import CloseImg from "./../../Assets/Images/Close.png"
import "./ServiceReport.css"
import { GeneralData, EquipmentData } from "../../Data/ReportData"
const ServiceReport=()=>{
    return(
        <div className="ServiceReport">
            <div className="Row">
                <h3>Service Report</h3>
                <img alt="Close" src={CloseImg} />
            </div>
            <hr />
            <div className="ReportCat">
                <h4>General</h4>
                {GeneralData.map((gd, index)=><div key={index}><h5>{gd.title}</h5><p>{gd.value}</p></div>)}                
            </div>
            <div className="ReportCat">            
            <hr />
            </div>
            <div className="ReportCat">
                <h4>Equipment</h4>
                {EquipmentData.map((ed, index)=><div key={index}><h5>{ed.title}</h5><p>{ed.value}</p></div>)}                
            </div>

        </div>
    )
}
export default ServiceReport;