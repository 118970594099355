import ImgThumb from "../ImgThumb/ImgThumb"
import userImage from "../../Assets/Images/userImage.png"
import style from "./Executor.module.css"
import { useNavigate } from "react-router-dom"
import User from "../../Services/User"
const Executor=({children, size})=>{
    const {firstname, lastname}=children;
    const navigate=useNavigate()
    const biodata=User.getBiodata();
    const handleClick=()=>{
        const base=biodata.appRoles[0].base;
        navigate(`/${base}/Engineers/Details`,{state:children})        
    }
    return(
        <div className={style.Executor}>
            <ImgThumb size={size}>{userImage}</ImgThumb>
            <div onClick={handleClick}>
                <h5>{firstname.capitalize() + " " + lastname.capitalize()}</h5>
            </div>
        </div>
        )
}
export default Executor;