import css from "./style.module.css"
import Expandable from "../../Components/Expandable/Expandable"
import AddOperation from "../../Components/AddEngineer/AddOperation"
import { useState } from "react"
import Utils from "../../Services/Utills"
import Svg from "../../Components/Svg/Svg"
import SvgOptions from "../../Components/Svg/SvgOptions"
import Button from "../../Components/Button/Button"
import Url from "../../Services/Url"
import { Post } from "../../Services/Server"
import { toast } from "react-toastify"
const JoinOPsModal = ({children}) => {
    const {task, closeModal}=children;
    console.log(children)
    const [expand, setExpand]=useState(true)
    const [Operations, setOperations]=useState(
        task.taskOperations.reduce((a,v, i)=>[...a, {index:i, ...v}],[])
    )
    const [count, setCount]=useState(task.taskOperations.length)
    
    const onSubmit=(op)=>{
        const dt=Utils.FormObject(op)
        setOperations(prev=>[...prev, {index:count, ...dt, owner:prev.length===0}]);
        setCount(count+1);
        setExpand(false)
      }
const setOwner=(op)=>{
    setOperations(Operations.reduce((a,v)=>[...a,{...v, owner:v===op}],[]))
}
const handleDelete=(op)=>{
    const list=Operations.filter(item=>item.index!==op.index);
    if(op.owner && list.length)list[0].owner=true
    setOperations(list);

}    
const Save=()=>{
    task.taskOperations=Operations;
    Post(Url.saveTask, task).then((resp)=>{
        toast("Operation Joined");
        closeModal(resp);
    })
}
  return (
    <div className={css.Modal}>
        <Expandable expand={expand} height={130}>
            <AddOperation onSubmit={onSubmit} />
        </Expandable>

        <div className={css.Body}>
          <div className={css.Operations}>
            {Operations.map(op=>
              <OperationView onDelete={handleDelete} onSetOwner={setOwner} key={op.index}>{op}</OperationView>
              )}
          </div>
          <div className={css.Controls}>
                <div><Button onClick={Save} className="Btn-Mid Active">Save</Button></div>
          </div>
        </div>     

    </div>
  )
}
const OperationView=({children, onSetOwner, onDelete})=>{
    const {operation, owner}=children
    return(
      <div className={css.OperationView}>
        <h5>{operation.name?operation.name:operation.fullName}</h5>
        <div className={css.Owner}>
          {
            owner?
            <h5>Full Control <Svg className={css.OpsIcon}>{SvgOptions.Ops}</Svg> </h5>:
            <div className={css.BtnLead}><Button className="Btn-Tny Inverse" onClick={()=>onSetOwner(children)}>Transfer</Button></div>
          }
          <Svg onClick={()=>onDelete(children)} className={css.Delete}>{SvgOptions.Delete}</Svg>
        </div>
      </div>
    )
  }
  

export default JoinOPsModal