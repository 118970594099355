import "./TextField.css";
const TextField=({children, onChange})=>{
    const {className}=children;
    return(
        <input onChange={onChange} className={className} placeholder="Tracking number" type="text"></input>
    )
}
TextField.defaultProps={
    children:{
        className:"Default-Field"
    }
}
export default TextField;