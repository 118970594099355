const FeedbackOptions=[
    {
        question:"How would you rate our Customer Service?", 
        stars:0,
        option:null,
        additionalText:""
    },
    {
        question:"How would you rate our Technical Service?", 
        stars:0,
        option:null,
        additionalText:""
    },
    {
        question:"How would you rate our Closure/Commercial handling?", 
        stars:0,
        option:null,
        additionalText:""
    },
    {
        question:"Would you recommend Fieldbase to another company?", 
        stars:null,
        option:{},
        additionalText:""
    },
    {
        question:"Any other thoughts?", 
        stars:null,
        option:null,
        additionalText:""
    },
]
export default FeedbackOptions;