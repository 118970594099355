import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ReactQuillEditorModules } from "../../Utils/RectQuillEditorConfig";
import css from "./AddContractClauseModal.module.css";
import Input from "../../Components/Form/Input/Input";
import Button from "../../Components/Button/Button";
import { Error, Post } from "../../Services/Server";
import Url from "../../Services/Url";
import { toast } from "react-toastify";


const AddContractClauseModal = ({children}) => {
  const [editorContent, setEditorContent] = useState("");
  const [title]=useState({ label: "Clause Title" })
  const {closeModal}=children;
  const save=()=>{
    const dt={
      name:title.value,
      details:editorContent
    }
    Post(Url.contract.saveClause, dt).then(resp=>{
      toast("Clause Saved")
      closeModal(resp)
    }).catch(err=>{
      Error(err)
    })
  }
  return (
    <>
      <hr />
      <div className={css.AddContractClauseModal}>
        <Input>{title}</Input>
        <p
          style={{
            color: "var(--blue)",
            fontWeight: 600,
            marginTop: "20px",
            marginBottom: "5px",
          }}
        >
          Clause Details
        </p>
        <ReactQuill
          theme="snow"
          modules={ReactQuillEditorModules}
          placeholder="Content goes here..."
          style={{ height: "50vh", marginBottom: "70px" }}
          value={editorContent}
          onChange={(e) => setEditorContent(e)}
          // readOnly={!editTemplate ? true : false}
        />
        <Button onClick={save} className="Btn-Mid Active">Save Clause</Button>
      </div>
    </>
  );
};

export default AddContractClauseModal;
