import Head from "../../../../Components/Head/Head"
import css from "./Details.module.css"
import { useParams , Navigate} from "react-router-dom"
import Left from "./Left";
import Right from "./Right";
import Route, {defaultRoute} from "./Route";
import RouteView from "../../../../Routes/RouteView";
import { ModalAction } from "../../../../Routes/NavRouter";
import { useState, useEffect } from "react";
import { Get } from "../../../../Services/Server";
import Url from "../../../../Services/Url";
import logoHolder from "../../../../Assets/Images/logoPlaceholder.png";
const Details = ({navoption}) => {
    const {id}=useParams();
    const [state, setState]=useState();
    const [logo, setLogo]=useState({data:logoHolder})
    useEffect(()=>{
      if(id){
        Get(Url.getCompany(id)).then(resp=>{
          setState(resp);          
        })
      }
    },[])
const initLogo=()=>{
  Get(Url.company.get.logo(id)).then(lresp=>{
    if(lresp.id)
    setLogo(lresp);
  })
}    
    useEffect(()=>{
      initLogo();
    },[state])
    
    if(!id){
        return(
            <Navigate to={"../"} />
        )
    }
  return (
    <div className={css.Details}>
      {state&&
      <>
        <RouteView ModalAction={ModalAction} navoption={navoption} props={{state, logo}} defaultRoute={defaultRoute} >{Route}</RouteView>
        <Right state={state} ></Right>
      </>
      }
    </div>
  )
}

export default Details