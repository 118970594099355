import React from "react";
import { useState, useEffect } from "react";
import css from "./AddTaskModal.module.css";
import Button from "../../Components/Button/Button";
import MilestoneEdit from "../../Components/Milestone2/MilestoneEdit";
import { ModalAction } from "../../Routes/NavRouter";
import AddButton from "../../Components/Form/AddButton/AddButton";
import Expandable from "../../Components/Expandable/Expandable";
import SvgOptions from "../../Components/Svg/SvgOptions";
const TaskTemplateModal = ({ children, onNext, onBack }) => {
	const { AddEquipmentModal } = children;
	const { openExtend } = ModalAction;
	const getMS = () => {
		if (!AddEquipmentModal) return [];
		const out = AddEquipmentModal.reduce((a, v) => {
			for (let i = 0; i < v.template?.milestones.length; i++) {
				let temp = v.template?.milestones[i];
				temp.count = 1;
				if (a.find((t) => t.name === temp.name))
					a.find((t) => t.name === temp.name).count += 1;
				else a.push(temp);
			}
			return [...a];
		}, []);
		return out;
	};
	const [list, setList] = useState(getMS());
	useEffect(() => {
		setList(getMS());
	}, [AddEquipmentModal]);
	//console.log(AddEquipmentModal)
	const handleNext = () => {
		onNext && onNext();
	};
	const handleBack = () => {
		//console.log(list)
		onBack && onBack();
	};
	const onDelete = () => {};
	const onClick = (ms) => {
		openExtend(
			<MilestoneDetails Milestone={ms}>{AddEquipmentModal}</MilestoneDetails>
		);
	};
	return (
		<div className={css.Cont}>
			<div className={css.Head}>
				<h2>Task Milestones</h2>
			</div>
			<div className={css.Body}>
				<div className={css.hgap10}>
					{list &&
						list.map((ms, index) => (
							<MilestoneEdit
								Events={{ onDelete: onDelete, onClick: onClick }}
								key={index}
							>
								{ms}
							</MilestoneEdit>
						))}
				</div>
				<div className={css.Controls}>
					<div>
						<Button onClick={handleBack} className="Btn-Mid Inverse">
							Back
						</Button>
					</div>
					<div>
						<Button onClick={handleNext} className="Btn-Mid Active">
							Next
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
const MilestoneDetails = ({ children, Milestone }) => {
	const { closeExtend } = ModalAction;
	const { name } = Milestone;
	const [activeTab, setActiveTab] = useState(0);

	const getEquipment = () => {
		if (!children) return [];
		const eq = children.filter((x) =>
			x.equipment.template.milestones.find((ms) => ms.name === name)
		);
		return eq;
	};
	const [list, setList] = useState(getEquipment());
	const [expand, setExpand] = useState(false);
	const openExpand = () => {
		setExpand(!expand);
	};
	const getProperty = (name, index) => {
		if (list.length < index) return null;
		const ms = list[index].equipment.template.milestones;
		return ms.find((m) => m.name === name);
	};
	const [details, setDetails] = useState(getProperty(name, activeTab));
	useEffect(() => {
		setList(getEquipment());
		setActiveTab(0);
	}, [name]);
	useEffect(() => {
		setDetails(getProperty(name, activeTab));
	}, [activeTab]);
	const getMerge = () => {
		const out = [];
		for (let i = 0; i < children.length; i++) {
			const item = children[i].equipment.template.milestones.find(
				(ms) => ms.name === name
			);
			if (item) {
				item.merge && out.push({ name, item: children[i] });
			}
		}
		return out;
	};

	const [merge, setMerge] = useState(getMerge());
	const addMerge = (item) => {
		const m = children[item.index].equipment.template.milestones.find(
			(ms) => ms.name === name
		);

		if (merge.find((m) => m.item.index == item.index && m.name === name)) {
			m.merge = false;
			m.merge_id = "";
			setMerge(
				merge.filter((m) => !(m.name === name && m.item.index === item.index))
			);
		} else {
			m.merge = true;
			m.merge_id = "100";
			setMerge([...merge, { name, item }]);
		}
	};
	console.log(details);
	return (
		<div className={css.MilestoneDetails}>
			<div className={css.MsHead}>
				<h2>{name}</h2>
			</div>
			<div className={css.Tab}>
				<div className={css.TabHead}>
					{list &&
						list.map((eq, index) => (
							<div
								key={index}
								onClick={() => setActiveTab(index)}
								className={index === activeTab ? css.Active : css.NotActive}
							>
								{eq.equipment.equipment}
							</div>
						))}
					<div className={css.TabHeadFill}></div>
				</div>
				<div className={css.TabBody}>
					{details && (
						<ul className={css.ListView}>
							<li>
								<div>Executor :</div>
								<div>{details.executor.name}</div>
							</li>
							<li>
								<div>Client View :</div>
								<div>{details.clientView ? "True" : "False"}</div>
							</li>
						</ul>
					)}
				</div>
			</div>
			<AddButton onClick={openExpand}>Merge Milestones</AddButton>
			<div>
				<Expandable expand={expand}>
					<div className={css.ExpandableCont}>
						<div className={css.BtnOptions}>
							{list &&
								list.map((eq, index) => (
									<div key={index}>
										<Button
											className="Btn-Tny Inverse"
											onClick={() => addMerge(eq)}
											icon={
												merge.find(
													(m) => m.item.index === eq.index && m.name === name
												) && SvgOptions.Check
											}
										>
											{eq.equipment.equipment}
										</Button>
									</div>
								))}
						</div>
						<Button onClick={openExpand} className="Btn-Tny Active">
							Save Merge
						</Button>
					</div>
				</Expandable>
			</div>
			<div className={css.Foot}>
				<Button className="Btn-Small Active" onClick={() => closeExtend()}>
					Close
				</Button>
			</div>
		</div>
	);
};
export default TaskTemplateModal;
