const GeneralData=[
    {title:"Order Number",value:1348949},
    {title:"Company",value:"Company Name"},
    {title:"Invoice To",value:"Name Person"},
    {title:"Vessel Name",value:"DSL"},
    {title:"Location",value:"Lucky Land Sambarth, Abonima, Nigeria"},
    {title:"Delivered on",value:"24-02-2023"},
]
const EquipmentData=[
    {title:"Equipment Name",value:"----"},
    {title:"Equipment Type",value:"----"},
    {title:"Model",value:"----"},
    {title:"Software Version",value:"----"},
    {title:"Manufacture",value:"----"},
    {title:"Last Survey",value:"----"},
    {title:"Last GMDSS SBM",value:"----"},
    {title:"Expiry GMDSS SBM",value:"----"},
]
const ExpensesData=[
    {title:"Antenna",value:22000},
    {title:"Cables",value:2000},
    {title:"Weldering service",value:14000},
    {title:"Cabling Services",value:1000},
]
export {EquipmentData, GeneralData, ExpensesData}