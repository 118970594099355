import css from "./Details.module.css"
import TaskItem from "../../../../Components/TaskItem/TaskItem"
const Right = ({state}) => {
  const {currentOpTasks}=state;
  return (
    <div className={css.Right}>
        <h3>{currentOpTasks.totalElements.length} Ongoing Tasks</h3>
        {currentOpTasks&& currentOpTasks.content.map((ct, index)=>
        <TaskItem key={index}>{ct}</TaskItem>)}
    </div>
  )
}

export default Right