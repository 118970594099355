import FormType from "../Form/FormType"
import Url from "../../Services/Url"
const SalesEquipmentOptions={
    Fields:[
        {element:FormType.Input,required:true, type:"text",name:"equipment", label:"Equipment", placeholder:"Enter Equipment Name", value:null},
        {element:FormType.Select,required:true, name:"service", label:"Task Type", placeholder:"Select Type", value:null, options:{url:Url.taskType, id:"id",name:"name"}}, 
        {element:FormType.Select,required:true, name:"template", label:"Provision Template", placeholder:"Select Type", value:null, options:{}}, 
    ],
    
    Buttons:[
        {type:"button", text:"Close", className:"Btn-Mid", onClick:null},
        {type:"submit", text:"Add", className:"Btn-Mid Active"},
    ]
}
export default SalesEquipmentOptions