import React from 'react'
import TaskNavOption, {defaultRoute} from './TaskNavOption'
import RouteView from '../../../Routes/RouteView'
const Tasks = ({navoption, ModalAction}) => {
  return (
    <RouteView defaultRoute={defaultRoute} ModalAction={ModalAction} navoption={navoption}>{TaskNavOption}</RouteView>
)
}

export default Tasks
