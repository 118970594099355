import css from "./AddTaskModal.module.css"
import Form from "../../Components/Form/Form"
import AddTaskOPtions from "./AddTaskOptions"
import { useState } from "react"
import { Get, Post } from "../../Services/Server"
import Url from "../../Services/Url"
import User from "../../Services/User"
import Utils from "../../Services/Utills"
import { toast } from "react-toastify"
import { ModalAction } from "../../Routes/NavRouter"
const AddTaskModal=({children, onNext})=>{
    const {closeModal}=ModalAction;
    const [options, setOptions]=useState(AddTaskOPtions)
    const [saving, setSaving]=useState(false);
    //options.Buttons[0].onClick=closeModal;
    const companyChange=(e)=>{
        if(typeof(e)==="object" && e!=null){
            const op={...options}
            op.Fields.find(fld=>fld.name==="vessel").options={url:Url.company.vesselDTO(e.id), id:"id",name:"name"};
            setOptions(op);    
        }
    }

    options.Buttons.find(btn=>btn.text==="Cancel").onClick=()=>{
        closeModal()
    }
    options.Fields.find(fld=>fld.name==="company").onSelected=companyChange;
    const onSubmit=(data)=>{
        const taskDetails=Utils.FormObject(data);
        children.AddTaskModal=taskDetails;
        onNext&&onNext(taskDetails);
    }
    return(
        <div className={css.Cont}>
            <div className={css.Head}><h2>Task Details</h2></div>
            <div className={css.Body} style={{display:saving?"none":"flex"}}>
                <Form onSubmit={onSubmit}>{options}</Form>
            </div>
        </div>
        )
}
export default AddTaskModal