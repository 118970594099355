import css from "./style.module.css"
const ExpensesList = () => {
  return (
    <div className={css.Page}>
      Expenses List
    </div>
  )
}

export default ExpensesList
