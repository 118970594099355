import FormType from "../Form/FormType"
import Url from "../../Services/Url"
import Role from "../../Data/Role";
const AddEngineeerOptions={
    Fields:[
        {element:FormType.Select,required:true, type:"text",name:"engineer", label:"Engineer", placeholder:"Select Engineer", value:null,options:{url:Url.selectStaffByRole(Role.engineer)}},
    ],
    
    Buttons:[
        {type:"button", text:"Close", className:"Btn-Mid", onClick:null},
        {type:"submit", text:"Add", className:"Btn-Mid Active"},
    ]
}
export default AddEngineeerOptions;