import { useRef } from "react";
import css from "../Form.module.css";

const TextArea = ({ children, onChange }) => {
  const { label, value, placeholder } = children;
  const ref = useRef();
  const textChange = () => {
    children.value = ref.current.value;
    onChange && onChange();
  };

  return (
    <div className={css.FormControl}>
      <div>
        <label>{label}</label>
      </div>
      <div>
        <textarea
          placeholder={placeholder}
          onChange={textChange}
          ref={ref}
          defaultValue={value}
        ></textarea>
      </div>
    </div>
  );
};
export default TextArea;
