import Dot from "../../../Components/Dot/Dot";
import style from "./Notification.module.css"
import Svg from "../../../Components/Svg/Svg";
import User from "../../../Services/User";
const Notification=({type, children, onClick})=>{
    const {staff, title, createdAt}=children;
    const biodata=User.getBiodata();
    const dotStyle={
        backgroundColor:"var(--electricblue)",
        border:"none",
        width:"6px",
        height:"6px",
        transform:"translate(-10px, 6px)"
    }
        let icon=null;
    if(typeof(type)==="object"){
        icon=type.icon;
        dotStyle.backgroundColor=type.color;
    }
    return(
        <div onClick={()=>onClick(children)} className={style[typeof(type)==="object"?type.name:"info"]}>
            <div className={style.band}></div>
            <div className={style.content}>
                <div>
                    <Dot style={dotStyle}></Dot>
                    <div className={style.text}>
                        <span>{staff.firstname+" "+staff.lastname
                        }</span> {title}
                    </div>
                    <div className={style.time}>{createdAt}</div>
                </div>
                {
                    icon&&
                    <div className={style["icon"]}>
                        <Svg>{icon}</Svg>
                    </div>
                }
            </div>
        </div>
    )
}
export default Notification;