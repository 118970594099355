import "./ProgressBar.css";
const ProgressBar=({className, progress, children, padding, borderRadius, height})=>{
    return(
        <div style={{borderRadius, height}} className="ProgressBarBack">
            <div className="ProgressBarTrack">
                <div style={{width:progress + "%", padding, transition:"1s"}} className={"ProgressBar bg" + className}>{children}</div>
            </div>
        </div>
    )
}
ProgressBar.defaultProps={
    className:"ElectricBlue",
    progress:0,
    padding:"6px",
    borderRadius:"2000px",
    height:"8px"
}
export default ProgressBar;