import { lazy } from "react";
import FormType from "../../Components/Form/FormType";
import Url from "../../Services/Url";

const VesselFormOptions = (init) => {
	return {
		Fields: [
			[
				{
					element: FormType.Input,
					required: true,
					name: "name",
					label: "Vessal Name",
					value: init.vessel.name,
				},
				{
					element: FormType.Input,
					required: true,
					name: "imo",
					label: "IMO Number",
					value: init.vessel.imo,
				},
			],
			[
				{
					element: FormType.Input,
					required: true,
					label: "MMSI Number",
					name: "mmsi",
					value: init.vessel.mmsi,
				},
				{
					element: FormType.Input,
					required: true,
					label: "Call Sign",
					name: "callSign",
					value: init.vessel.callSign,
				},
			],
			[
				{
					element: FormType.Select,
					required: true,
					label: "Classification",
					name: "classification",
					options: { url: Url.company.vesselClasses, id: "id", name: "name" },
					placeholder: "Select Classification",
					value: init.vessel.classification,
				},
				{
					element: FormType.Select,
					required: true,
					label: "Vessel Type",
					name: "vesselType",
					options: { url: Url.company.vesselTypes, id: "id", name: "name" },
					placeholder: "Select Vessel Type",
					value: init.vessel.vesselType,
				},
			],
			[
				{
					element: FormType.Select,
					required: true,
					name: "weightClass",
					label: "Gross Tonnage",
					options: { url: Url.company.vesselWeights, id: "id", name: "name" },
					placeholder: "Select Gross Tonnage",
					value: init.vessel.weightClass,
				},
				{
					element: FormType.Input,
					required: true,
					name: "paxCapacity",
					label: "PAX Capacity",
					value: init.vessel.paxCapacity,
				},
			],
			{
				element: FormType.Select,
				required: true,
				label: "Hull Material",
				name: "hallMaterial",
				placeholder: "Select Hull Material",
				options: { url: Url.company.vesselMaterials, id: "id", name: "name" },
				value: init.vessel.hallMaterial,
			},
			{
				element: FormType.Select,
				required: true,
				name: "vesselPower",
				label: "Vessel Power",
				options: { url: Url.company.vesselPowers, id: "id", name: "name" },
				value: init.vessel.vesselPower,
			},
			{
				element: FormType.Select,
				required: true,
				label: "UPS",
				name: "ups",
				options: {
					url: Url.company.availabilityStatus,
					id: "id",
					name: "name",
				},
				value: init.vessel.ups,
			},
			{
				element: FormType.Select,
				required: true,
				name: "hotWorkPermit",
				label: "Hot Work Permit",
				options: { url: Url.company.requiredStatus, id: "id", name: "name" },
				value: init.vessel.hotWorkPermit,
			},
		],
	};
};

export default VesselFormOptions;
