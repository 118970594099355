import { useState } from "react"
import css from "../Details/Details.module.css"
import Edit from "../Details/Edit/Edit"
import Right from "../Details/Right"
import { Post } from "../../../../Services/Server"
import Url from "../../../../Services/Url"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import logoPlaceholder from "../../../../Assets/Images/logoPlaceholder.png";
const AddCompany = () => {
  const [state, setState]=useState({vessels:[], name:"", email:"", phone:"", address:"", website:""})
  const logo={data:logoPlaceholder};
  const [vessels, setVessels]=useState([])
  const navigate=useNavigate();
  const handleVessel=(e)=>{
    setVessels(e.vessels)
  }
  const handleCompany=(e)=>{
    e.vessels=vessels;
    Post(Url.saveCompany, e).then(resp=>{
        toast("Saved")
        navigate("../");
    })
  }
  return (
    <div className={css.Details}>
        <Edit onChange={handleCompany} title={"New company"} logo={logo} state={state}></Edit>
        <Right onChange={handleVessel} state={state}></Right>
    </div>
  )
}

export default AddCompany