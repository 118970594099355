import FormType from "../../../../../Components/Form/FormType";
import Url from "../../../../../Services/Url";

const AddRequirementsFormOptions = (init) => {
  return {
    Fields: [
      [
        {
          element: FormType.Input,
          required: true,
          label: "Vessel Name:",
          placeholder: "Enter Vessel Name",
          value: init?.vessel?.name,
          name: "name",
        },
        {
          element: FormType.Select,
          required: true,
          label: "Vessel Power:",
          placeholder: "Select Vessel Power",
          value: init.vessel.vesselPower,
          options: { url: Url.company.vesselPowers, id: "id", name: "name" },
          name: "vesselPower",
        },
      ],
      [
        {
          element: FormType.Input,
          required: true,
          label: "IMO Number:",
          placeholder: "Enter IMO Number",
          value: init.vessel.imo,
          name: "imo",
        },
        {
          element: FormType.Select,
          required: true,
          label: "UPS:",
          placeholder: "Select UPS",
          value: init.vessel.ups,
          options: {
            url: Url.company.availabilityStatus,
            id: "id",
            name: "name",
          },
          name: "ups",
        },
      ],
      [
        {
          element: FormType.Input,
          required: true,
          label: "MMSI Number:",
          placeholder: "Enter MMSI Number",
          value: init.vessel.mmsi,
          name: "mmsi",
        },
        {
          element: FormType.Select,
          required: true,
          label: "Hot Work Permit:",
          placeholder: "Select Hot Work Permit",
          options: { url: Url.company.requiredStatus, id: "id", name: "name" },
          value: init.vessel.hotWorkPermit,
          name: "hotWorkPermit",
        },
      ],
      [
        {
          element: FormType.Input,
          required: true,
          label: "Call Sign:",
          placeholder: "Enter Call Sign",
          value: init.vessel.callSign,
          name: "callSign",
        },
        {
          element: FormType.Input,
          required: true,
          label: "VOIP Phone:",
          placeholder: "Enter VOIP Phone",
          value: init.requirements ? init.requirements.voip : null,
          name: "voip",
        },
      ],
      [
        {
          element: FormType.Select,
          required: true,
          label: "Classification:",
          placeholder: "Select Classification",
          options: { url: Url.company.vesselClasses, id: "id", name: "name" },
          value: init.vessel.classification,
          name: "classification",
        },
        {
          element: FormType.Input,
          required: true,
          label: "Wifi Zones:",
          placeholder: "Select Wifi Zone",
          value: init.requirements ? init.requirements.wifiZone : null,
          name: "wifiZone",
        },
      ],
      [
        {
          element: FormType.Select,
          required: true,
          label: "Vessel Type:",
          placeholder: "Select Vessel Type",
          options: { url: Url.company.vesselTypes, id: "id", name: "name" },
          value: init.vessel.vesselType,
          name: "vesselType",
        },
        {
          element: FormType.Input,
          required: true,
          label: "Bandwidth:",
          placeholder: "Select Bandwidth",
          value: init.requirements ? init.requirements.bandwidth : null,
          name: "bandwidth",
        },
      ],
      [
        {
          element: FormType.Select,
          required: true,
          label: "Gross Tonnage:",
          placeholder: "Select Gross Tonnage",
          options: { url: Url.company.vesselWeights, id: "id", name: "name" },
          value: init.vessel.weightClass,
          name: "weightClass",
        },
        {
          element: FormType.Select,
          required: true,
          label: "Firewall:",
          placeholder: "Select Firewall",
          options: { url: Url.company.requiredStatus, id: "id", name: "name" },
          value: init.requirements ? init.requirements.firewall : null,
          name: "firewall",
        },
      ],
      [
        {
          element: FormType.Input,
          required: true,
          label: "PAX Capacity:",
          placeholder: "Select PAX Capacity",
          value: init.vessel.paxCapacity,
          name: "paxCapacity",
        },
        {
          element: FormType.Input,
          required: true,
          label: "ADU Details:",
          placeholder: "Enter ADU Details",
          value: init.requirements ? init.requirements.adu : null,
          name: "adu",
        },
      ],
      [
        {
          element: FormType.Select,
          required: true,
          label: "Hull Material:",
          placeholder: "Select Hull Capacity",
          options: { url: Url.company.vesselMaterials, id: "id", name: "name" },
          value: init.vessel.hallMaterial,
          name: "hallMaterial",
        },
        {
          element: FormType.Input,
          required: true,
          label: "BDU Details:",
          placeholder: "Enter BDU Details",
          value: init.requirements ? init.requirements.bdu : null,
          name: "bdu",
        },
      ],
      {
        element: FormType.TextArea,
        required: true,
        label: "Project Scope:",
        placeholder: "Enter Details of Project Scope",
        value: init.requirements ? init.requirements.projectScope : null,
        name: "projectScope",
      },
    ],
    Buttons: [
      { type: "button", text: "Close", className: "Btn-Mid", onClick: null },
      { type: "submit", text: "Submit", className: "Btn-Mid Active" },
    ],
  };
};

export default AddRequirementsFormOptions;
