import "./PieChart.css";
import Charts from "./Charts";
import { useEffect, useState } from "react";
import * as echarts from 'echarts';

const Piechart=({children, chartId})=>{
    const [options, setOption]=useState([{text:"Month", css:"ActiveRange", index:0},{text:"Year",css:null, index:1}])
    const [myChart, setChart]=useState();

    const getChartData=(index)=>{
        if(!children)return [];
        const Installation=[children.installationCountMonth, children.installationCountYear];
        const Repair=[children.repairCountMonth, children.repairCountYear];
        const Maintenance=[children.maintenanceCountMonth, children.maintenanceCountYear];
        const ChartData= [
            { value: Installation[index], name: 'Installation',itemStyle:{color:"#F64740"} },
            { value: Repair[index], name: 'Repairs',itemStyle:{color:"#14B8FF"} },
            { value: Maintenance[index], name: 'Maintenance',itemStyle:{color:"#FAC815"} },
          ]
            return(ChartData);
    }
    const [data, setData]=useState([...getChartData(0)]);
    

    const sum=data.reduce((ps, a)=>ps+=parseInt(a.value), 0);
    useEffect(()=>{
        setChart(echarts.init(document.getElementById(chartId)));
    });

    useEffect(
        ()=>{      
            if(myChart){
                myChart.setOption(Charts.getPie(data));
            }
        },[myChart])

        useEffect(
            ()=>{      
                const index=options.findIndex(o=>o.css);
                const dt=getChartData(index);
                setData(dt)
                if(myChart){
                    myChart.setOption(Charts.getPie(dt));
                }
            },[options])
    
    const setSelected=(op)=>{
        const lst=options.map(o=>{o.css=o.text===op.text?"ActiveRange":null; return o});
        setOption([...lst])
    }
    return(
        <div className="PieChart">  
            <div className="PieHead">
                <div>
                    <h4>Task Type</h4>
                </div>
                <div className="PieRange">
                    {options.map(op=>
                       <div onClick={()=>setSelected(op)} key={op.text} className={op.css}>{op.text}</div>
                    )}
                </div>
            </div>          
            <div id={chartId} style={{width:"100%", height:"300px"}}></div>
            <div className="ChartDetails">
            {
                data.map((dt, index)=>
                <div className="ChartItem" key={index}>
                    <div className="ChartItemName">
                        <div className="ChartDot" style={{backgroundColor:dt.itemStyle.color}}></div>
                        <div>
                            {dt.value + " "+ dt.name}
                        </div>
                    </div>
                    <div className="ChartPercent">{sum?(Math.round((parseInt(dt.value) *1000)/sum)/10):(Math.round(100/data.length))}%</div>
                </div>)
            }
            </div>
        </div>
    )
}
Piechart.defaultProps={
    chartId:"PieChart"
}
export default Piechart;