import "./Ops.css";
import DataTable from "../../../Components/DataTable/DataTable";
import PageHead from "../../../Components/PageHead/PageHead";
import AddOpModel from "../../../Modals/AddOpModal/AddOpModel";
import { useEffect, useState } from "react";
import {Get} from "../../../Services/Server";
import Url from "../../../Services/Url";
import {UserRecOp} from "../../../Data/UserRec";
import Role from "../../../Data/Role";
import { useNavigate } from "react-router-dom";
const OpsList=({navoption, ModalAction, data})=>{
    const navigate=useNavigate();
    const handleAddOp=()=>{
        const Options={
            title:"Add Operation",
            element:AddOpModel,
            closeModal:handleCloseModal,
        }
        ModalAction.openModal(Options);
    }
    const handleCloseModal=()=>{
        load();
        ModalAction.closeModal();
    }

    const [currentTasks, setCurrentTasks]=useState({
        data:[]
    });
    const load=()=>{
        Get(Url.staffByrole(Role.operation)).then(resp=>{
            setCurrentTasks({data:resp})
        })
    }

    useEffect(()=>{
        load();
    },[])
    const flex=[30, 30,30,10];
    const handleClick=(e)=>{
        navigate("Details", {state:e})
    }
    const heading=["Op's Name", "Email", "Phone", "Tasks"]

    return(
        <div className="Ops">
            <PageHead navoption={navoption}  addBtn={{text:"Operation", onClick:handleAddOp}}></PageHead>
            <div className="UserList">
                <DataTable onClick={handleClick} reader={UserRecOp} flex={flex}>{{...currentTasks, heading}}</DataTable>
            </div>

        </div>
    )
}
export default OpsList;