import Button from "../../Components/Button/Button"
import css from "./ReportModal.module.css"
import { useState } from "react";
import AddButton from "../../Components/Form/AddButton/AddButton";
import logTemplate from "./logTemplate";
import ReportLog from "./ReportLog";
import { Post } from "../../Services/Server";
import Url from "../../Services/Url";
import User from "../../Services/User";
import { useEffect } from "react";
const Report2=({onNext, onBack, children})=>{
    const [btn, setBtn]=useState({text:"Next", onClick:onNext})
    const [saving, setSaving]=useState(false);
    const {logs}=children
    const clone=()=>{
       return JSON.parse(JSON.stringify(logTemplate));
    }

    const handleNext=()=>{
        if(btn.text==="Next")onNext();
        else save();
    }

    const initLog=()=>{
        if(!children.logs)return [clone()];
        if(!children.logs.length)return [clone()];
        const logList=children.logs.map(log=>{
            const cl= clone();
            cl.id=log.id;
            cl.observation.value=log.observation;
            cl.start.value=log.start;
            cl.finish.value=log.finish;
            cl.images=log.images;
            cl.jobDoneList=log.jobDoneList.reduce((a,v)=>[...a,{label:"Task Done",name:"details",id:v.id, value:v.details}],[])
            return cl
        });
        //setLogs(logList);
        return logList;
    }
    const [logList, setLogs]=useState([...initLog()]);
    //console.log(dt)
    useEffect(()=>{
        //console.log("Children changed")
        //initLog();
        setLogs([...initLog()])
    },[logs])
    const addLog=()=>{
        setLogs([...logList, clone()])
    }
    //console.log(children)
    const save=async()=>{
        setSaving(true);
        setBtn({text:"Saving...", onClick:null})
        //const images=[];
        const data= logList.map(log=>{
            return {
                start:log.start.value,
                finish:log.finish.value,
                observation:log.observation.value,
                jobDoneList:log.jobDoneList.map(job=>{return {details:job.value, id:job.id}}),
                images:log.images,
                createdBy:{id:User.getBiodata().id},
                inReport:true,
                //report:{id:children.id},
                id:log.id,
            }
        })
        children.logs=data;

        Post(Url.saveReport, children).then(resp=>{

            setLogs([...logList.map((log, index)=>{
                log.jobDoneList.map((jd, key)=>{
                    jd.id= resp.logs[index].jobDoneList[key].id
                    return jd;
                })
                log.id=resp.logs[index].id; 
                return log;
            })])
        }).finally(()=>{
            window.setTimeout(()=>{
                setBtn({text:"Next", onClick:onNext})    
                setSaving(false)
            },100)
        })

    }
    const onChange=()=>{
        if(!saving)
        setBtn({text:"Save", onClick:save})
    }
    const onDelete=(index)=>{
        const logs=[...logList]
        logs.splice(index, 1);
        setLogs(logs);
    }
    return(
        <div className={css.Report}>
            <div className={css.Head}><h2>Job</h2></div>
            <div className={css.ReportBody}>
            <div className={css.LogsCont}>
                <div className={css.Logs}>
                    {logList&&
                        logList.map((log, index)=>
                            <ReportLog onChange={onChange} onDelete={onDelete} key={index} index={index}>{log}</ReportLog>
                        )
                    }
                </div>
                <AddButton onClick={addLog}>Add Log</AddButton>
            </div>
            </div>
            <div className={css.Foot}>
                <div className={css.Buttons}>
                <Button className="Btn-Mid" onClick={onBack}>Back</Button>
                <Button className="Btn-Mid Active" onClick={handleNext}>{btn.text}</Button>                
                </div>
            </div>
        </div>
    )
}

export default Report2;