import Button from "../../Components/Button/Button"
import css from "./Style.module.css"
const EquipmentModal = ({children}) => {
    const {taskEquipmentList}=children.task
  return (
    <div className={css.Modal}>        
        {taskEquipmentList.map(eq=>
        <div key={eq.id} className={css.Equipment}>
            <div>
                <div>Equipment Name</div>
                <div>{eq.name}</div> 
            </div>
            <div>
                <div>Model</div>
                <div>{"Nil"}</div> 
            </div>
            <div>
                <div>Manufacture</div>
                <div>{"Nil"}</div> 
            </div>
            <div>
                <div>Job Type</div>
                <div>{eq.type.name}</div> 
            </div>
        </div>
        
            )}
    <div className={css.Foot}><Button onClick={children.closeModal} className="Active Btn-Mid">Close</Button></div>
    </div>
  )
}

export default EquipmentModal