import userImg from "../../../../Assets/Images/userImage2.png"
import css from "./style.module.css"
import Button from '../../../../Components/Button/Button'
import Form from '../../../../Components/Form/Form'
import ImgThumb from '../../../../Components/ImgThumb/ImgThumb'
import AccOptions, {SecureOptions, AccId} from "./Options";
import Utils from "../../../../Services/Utills"
import { toast } from "react-toastify"
import { Post } from "../../../../Services/Server"
import Url from "../../../../Services/Url"

const AddStaff = () => {
  const handleSubmit=()=>{
    const pw=Utils.FormObject(SecureOptions)
    if(pw.password!==pw.cpassword){
      toast("Password error");
      return;
    }
    const newStaff={password:pw.password,...Utils.FormObject(AccId), ...Utils.FormObject(AccOptions)}
    Post(Url.auth.register, newStaff).then(resp=>{
      console.log(resp)
    }).catch(err=>{
      console.log(err)
    })
  }
  return (
    <div className={css.Page}>
            <div>
                <h2 className={css.Title}>Staff Details</h2>
                <div>
                    <Form>{AccId}</Form>
                </div>
            </div>

            <div>
                <h2 className={css.Title}>Biodata</h2>
                <div>
                    <Form>{AccOptions}</Form>
                </div>
            </div>

            <div>
                <h2 className={css.Title}>Account Security</h2>
                <div>
                    <Form onSubmit={handleSubmit}>{SecureOptions}</Form>
                </div>
            </div>
    </div>
  )
}

export default AddStaff
