import css from "./style.module.css"
import css2 from "../Form.module.css"
import 'react-calendar/dist/Calendar.css';
import { useRef, useEffect, useState } from "react";
const DateTime=({children,onChange})=>{    
    const dateRef=useRef()
    const hrRef=useRef()
    const minRef=useRef()
    const {label, name, placeholder, type, required}=children
    const [value, setValue]=useState(new Date(children.value));
    if(!value){
        value=new Date();
    }
    useEffect(()=>{
        dateRef.current.value=`${value.getFullYear()}-${value.getMonth().toString().padStart(2,0)}-${value.getDate().toString().padStart(2,0)}`
        hrRef.current.value=value.getHours()
        minRef.current.value=value.getMinutes()
    },[])
    useEffect(()=>{
        children.value=JSON.parse(JSON.stringify(value));
    },[value])
    const valChange=()=>{
        setValue(
            new Date(`${dateRef.current.value}T${hrRef.current.value}:${minRef.current.value}`)
            )
            onChange&&onChange()
    }
    const validate=(e)=>{
        if(e.target.value<0){
            e.target.value=0;
        }
        if(e.target.value> parseInt(e.target.max)){
            e.target.value=parseInt(e.target.max)
        }
        valChange();
    }
    return(
        <div className={css2.FormControl}>
        <div>
            <label>{label}</label>
        </div>

        <div className={css.DateTime}>
            <div className={css.Time}>
                <div><input onChange={valChange} ref={dateRef} placeholder="HR" type="date" required={required}/></div>
                <div><input ref={hrRef} onChange={validate} placeholder="HR" max={24} type="number" required={required}/></div>
                <div className={css.Separator}>:</div>
                <div><input ref={minRef} onChange={validate} placeholder="MIN" max={60} type="number"required={required}/></div>
            </div>
        </div>
    </div>
    )
}
export default DateTime;