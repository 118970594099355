import React from 'react'
import PageHead from '../../../../Components/PageHead/PageHead'
import DataTable from "../../../../Components/DataTable/DataTable";
import AddEngrModel from "../../../../Modals/AddEngrModal/AddEngrModel";
import { Get } from "../../../../Services/Server";
import { useEffect, useState } from "react";
import {UserRecAdmin} from "../../../../Data/UserRec";
import Role from "../../../../Data/Role";
import Url from "../../../../Services/Url";
import css from "./style.module.css"
import { useNavigate } from 'react-router-dom'
const StaffList = ({navoption, ModalAction}) => {
    const nav=useNavigate();
    const handleAddStaff=()=>{
        nav("Add");
    }
    const load=()=>{
      Get(Url.allStaff).then(resp=>{
          console.log(resp);
          setStaffList({data:resp})
      })
  }
  // const handleCloseModal=()=>{
  //     load();
  //     ModalAction.closeModal();
  // }
  const [staffList, setStaffList]=useState({
      data:[]
  });
  useEffect(()=>{
      load()
  },[])
  const flex=[30, 30,30,10];
  const handleClick=(e)=>{
      nav("Details", {state:e})
  }
  const heading=["Name", "Email", "Phone", "Staff Number"]
  
  return (
    <div className={css.Page}>
        <PageHead navoption={navoption}  addBtn={{text:"Staff", onClick:handleAddStaff}}></PageHead>
        <div className="UserList" id="ModalRoot">
                <DataTable onClick={handleClick} flex={flex} reader={UserRecAdmin}>{{...staffList, heading}}</DataTable>
            </div>
    </div>
  )
}

export default StaffList
