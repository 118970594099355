import React from 'react'
import StaffNavOptions, {defaultRoute} from './StaffNavOptions'
import RouteView from '../../../Routes/RouteView'
const ManageStaff = ({navoption, ModalAction}) => {
  return (
    <RouteView navoption={navoption} ModalAction={ModalAction} defaultRoute={defaultRoute}>{StaffNavOptions}</RouteView>
  )
}

export default ManageStaff
