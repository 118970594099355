import React from "react";
import Svg from "../../../../../Components/Svg/Svg";
import SvgOptions from "../../../../../Components/Svg/SvgOptions";
import css from "./KBSingleList.module.css";

const KBSingleList = ({ KBList, onClick }) => {
  return (
    <div className={css.KBSingleList} onClick={onClick}>
      <div className={css.Top}>
        <Svg>{SvgOptions.Light}</Svg>
        <p className={css.Name}>{KBList.name}</p>
      </div>
      <p className={css.Description}>{KBList.reportInitialDetails}</p>
    </div>
  );
};

export default KBSingleList;
