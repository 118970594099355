const Svg = ({ children, style, onClick, className }) => {
  const { paths, fill, viewBox, width, height, rects, circles } = children;
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
      onClick={onClick}
    >
      {paths &&
        paths.map((path, index) => (
          <path
            key={index}
            d={path.d}
            fillRule={path.fillRule}
            fill={path.fill}
            stroke={path.stroke}
            strokeWidth={path.strokeWidth}
            strokeLinecap={path.strokeLinecap}
            strokeLinejoin={path.strokeLinejoin}
            fillOpacity={path.fillOpacity}
            className={className}
          />
        ))}
      {rects &&
        rects.map((rect, index) => (
          <rect
            key={index}
            x={rect.x}
            y={rect.y}
            width={rect.width}
            height={rect.height}
            transform={rect.transform}
            stroke={rect.stroke}
            strokeWidth={rect.strokeWidth}
            rx={rect.rx}
          />
        ))}
      {circles &&
        circles.map((circle, index) => (
          <circle
            key={index}
            cx={circle.cx}
            cy={circle.cy}
            r={circle.r}
            fill={circle.fill}
            transform={circle.transform}
            stroke={circle.stroke}
            strokeWidth={circle.strokeWidth}
            rx={circle.rx}
          />
        ))}
    </svg>
  );
};
Svg.defaultProps = {
  className: "icon",
  children: {
    viewBox: "0 0 24 24",
    fill: "currentColor",
  },
};
export default Svg;
