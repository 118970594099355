import SvgOptions from "../../../Components/Svg/SvgOptions";
import AddJob from "./AddJob/AddJob";
import AddStarlinkJob from "./AddStarlinkJob/AddStarlinkJob";
import DetailsView from "./Details/DetailsView";
import JobsList from "./JobsList";

const JobsNavOption = [
	{
		element: AddJob,
		path: "Create",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		icon: SvgOptions.Task,
	},
	{
		element: AddStarlinkJob,
		path: "CreateStarlink",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		icon: SvgOptions.Task,
	},
	{
		element: DetailsView,
		path: ":id/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		icon: SvgOptions.Task,
	},
];

const defaultRoute = {
	element: JobsList,
	path: "*",
	access: { user: true, admin: true, super: true },
	Protected: false,
	default: true,
	icon: SvgOptions.Task,
};

export { defaultRoute };
export default JobsNavOption;
