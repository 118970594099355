import React from 'react'
import { useLocation } from 'react-router-dom'
import "./ClientMilestones.css"
import Head from '../../Components/Head/Head';
import TaskService from '../../Services/TaskService';
import MsNotify from './MsNotify/MsNotify';
const ClientNotifications = () => {
    const {state}=useLocation();
    const {taskEquipmentList, title, tracking, id, jobNumber}=state;
    const ts=new TaskService(state);
    const msGroup=ts.msGroup();
  return (
    <div className='CMRight'>
        <Head dropdown={[]} state={state} title={"Manage Notifications"}></Head>
        <div className="Cont-Space">
            <div><h5>Service:</h5><div>{title} </div></div>
            <div><h5>Your order number:</h5><div>{jobNumber}</div></div>
        </div>

        <div className='info'>
            Kindly select the milestones you will like us to notify you about, during the execution of this job. You will receive email notification when any of the selected milestones is acheived.
        </div>
        <div className='msNotifyCont'>
            {msGroup.map((g,i)=>
                <MsNotify key={i}>{g}</MsNotify>
            )
            }
        </div>
    </div>
  )
}

export default ClientNotifications
