import Left from "./Left"
import Edit from "./Edit/Edit"
import Svg from "../../../../Components/Svg/Svg"
import SvgOptions from "../../../../Components/Svg/SvgOptions"
const Route=[
    {
        element:Edit, 
        path:"Edit",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
]
const defaultRoute={
    element:Left, 
    path:"*",
    access:{user:true, admin:true, super:true}, 
    Protected:false,
    default:true,
    icon:SvgOptions.Dashboard
}
export default Route;
export {defaultRoute}