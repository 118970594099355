import jwtDecode from "jwt-decode";
import { redirect } from "react-router-dom";

let biodata=null;
let token=null;
let role=null;
let roles=null;
let jobInit=null;
const User={
    setBiodata:(staffBiodata)=>{
        biodata=staffBiodata;
        localStorage.setItem("biodata", JSON.stringify(biodata));
    },
    getBiodata:()=>{
        biodata=biodata?biodata:JSON.parse(localStorage.getItem("biodata"));
        return biodata;
    },
    getBase:()=>{
        return "/"+User.getBiodata().appRoles[0].base+"/";
    },
    Logout:()=>{
        biodata=null;
        token=null;
        role=null;
        roles=null;
        localStorage.clear()
    },
    setToken:(new_token)=>{
        token=new_token;
        localStorage.setItem("token", token);
    },
    getToken:()=>{        
        token=token?token:localStorage.getItem("token")
        const decode=jwtDecode(token);
        if(decode.exp < parseInt(Date.now()/1000))window.location.href="/login"
        return token;
    },
    setRole:(user_role)=>{
        role=user_role;
        localStorage.setItem("role", JSON.stringify(role));
    },
    getRole:()=>{
        role=role?role:JSON.parse(localStorage.getItem("role"));
        return role;
    },
    setRoles:(user_roles)=>{
        roles=user_roles;
        localStorage.setItem("roles", JSON.stringify(roles));
    },
    getRoles:()=>{
        roles=roles?roles:JSON.parse(localStorage.getItem("roles"));
        return roles;
    },
    setJobInit:(job_init)=>{
        jobInit=job_init;
        localStorage.setItem("jobInit", JSON.stringify(jobInit));
    },
    getJobInit:()=>{
        jobInit=jobInit?jobInit:JSON.parse(localStorage.getItem("jobInit"));
        return jobInit;
    },

}
export default User;