import css from "../Details.module.css"
import style from "./Edit.module.css"
import Form from "../../../../../Components/Form/Form";
import FormOptions from "./FormOptions";
import Head from "../../../../../Components/Head/Head";
import Utils from "../../../../../Services/Utills";
import { Get, Post } from "../../../../../Services/Server";
import Url from "../../../../../Services/Url";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import logoholder from "../../../../../Assets/Images/logoPlaceholder.png"

const Edit = ({state, title, logo, onChange}) => {
  const [logoImg, setLogo]=useState(logo);
    const options =FormOptions;
    const navigate=useNavigate();
    const set=(name)=>{
        if(options.Fields.find(f=>f.name===name))
        options.Fields.find(f=>f.name===name).value=state[name];
    }
    useEffect(()=>{
      if(state.id){
        logo.company={id:state.id}
        Get(Url.company.get.logo(state.id)).then(resp=>{
          if(resp.id){
            logo.id=resp.id;
          }
        })
      for(const key in state)set(key);
      }  
    },[])
    const handleSubmit=(e)=>{
        const dt=Utils.FormObject(e);
        for(const k in dt)state[k]=dt[k];
        if(onChange)
        onChange(state);
        else{
        Post(Url.saveCompany, state).then(resp=>{
          if(logo.data!=logoholder){
            Post(Url.company.save.logo,{...logo, company:{id:resp.id}}).finally(()=>{
              navigate("..");
            })
          }else{
            navigate("..");
          }
        });
        }
    }
    const handleChange=(e)=>{
      Utils.getBase64(e.target.files[0], (resp)=>{
        logo.data=resp;
        setLogo({...logo, data:resp})
      })
    }
  return (
    <div className={css.Left}>
        <Head state={state} title={title?title:state.name}></Head>
        <div className={style.Edit}>

        <div className={style.Top}>
          <div className={style.Logo}>
            <img src={logoImg.data}></img>
          </div>
          <div>
            <div>
            You can upload logo up to 256x256. The Logo will shows up in the company's profile and reports.              
            </div>
            <div className={style.File}>
              <input id="logoFile" type="file" onChange={handleChange} />
              <label htmlFor="logoFile">
                Upload Logo
              </label>
            </div>
            
          </div>
        </div>
        <Form onSubmit={handleSubmit}>{options}</Form>
        </div>
    </div>
  )
}
export default Edit;