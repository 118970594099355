import ProgressBar from "../ProgressBar/ProgressBar";
import "./EngrTasks.css";
import Executor from "../Executor/Executor";
const EngrTasks=({children, ops})=>{
    //const {staffBiodata, previousTasks}=children;
    return(
        <div className="EngrTasks">
            <div className="EngrTaskDetails">
                <Executor>{children}</Executor>
                <div className="ETCount">
                    <h5>{ops?children.currentOpMonthJobs:children.currentMonthJobs}</h5>
                </div>
            </div>
                <ProgressBar progress="100" className="ElectricBlue"></ProgressBar>
        </div>
    )
}
export default EngrTasks