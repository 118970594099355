import Button from "../../../Components/Button/Button";
import ViewContract from "../ViewContract/ViewContract";
import css from "./ContractSigned.module.css";

const ContractSigned = ({ ModalAction }) => {
  const showContract = () => {
    const Options = {
      title: "View Contract",
      element: ViewContract,
      closeModal: ModalAction.closeModal(),
    };
    ModalAction.openModal(Options, "View Contract");
  };

  return (
    <div className={css.ContractSigned}>
      <div className={css.Top}>
        <div>
          <p className={css.Title}>Service:</p>
          <p>Installation Icom 402</p>
        </div>
        <div>
          <p className={css.Title}>Your Order Number:</p>
          <p>42004297</p>
        </div>
      </div>
      <div className={css.Alert}>
        <p className={css.AlertTitle}>Contract Signed</p>
        <p className={css.AlertMessage}>
          Your request is in good hands. We sent you an email with the job's
          tracking number.
        </p>
      </div>
      <Button className="Btn-Mid Inverse" onClick={showContract}>
        View Contract
      </Button>
      <div className={css.Details}>
        <p className={css.Title}>Estimated Delivery:</p>
        <p className={css.Important}>Monday, 18 December</p>
      </div>
      <div>{/* <MilestoneG>{}</MilestoneG> */}</div>
    </div>
  );
};

export default ContractSigned;
