import React from 'react'
import css from "../ReportModal/ReportModal.module.css"
import Button from '../../Components/Button/Button'
import { useState, useEffect } from 'react'
import SvgOptions from '../../Components/Svg/SvgOptions'
import Url from '../../Services/Url'
import { Get } from '../../Services/Server'
import { toast } from 'react-toastify'
const WizardOption = ({children,task, onClose, onNext, current}) => {
    const items=task.taskEquipmentList;
    const [options, setOptions]=useState(items)
    const [selected, setSelected]=useState();
    useEffect(()=>{
      setSelected(options.find(op=>op.selected))
    },[options])
    const handleSelect=(op)=>{
      options.map(i=>i.selected=false);
      options.find(item=>item.id===op.id).selected=true;
      setOptions([...options])
      //setSelected(op)  
    }
    const handleNext=()=>{      
      if(!selected){
        toast("Please select an equipment")
        return
      }
      Get(Url.getEquipmentReport(selected.id)).then(resp=>{       
        children.report=resp     
        children.selected=selected;
        onNext()
      })    

    }
  return (
    <div className={css.Report}>
    <div className={css.Head}><h2>Select Equipment</h2></div>
    <div className={css.ReportBody}>
    <div className={css.FormControl}>
        <div className={css.hgap10}>
        {options&&
        options.map((op, index)=>
            <Button key={index} onClick={()=>handleSelect(op)} icon={op.selected&&SvgOptions.Check} className="Btn-Small Inverse">{op.name}</Button>
        )
        }
        </div>
    </div>

    </div>
    <div className={css.Foot}>
        <div className={css.Buttons}>
        <Button className="Btn-Mid" onClick={onClose}>Close</Button>
        <Button className="Btn-Mid Active" onClick={handleNext}>Next</Button>                
        </div>
    </div>
</div>
  )
}

export default WizardOption