import Button from "../../Components/Button/Button"
import css from "./ReportModal.module.css"
import Pictures from "../../Components/Form/Pictures/Pictures"
import { useRef, useState, useEffect } from "react"
import { Get } from "../../Services/Server"
import Url from "../../Services/Url"
import SvgOptions from "../../Components/Svg/SvgOptions"
import { toast } from "react-toastify"

const ReportOptions = ({children, onNext, onClose, task}) => {
  const items=task.taskEquipmentList;
  const [options, setOptions]=useState(items)
  const [selected, setSelected]=useState();
  useEffect(()=>{
    setSelected(options.find(op=>op.selected))
  },[options])
  const handleSelect=(op)=>{
    options.map(i=>i.selected=false);
    options.find(item=>item.id===op.id).selected=true;
    setOptions([...options])
    //setSelected(op)
  }
  const handleNext=()=>{
    if(!selected){
        toast("Please select an equipment");
        return;
    }
    children.taskEquipment=[{id:selected.id, name:selected.name}];
    //onNext(children);
    Get(Url.getEquipmentReport(selected.id)).then(resp=>{
        children.initial=resp.initial;
        children.logs=resp.logs;
        //children.taskEquipment=resp.taskEquipmentList
        children.debrief=resp.debrief
        children.id=resp.id
        onNext(children);
    }).catch(err=>{
        console.log(err)
    })
  }
  return (
    <div className={css.Report}>
    <div className={css.Head}><h2>Select Equipment</h2></div>
    <div className={css.ReportBody}>
    <div className={css.FormControl}>
        <div className={css.hgap10}>
        {options&&
        options.map((op, index)=>
            <Button key={index} onClick={()=>handleSelect(op)} icon={op.selected&&SvgOptions.Check} className="Btn-Small Inverse">{op.name}</Button>
        )
        }
        </div>
    </div>

    </div>
    <div className={css.Foot}>
        <div className={css.Buttons}>
        <Button className="Btn-Mid" onClick={onClose}>Close</Button>
        <Button className="Btn-Mid Active" onClick={handleNext}>Next</Button>                
        </div>
    </div>
</div>
)
}

export default ReportOptions