import { Routes, Route } from "react-router-dom"
import NavRouter from "./NavRouter"
const RouteView=({children, defaultRoute, emptyRoute, ModalAction, navoption, props})=>{
    if(ModalAction){
        return(
            <Routes>
            {children.map((op, index)=>
            <Route key={index} path={op.path} element={<op.element navoption={op} ModalAction={ModalAction} {...props}></op.element>}></Route>
            )}
            {emptyRoute&&
                <Route path="" element={<emptyRoute.element navoption={navoption} ModalAction={ModalAction} {...props}></emptyRoute.element>} ></Route>
            }
            {defaultRoute?
            <Route path="*" element={<defaultRoute.element navoption={navoption} ModalAction={ModalAction} {...props}></defaultRoute.element>}></Route>
            :
            <Route path="*" element={<div><h1>Error Page</h1></div>}></Route>
            }
        </Routes>
        )
    }
    return (
        <Routes>
            {children.map((op, index)=>
            <Route key={index} path={op.path} element={<NavRouter>{op}</NavRouter>}></Route>
            )}
            {defaultRoute?
            <Route path="*" element={<NavRouter>{defaultRoute}</NavRouter>}></Route>
            :
            <Route path="*" element={<div><h1>Error Page</h1></div>}></Route>
            }
        </Routes>
    )
}
export default RouteView;