import DateTime from "./DateTime/DateTime";
import Input from "./Input/Input";
import Select from "./Select/Select";
import Slider from "./Sider/Slider";
import TextArea from "./TextArea/TextArea";

const FormType = {
  Input,
  Select,
  TextArea,
  DateTime,
  Slider,
};
export default FormType;
