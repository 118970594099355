import css from "./AITroubleshooting.module.css";

const chats = [
  {
    id: 1,
    from: "AI",
    message:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    time: "12:00",
  },
  {
    id: 2,
    from: "User",
    message:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    time: "12:00",
  },
  {
    id: 3,
    from: "AI",
    message:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    time: "12:00",
  },
  {
    id: 4,
    from: "User",
    message:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    time: "12:00",
  },
  {
    id: 5,
    from: "AI",
    message:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    time: "12:00",
  },
  {
    id: 6,
    from: "AI",
    message:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    time: "12:00",
  },
  {
    id: 7,
    from: "User",
    message:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    time: "12:00",
  },
  {
    id: 8,
    from: "User",
    message:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    time: "12:00",
  },
  {
    id: 9,
    from: "User",
    message:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    time: "12:00",
  },
  {
    id: 10,
    from: "User",
    message:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    time: "12:00",
  },
];

const AITroubleshooting = () => {
  return (
    <div className={css.AITroubleshooting}>
      <div className={css.Top}>
        <div className={css.Title}>AI Troubleshooting</div>
        <div className={css.ChatArea}>
          {chats?.map((chat) => (
            <div
              key={chat.id}
              className={chat.from === "AI" ? css.AIMessages : css.UserMessages}
            >
              <div>{chat.message}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={css.UserInput}>
        <input type="text" placeholder="Enter your problem" />
      </div>
    </div>
  );
};

export default AITroubleshooting;
