import css from "./Review.module.css";
import { forwardRef, useImperativeHandle } from "react";

const Review = forwardRef(({ init }, ref) => {
	const contract = init.contract || {};
	const vessel = init.vessel || {};
	const contact = init.contact || {};
	const equipment = init.equipment || {};
	const { billingInformation, subscriberInformation, contractSign } = contract;
	useImperativeHandle(ref, () => ({
		data() {
			return { id: init.id };
		},
	}));
	return (
		<div className={css.Review}>
			<p className={css.Title}>Contract Review</p>
			<div>
				<p className={css.SubTitle}>Service Provider Information</p>
				<div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Company: </p>
							<p>Fieldbase Services Limited</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>Email: </p>
							<p>sales@fbase.co.uk</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Company Number: </p>
							<p>F10637893</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>Phone: </p>
							<p>07425-155-321</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Vat Number: </p>
							<p>313 7483 09</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>County: </p>
							<p>United Kingdom</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Contact First Name: </p>
							<p>Chinedu</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>Province: </p>
							<p>Greater London</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Contact Last Name: </p>
							<p>Abanobi</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>City: </p>
							<p>London</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Role: </p>
							<p>Managing Director</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>Postal Code: </p>
							<p>EC3V 3NG</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Also Designated as: </p>
							<p>-</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>Address: </p>
							<p>International House, 36-38 Cornhill</p>
						</div>
					</div>
				</div>
			</div>
			{subscriberInformation && (
				<div>
					<p className={css.SubTitle}>Subscriber Information</p>
					<div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Company: </p>
								<p>{subscriberInformation.company?.name}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Email: </p>
								<p>{contact?.email}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Designation: </p>
								<p>{subscriberInformation.designation?.name}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Phone: </p>
								<p>{contact?.phone}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Vessel Name: </p>
								<p>{vessel?.name}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>County: </p>
								<p>{subscriberInformation?.country?.toUpperCase()}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>First Name: </p>
								<p>{contact?.firstname?.toUpperCase()}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>State/Province: </p>
								<p>{subscriberInformation?.state?.toUpperCase()}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Last Name: </p>
								<p>{contact?.lastname?.toUpperCase()}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>City: </p>
								<p>{subscriberInformation.city?.toUpperCase()}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Role: </p>
								<p>{subscriberInformation.role?.name.toUpperCase()}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Postal Code: </p>
								<p>{subscriberInformation.postalCode?.toUpperCase()}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Address: </p>
								<p style={{ gridColumnStart: 1, gridColumnEnd: 4 }}>
									{subscriberInformation.address?.toUpperCase()}
								</p>
							</div>
						</div>
					</div>
				</div>
			)}
			{billingInformation && (
				<div>
					<p className={css.SubTitle}>Billing Information</p>
					<div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Company: </p>
								<p>{billingInformation.company?.name}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Email: </p>
								<p>{billingInformation.email}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Firstname: </p>
								<p>{billingInformation.firstname?.toUpperCase()}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Lastname: </p>
								<p>{billingInformation.lastname?.toUpperCase()}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Phone: </p>
								<p>{billingInformation.phone}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Country: </p>
								<p>{billingInformation.country?.toUpperCase()}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>State/Province: </p>
								<p>{billingInformation.state?.toUpperCase()}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>City: </p>
								<p>{billingInformation.city?.toUpperCase()}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Postal Code: </p>
								<p>{billingInformation.postalCode}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Address: </p>
								<p style={{ gridColumnStart: 1, gridColumnEnd: 4 }}>
									{billingInformation.address}
								</p>
							</div>
						</div>
					</div>
				</div>
			)}
			<div>
				<p className={css.SubTitle}>Terms &amp; Condition</p>
				<div>
					<ol>
						{equipment.map((eq, index) => (
							<div key={index}>
								{eq.template.clauseList
									.filter((te) => eq.selectedProvisions.indexOf(te.id) !== -1)
									.map((pr) => (
										<li key={pr.id}>
											<div className={css.ListTitles}>{pr.name}</div>
											<p
												className={css.ClauseDetails}
												dangerouslySetInnerHTML={{ __html: pr.details }}
											></p>
										</li>
									))}
							</div>
						))}
					</ol>
				</div>
			</div>

			<div>
				<p className={css.SubTitle}>Signature</p>
				<div className={css.InfoRow}>
					<div>
						<p className={css.SignatureNames}>Chinedu Abanobi</p>
						<p>Managing Director at Fieldbase</p>
					</div>
					<div>
						<p className={css.SignatureNames}>{contact?.name}</p>
						<p>{subscriberInformation?.company?.name}</p>
					</div>
				</div>
				<div className={css.InfoRow}>
					<div>
						<img src="/signature.png" alt="signature" />
						<p className={css.Date}>Date: 12 Jan 2024</p>
					</div>
					{contractSign && (
						<div>
							<img src={contractSign.data} alt="signature" />
							<p className={css.Date}>
								{new Date(contractSign.time).toDateString() +
									" " +
									new Date(contractSign.time).toLocaleTimeString()}
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
});

export default Review;
