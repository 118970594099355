const Time={
    getDateTime:(str)=>{
        const d=new Date(str);
        return d.getDate()+"/"+(d.getMonth() +1)+"/"+d.getFullYear()+" "+d.toLocaleTimeString();
    },
    getDate:(str)=>{
        const d=new Date(str);
        return d.getDate()+"/"+(d.getMonth() +1)+"/"+d.getFullYear();
    }
}
export default Time;