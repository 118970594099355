import "./ImgThumb.css"
const ImgThumb=({children, className, size})=>{
    const cssClass=className?className+" UserRoundThumb":"UserRoundThumb";
    const style=size?{height:size, width:size}:{};
    return(
        <div className={cssClass} style={style}>
            <img src={children} style={style} alt="user" />
        </div>
    )
}
export default ImgThumb;