import css from "./Pictures.module.css"
import Svg from "../../Svg/Svg";
import SvgOptions from "../../Svg/SvgOptions";
import Utils from "../../../Services/Utills";
import ScrollContainer from "react-indiana-drag-scroll";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { UploadFile } from "../../../Services/Server";
import Picture from "./Picture";
const Pictures=({onChange, load, onDelete})=>{
    const id="File-"+(Date.now() + (Math.random()*1000000));
    const [Uploads, setUploads]=useState([]);
    useEffect(()=>{
        if(load){
            load.length&&setUploads(load);
        }
    },[load])

    const isValidFile=(filename)=>{
        const exts=["png", "gif", "jpg" , "jpeg" , "jfif" , "pjpeg", "pjp"]
        if(!filename)return false;
        const ext=filename.split(".").pop();
        return exts.find(ex=>ex.localeCompare(ext.toLowerCase())===0)        
    }

    const onUpload=(e)=>{
        const filename=e.target.value
        const upload={value:null, uploadType:null, uploadId:null}
        if(isValidFile(filename)){
            const fileNameArr=filename.split('\\');
            const name=fileNameArr[fileNameArr.length-1]
            UploadFile(e.target.files[0], name).then(resp=>{
                upload.uploadId=resp.id;
            }).catch(err=>{
                Error(err)
                console.log(err)
            })            
            Utils.getBase64File(e.target.files[0], (result)=>{
                upload.uploadType=result.split(";")[0].substring(5);
                upload.value=result;            
                setUploads([upload, ...Uploads])
            })
        }else toast("Selected file is not an image")
    }
    const handleDelete=(del)=>{
        setUploads(Uploads.filter(img=>img!=del));
        onDelete&&onDelete(del);
    }
    useEffect(()=>{
        onChange&&onChange(Uploads);
    },[Uploads])
    return(
        <div className={css.Wrapper}>
            <input id={id} onChange={onUpload} className={css.FileInput} type="file" />
            <div className={css.Cont}>
                <div className={css.Label}>
                    <label htmlFor={id}>
                        <div><center><Svg className={css.Photo}>{SvgOptions.AddPhoto}</Svg> Upload Photo</center></div>
                    </label>
                </div>
                <div className={css.UploadedCont}>
                    {Uploads.length?<ScrollContainer><div className={css.Uploaded}>
                        {Uploads.map((src, Index)=>
                            <div key={Index} className={css.Image}>
                                <div><Picture>{src}</Picture></div>
                                <div className={css.Close}><Svg onClick={()=>handleDelete(src)} className={css.CloseIcon}>{SvgOptions.Close}</Svg></div>
                            </div>
                        )}
                    </div></ScrollContainer>:<div className={css.NoUpload}> No picture uploaded</div>}
                </div>
            </div>
        </div>
    )
}
export default Pictures;