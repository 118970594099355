import style from "./RoundedStatus.module.css"
import SvgOptions from "../Svg/SvgOptions"
import Svg from "../Svg/Svg"
const RoundedStatus=({status, btnReject=false, onClick})=>{
    let state=null
    if(status!==2){
        if(status===3){
            state={
                backgroundColor: "var(--green)",
                color: "white"
            }
        }
        else{
            state={
                color: "var(--blue25)",
                border:"1px solid var(--blue25)"
            }
        }    
    }else{
        state={
            backgroundColor: "var(--electricblue)",
            color: "white",
        }
    }
    const closestate={
        backgroundColor: "var(--red)",
        color: "white",
    }

    return(
        <div className={style.RoundedStatusCont}>
           {(status===2 && btnReject)&&
                <div onClick={()=>onClick(false)} className={style.RoundedClose} style={closestate}>
                <div>
                    <Svg className="StatusImg">{SvgOptions.Close}</Svg>
                </div>
            </div>       
            }
 
            <div onClick={()=>onClick(true)} className={style.RoundedStatus} style={state}>
                <div>
                    <Svg className="StatusImg">{SvgOptions.Check}</Svg>
                </div>
            </div>       
        </div>
    )
}
export default RoundedStatus;