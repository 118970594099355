import React from 'react'
import css from "./Style.module.css"
import { Get, Error } from '../../../Services/Server'
import { useState, useEffect } from 'react'
import DataTable from '../../../Components/DataTable/DataTable'
import PageHead from '../../../Components/PageHead/PageHead'
import { useNavigate } from 'react-router-dom'
import Url from '../../../Services/Url'

const Reader=(rec)=>{
    return [
        rec.title,rec.jobNumber,rec.companyName,rec.location,rec.createdAt]
  }  
const TaskView = ({navoption, ModalAction}) => {
    const heading=["Job Number","Company", "Location", "Submitted on"];
    const[current, setCurrent]=useState({heading:["Current Expenses", ...heading]});
    const[previous, setPrevious]=useState({heading:["Previous Expenses", ...heading]});
    const flex=[20,20, 20,20,20];
    
    const nav=useNavigate();
    const btns=[];
  const load=(state, page, cb)=>{
    Get(Url.enterprise.userTaskList(state, page)).then(resp=>{
      cb(resp)
    }).catch(err=>{
      Error(err)
    })  
  }
  const loadCurrent=(page)=>{
    load(true, page, (resp)=>{
      setCurrent({...current, data:resp});
    })
  }
  const loadPrevious=(page)=>{
    load(false, page, (resp)=>{
      setPrevious({...previous, data:resp});
    })
  
  }
  useEffect(()=>{
    loadCurrent(0);
    loadPrevious(0);
  },[])
  
  const handleNextCurrent=(e)=>{
    loadCurrent(e)
  }
  const handleNextPrevious=(e)=>{
    loadPrevious(e)
  }
  const handleClick=(e)=>{
    nav(""+e.id)
  }
    return (
      <div className={css.TaskView}>
        <PageHead navoption={navoption} buttons={btns}></PageHead>
        <div>
          {current.data&&<DataTable handlePagination={handleNextCurrent} reader={Reader} onClick={handleClick} flex={flex}>{current}</DataTable>}
        </div>
        <div>
          {previous.data&&<DataTable handlePagination={handleNextPrevious} reader={Reader} onClick={handleClick} flex={flex}>{previous}</DataTable>}
        </div>
  
      </div>
    )
  }
  
export default TaskView
