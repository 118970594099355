import Button from "../Button/Button";
import css from "./Pagination.module.css";

const Pagination = ({ pageable, totalPages, onClick }) => {
  const handleClick = (pageNumber) => {
    const pg =
      pageNumber > 0
        ? pageNumber >= totalPages
          ? totalPages - 1
          : pageNumber
        : 0;
    onClick && onClick(pg);
  };

  return (
    <div className={css.Pagination}>
      {totalPages > 0 && (
        <div className={css.Center}>
          <div>
            <Button onClick={() => handleClick(0)} className="Btn-Mid Active">
              {"<<"}
            </Button>
          </div>
          <div>
            <Button
              onClick={() => handleClick(pageable.pageNumber - 1)}
              className="Btn-Mid Active"
            >
              {"<"}
            </Button>
          </div>
          <div>
            <Button className="Btn-Mid Inverse">
              {pageable.pageNumber + 1 + "/" + totalPages}
            </Button>
          </div>
          <div>
            <Button
              onClick={() => handleClick(pageable.pageNumber + 1)}
              className="Btn-Mid Active"
            >
              {">"}
            </Button>
          </div>
          <div>
            <Button
              onClick={() => handleClick(totalPages - 1)}
              className="Btn-Mid Active"
            >
              {">>"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagination;
