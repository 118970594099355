import FormType from "../../../../Components/Form/FormType";
import Url from "../../../../Services/Url";

const AddStarlinkJobFormOptions = (initialValues = {}) => ({
	Fields: [
		{
			element: FormType.Select,
			required: true,
			name: "company",
			label: "Company",
			value: null,
			placeholder: `Select Company: ${initialValues.companyName}`,
			options: {
				url: Url.enterpriseCompany.allCompany,
				id: "id",
				name: "name",
			},
		},
		{
			element: FormType.Select,
			required: true,
			name: "contact",
			value: null,
			label: "Contact",
			placeholder: `Select Contact: ${initialValues.firstName} ${initialValues.lastName}`,
			options: null,
		},
		{
			element: FormType.Input,
			required: true,
			name: "email",
			label: "Email",
			placeholder: "Enter contact's email",
		},
	],
});

export default AddStarlinkJobFormOptions;
