import Wizard from "../../Components/Wizard/Wizard";
import AddTaskModal from "./AddTaskModal";
import AddEquipmentModal from "./AddEquipmentModal";
import AddEngineersModal from "./AddEngineersModal";
import TaskTemplateModal from "./TaskTemplateModal";
import AddMailCopy from "./AddMailCopy";
import { Error, Get } from "../../Services/Server";
import { useState, useEffect } from "react";
import Url from "../../Services/Url";
const AddTaskWizard = ({ children }) => {
	const { closeModal, jobId } = children;
	const pages = [
		AddTaskModal,
		AddEquipmentModal,
		TaskTemplateModal,
		AddMailCopy,
		AddEngineersModal,
	];
	const [job, setJob] = useState();
	useEffect(() => {
		Get(Url.sales.getEnterpriseInitial(jobId))
			.then((resp) => {
				setJob({
					initialId: jobId,
					location: resp.company.address,
					taskEquipmentList: resp.equipment.reduce(
						(a, v, index) => [
							...a,
							{ index: index, equipment: v.equipment, type: v.service },
						],
						[]
					),
				});
			})
			.catch((err) => {
				Error(err);
			});
	}, []);

	return (
		<>
			{job && (
				<Wizard width={500} onClose={closeModal} init={job}>
					{pages}
				</Wizard>
			)}
		</>
	);
};

export default AddTaskWizard;
