import "./PieChart.css";
import Charts from "./Charts";
import { useEffect, useState } from "react";
import * as echarts from 'echarts';
//var myChart;
const BarChart=({children , chartId, op})=>{
    const getData=(index)=>{
        const dt=children.reduce((a, v)=>{
            const lst=op?[v.currentOpMonthJobs, v.currentOpYearJobs]:[v.currentMonthJobs, v.currentYearJobs];
            return [...a,
            {value:lst[index],
            name:v.firstname,itemStyle:{color:"#14B8FF"}
            }]},[]) 
            return dt;
    }
    const xdata=children.map((v)=>v.firstname)
    const [options, setOption]=useState([{text:"Month", css:"ActiveRange", index:0},{text:"Year",css:null, index:1}])
    const [myChart, setChart]=useState();
    useEffect(()=>{      
        if(!myChart){
            setChart(echarts.init(document.getElementById(chartId)));
        }
    },[]);
    useEffect(
        ()=>{      
            if(myChart){
                    const dd=getData(0);
                    const op=Charts.getBar([...dd], [...xdata]);
                    myChart.setOption(op);                
            }
        },[myChart])

    useEffect(
        ()=>{      
            const index=options.findIndex(o=>o.css);
            if(myChart){
                    const dd=getData(index);
                    myChart.setOption(Charts.getBar(dd, xdata));                
            }
        },[options])

    const setSelected=(op)=>{
        const lst=options.map(o=>{o.css=o.text===op.text?"ActiveRange":null; return o});
        setOption([...lst])
    }
    return(
        <div style={{width:"100%"}} className="PieChart">
            <div className="PieHead">
                <div>
                    <h4>Jobs Overview</h4>
                </div>
                <div className="PieRange">
                    {options.map(op=>
                       <div onClick={()=>setSelected(op)} key={op.text} className={op.css}>{op.text}</div>
                    )}
                </div>
            </div>          
            <div id={chartId} style={{width:"100%", height:"500px"}}></div>
        </div>
    )
}
BarChart.defaultProps={
    chartId:"BarChart"
}

export default BarChart;