import React from 'react'
import MilestoneG from '../Milestone/MilestoneG'
const MilestonesG = ({children}) => {
  return (
    <div>
      {children.map((mg, index)=><MilestoneG key={index}>{mg}</MilestoneG>)}
    </div>
  )
}

export default MilestonesG
