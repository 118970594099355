/*const Roles=new Map([
    [1, "Admin"],
    [2, "Engineer"],
    [3, "Engineer"],
    [4, "Operation"]
])
*/
const app_id=1;
const Roles={
    has:(app_roles)=>{
        const app_role=app_roles.find(role=>role.app.id===app_id);
        return app_role?true:false;
    },
    get:(app_roles)=>{
        return app_roles.find(role=>role.app.id===app_id);
    }
}
export default Roles;