import Milestone from "../Milestone2/Milestone";
import style from "./StatusGroup.module.css";

const StatsGroup = ({ children, activeUser, title, onChange, ts,  updateUrl }) => {
	const list = children;
	let pointer = list.find((obj) => obj.Status === 0);
	const handleChange = (op) => {
		const group = children.find((ms) => ms.name === op.name).group;
		onChange((group.find((ms) => ms.id === op.id).status = op.status));
	};


	return (
		<div className={style.StatsGroup}>
			<div className={style.Title}>
				<h4>{title}</h4>
			</div>
			<div>
				{list.map((ms, index) => (
					<Milestone
						onChange={handleChange}
						pointer={pointer === ms}
						activeUser={activeUser}
						key={index}
						updateUrl={updateUrl}
					>
						{ms}
					</Milestone>
				))}
			</div>
		</div>
	);
};
export default StatsGroup;
