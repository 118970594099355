import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Button from "../../../../Components/Button/Button";
import Head from "../../../../Components/Head/Head";
import SalesMilestones from "../../../../Components/SalesMilestones/SalesMilestones";
import SvgOptions from "../../../../Components/Svg/SvgOptions";
import { Get } from "../../../../Services/Server";
import Url from "../../../../Services/Url";
import AddContract from "./AddContract/AddContract";
import AddRequirements from "./AddRequirements/AddRequirements";
import AddRiskAssessment from "./AddRiskAssessment/AddRiskAssessment";
import css from "./Details.module.css";

const Details = ({ navoption, init }) => {
  const { id } = useParams();
  const nav=useNavigate();
  const [sales, setDetails] = useState(init);
  const [title, setTitle] = useState();
  const [showNextForm, setShowNextForm]=useState(false);
  const [formIndex, setFormIndex]=useState(1);

  const contractView=()=>{
    return(
      <Button icon={SvgOptions.Receipt} className="Btn-Mid Active" onClick={()=>nav("addcontract")}>
      View Contract
    </Button>
    )
  }
 const forms=[AddRequirements, AddRiskAssessment, contractView];
 const btns=[{text:"Add Requirements"}, {text:"Add Risk Assessment"}, {onClick: ()=>nav("addcontract"),text:"Add Contract"}]
 console.log(sales);
 useEffect(() => {
    setTitle(
      init.equipment
        .reduce((a, v) => [...a, v.service.name + " of " + v.equipment], [])
        .join(" & ")
    );
  }, []);

  const onSaved = (e) => {
    //sales.status = e;    
    setDetails({...e});
    //console.log
  };
  useEffect(()=>{
    if(sales){
      setFormIndex(Object.keys(sales.status).filter(s=>sales.status[s].id===3).length -1);
      setShowNextForm(false);
    }
  }, [sales])


  const formDisplay=[]
  for(let i=0; i<formIndex; i++){
    const FormElement=forms[i];
    formDisplay.push(<FormElement key={i} onSaved={onSaved} init={sales} />)
  }
  const NextFormElement=forms[formIndex];
  if(formIndex<forms.length){
  if(!showNextForm){
  formDisplay[formIndex] =
    <Button icon={SvgOptions.Add} className="Btn-Mid Active" onClick={btns[formIndex]?.onClick?btns[formIndex].onClick: () => setShowNextForm(!showNextForm)} key={formIndex}>
      {btns[formIndex]?.text};
    </Button>
  }else{
    formDisplay[formIndex]=<NextFormElement key={formIndex} onSaved={onSaved} init={sales} />
  }
}

  return (
    <div className={css.Details}>
      {sales && (
        <div className={css.DetailsBody}>
          <Head title={`Job ${id}: ${title}`} />
          <div className={css.DetailsBodyInfo}>
            <div>
              <span>Order Number: </span>
              <span>Job {id}</span>
            </div>
            <div>
              <span>Vessel Name: </span>
              <span>{sales?.vessel?.name}</span>
            </div>
            <div>
              <span>Service: </span>
              <span>{title}</span>
            </div>
            <div>
              <span>Installation Port: </span>
              <span>{sales.portSchedule ? sales.portSchedule.port : ""}</span>
            </div>
            <div>
              <span>Company: </span>
              <span>{sales?.company?.name}</span>
            </div>
            <div>
              <span>Vessel ETA: </span>
              <span>{sales.portSchedule ? sales.portSchedule.eta : ""}</span>
            </div>
            <div>
              <span>Main Contact: </span>
              <span>{sales?.contact?.email}</span>
            </div>
            <div>
              <span>Vessel ETD: </span>
              <span>{sales.portSchedule ? sales.portSchedule.etd : ""}</span>
            </div>
          </div>
            <div className={css.FormsDisplay} style={{ paddingBottom: "10px" }}>{formDisplay}</div>
        </div>
      )}
      <div className={css.DetailsMilestones}>
        <h3>Milestones</h3>
        {sales && <SalesMilestones>{sales.status}</SalesMilestones>}
      </div>
    </div>
  );
};

export default Details;
