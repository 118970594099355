import React from "react";

import css from "./Contact.module.css";
import Logo from "../../Assets/svg/logo.svg";

const Completed = () => {
  return (
    <div className={css.Contact}>
      <div className={css.ContactLeft}>
        <div>
          <img src={Logo} alt="Fieldbase Logo" />
        </div>
        <div className={css.ContactLeftContent}>
          <p className={css.title}>Contact Us</p>
          <p className={css.text}>
            If you have any concerns or issues with your order, please
            <br /> don't hesitate to get in touch with us.
          </p>
          <p className={css.text}>
            <span className={css.key}>Email:</span> support@fbase.co.uk
          </p>
          <p className={css.text}>
            <span className={css.key}>Phone:</span> +234 9030 001 104
          </p>
        </div>
      </div>
      <div className={css.CompletedRight}>
        <div className={css.CompletedBox}>
          <p className={css.Title}>Form Submitted!</p>
          <p className={css.Details}>
            Your request is in good hands. One of our <br /> sales rep will be
            in touch soon!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Completed;
