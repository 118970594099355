import TaskDetails from "./TaskDetails/TaskDetails"
import TasksView from "./TaskView";
import SvgOptions from "../../../Components/Svg/SvgOptions";
import MilestoneRoute from "./MilestoneTemplates/MilestoneRoute";
import Report from "./Report/Report";
import Expenses from "./Expenses/Expenses";
const TaskNavOptions=[
    {
        element:MilestoneRoute, 
        path:"MilestoneTemplates/*",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
    {
        element:TaskDetails, 
        path:"TaskDetails",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
    {
        element:Report, 
        path:"Report",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
    {
        element:TaskDetails, 
        path:":id",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
    {
        element:Expenses, 
        path:"Expenses/:id",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },

]
const defaultRoute={
    element:TasksView, 
    path:"*",
    access:{user:true, admin:true, super:true}, 
    Protected:false,
    default:true,
    icon:SvgOptions.Dashboard
}
export {defaultRoute};
export default TaskNavOptions;