import { Route, Routes } from "react-router-dom";
import Logo from "../../Assets/svg/logo.svg";
import css from "./ClientContract.module.css";
import ContractSigned from "./ContractSigned/ContractSigned";
import ViewContract from "./ViewContract/ViewContract";
import User from "../../Services/User";
import { Error, Get } from "../../Services/Server";
import Url from "../../Services/Url";

const ClientContract = ({ ModalAction }) => {
  const job = User.getJobInit();
  return (
    <div className={css.ClientContract}>
      <div className={css.ContractLeft}>
        <div>
          <img src={Logo} alt="Fieldbase Logo" />
        </div>
        <div className={css.ContractLeftContent}>
          <p className={css.title}>Contact Us</p>
          <p className={css.text}>
            If you have any concerns or issues with your order, please don't
            hesitate to get in touch with us.
          </p>
          <p className={css.text}>
            <span className={css.key}>Email:</span> support@fbase.co.uk
          </p>
          <p className={css.text}>
            <span className={css.key}>Phone:</span> +234 9030 001 104
          </p>
        </div>
      </div>
      <div className={css.ContractRight}>
        <Routes>
          <Route
            path="*"
            element={<ViewContract job={job} ModalAction={ModalAction} />}
          />
          <Route
            path="signed"
            element={<ContractSigned ModalAction={ModalAction} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default ClientContract;
