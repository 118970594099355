import React from 'react'
import css from "./Style.module.css"
import PdfDoc from './PdfDoc';
import { PDFViewer } from '@react-pdf/renderer'

const ViewReportModal = ({task, children}) => {
  const {report, selected, logo}=children;
  return (
    <div className={css.Modal}>
      {selected&&
      <PDFViewer>
          <PdfDoc report={report} logo={logo}>{task}</PdfDoc>
      </PDFViewer>
      }      
    </div>
  )
}

export default ViewReportModal