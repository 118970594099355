import "./SearchBox.css";
import Svg from "../Svg/Svg";
import SvgOptions from "../Svg/SvgOptions";
const SearchBox=({onChange})=>{
    const handleChange=(e)=>{
        onChange&&onChange(e.target.value)
    }
    return(
        <div className="SearchBox">
            <div className="SearchIcon">
                <Svg>{SvgOptions.Search}</Svg>
            </div>
            <input type="text" name="Search" onChange={handleChange} placeholder="Search..." />
        </div>
    )
}
export default SearchBox;