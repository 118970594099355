import "./Confirm.css"
import SvgOptions from "../../Components/Svg/SvgOptions";
import Svg from "../../Components/Svg/Svg";
import Button from "../../Components/Button/Button";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
const Confirm=({onOk, onExit, children})=>{
    const {title, text, okText, exitText, icon, options}=children;
    let hasOption=false;
    if(options){
        if(options.length<=1)options[0].selected=true;
        else if(options.filter(op=>!op.merge).length) hasOption=true;
    }
    const [opList, setOptions]=useState(options)
    const handleSelect=(op)=>{
        options.find(i=>i.id===op.id).selected=!options.find(i=>i.id===op.id).selected;
        setOptions([...options])
    }
    const handleOk=()=>{
        if(options){
            if(options.filter(op=>op.selected).length)onOk(options)
            else toast("Please select at least one option")
        }else onOk();
    }
    return(
        <div className="Confirm">
            <div className="ConfirmContent">
                <div className="ConfirmIconCont"><Svg className="ConfirmIcon">{SvgOptions.Exit}</Svg></div>
                <div className="ConfirmText">
                    <div className="ConfirmTitle"><h2>{title}</h2></div>
                    <div className="COnfirmText">{text}</div>
                </div>
            </div>
            {hasOption&&
                <div className="ConfirmOptions">
                     {opList.map((op, index)=>
                            <Button onClick={()=>handleSelect(op)} icon={(op.selected===true)&&SvgOptions.Check} className="Btn-Tny Inverse" key={index}>
                                {op.equipment}
                            </Button>
                        )}      
                </div>
            }
            <div className="ConfirmControl">
                <div><Button onClick={onExit} className="Btn-Mid Inverse">{exitText}</Button></div>
                <div><Button onClick={handleOk} className="Btn-Mid Active">{okText}</Button></div>
            </div>
        </div>
    )
}
Confirm.defaultProps={
    children:{
        title:"Logging out?",
        text:"You are about to logout from fieldbase progress app",
        exitText:"Exit",
        okText:"Continue"
    }
}
export default Confirm;