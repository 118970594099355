import { useState } from "react";
import CheckBox from "./CheckBox/CheckBox";
import css from "./CheckBoxList.module.css";

const CheckBoxList = ({ checkboxes, onCheckboxChange }) => {
  const [checkedItems, setCheckedItems] = useState(
    checkboxes.reduce((acc, checkbox) => {
      acc[checkbox.name] = checkbox.isChecked;
      return acc;
    }, {})
  );

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
    onCheckboxChange(name, checked); // Update this line
  };

  return (
    <div className={css.CheckBoxList}>
      {checkboxes.map((checkbox) => (
        <CheckBox
          key={checkbox.name}
          name={checkbox.name}
          label={checkbox.label}
          isChecked={checkedItems[checkbox.name]}
          onChange={handleChange}
        />
      ))}
    </div>
  );
};

export default CheckBoxList;
