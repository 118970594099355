import "./Tasks.css";
import DataTable from "../../../Components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import PageHead from "../../../Components/PageHead/PageHead";
import { Get } from "../../../Services/Server";
import Url from "../../../Services/Url";
import User from "../../../Services/User";
import { useState, useEffect } from "react";
import AddTaskWizard from "../../../Modals/AddTaskModal.js/AddTaskWizard";
import AddPendingTaskWizard from "../../../Modals/AddPendingTask/AddTaskWizard";
const TaskReader=(rec)=>{
    return [
        rec.vesselName,rec.jobNumber,rec.companyName,rec.location,rec.createdAt]
}
const JobReader=(rec)=>{
    return [
        rec.vessel,rec.equipment,rec.company,rec.port,rec.createdAt]
}

const TasksView=({navoption, ModalAction})=>{
    const role=User.getRole();
    const heading=["Job Number","Company", "Location", "Started on"];
    const jobHeading=["Job","Company", "Location", "Started on"];
    const Navigate=useNavigate();
    const[current, setCurrent]=useState({heading:["Current Tasks", ...heading]});
    const[previous, setPrevious]=useState({heading:["Previous Tasks", ...heading]});
    const[pending, setPending]=useState({heading:["Pending Tasks", ...jobHeading]});
    const [view, setView]=useState(null);

    const loadPending = (pg) => {
		Get(Url.sales.getAllInitDTO(true, pg)).then((resp)=>{
			setPending({...pending, data:resp});
		})
	};

    const loadCurrent=(page)=>{
        Get(Url.userTasks(1, page)).then(resp=>{
            setCurrent({...current, data:resp});
        }).catch(err=>{
            console.log(err)
        })
    }
    const loadPrevious=(page)=>{
        Get(Url.userTasks(0, page)).then(resp=>{
            setPrevious({...previous, data:resp});
        }).catch(err=>{
            console.log(err)
        })
    }
    const loadClosed=(page)=>{
        Get(Url.closedTasks(page)).then(resp=>{
            setView({...current, data:resp})
        }).catch(err=>{
            console.log(err)
        })
    }
    const loadDeleted=(page)=>{
        Get(Url.trashedTasks(page)).then(resp=>{
            setView({...current, data:resp})
        }).catch(err=>{
            console.log(err)
        })
    }

    const loadData=(page)=>{
        loadCurrent(page);
        loadPrevious(page);
        loadPending(page);
    }

    useEffect(()=>{        
    loadData(0);
    },[])

    const onTaskClick=(e)=>{
        if(role.name=="account")
        Navigate(`Expenses/${e.id}`)
        else
        Navigate(`${e.id}`);
    }
    const flex=[20,20, 20,20,20];
    const btns=navoption.controls()
    const handleTemplate=()=>{
        Navigate("MilestoneTemplates");
    }
    const handleAddTask=()=>{
        const Options={
            title:"Add Task",
            element:AddTaskWizard,
            closeModal:(resp)=>{
                console.log(resp)
                loadData(0);
                ModalAction.closeModal();
            }
        }
        ModalAction.openModal(Options, "Add Task");
    }
    if(Array.isArray(btns)){
        btns.forEach(btn=>{
            if(btn.id===1)btn.onClick=handleTemplate;
            else if(btn.id===4)btn.onClick=()=>Navigate("report");
            else btn.onClick=handleAddTask})
    }
    const handleNextCurrent=(page)=>{
        console.log(page)
        loadCurrent(page)
    }
    const handleNextPending=(page)=>{
        loadPending(page)
    }
    const handleNextPrevious=(page)=>{
        loadPrevious(page)
    }
    const dropOptions=role.base==="admin"?{
        onClick:(e)=>{
            const op=e.find(o=>o.selected);
            if(!op)setView(null)
            else{        
                if(op.text==="Closed Tasks")        
                loadClosed(0);
                else if(op.text==="Deleted Tasks"){
                    loadDeleted(0);
                }
                //setView(op)
            }
            console.log(e.find(o=>o.selected))
        },
        list:["Closed Tasks","Deleted Tasks"]
    }:null;

    const handlePending=(e)=>{
        const Options={
            title:"Add Task",
            element:AddPendingTaskWizard,
            jobId:e.id,
            closeModal:(resp)=>{
                console.log(resp)
                loadData(0);
                ModalAction.closeModal();
            }
        }
        ModalAction.openModal(Options, "Add Task");
//        console.log(e)
    }
    return(
        <div className="Tasks">
            <PageHead dropOptions={dropOptions} navoption={navoption} buttons={btns}></PageHead>
            {
                view?
                <div className="CurrentTasks">
                    {view.data&&<DataTable handlePagination={handleNextCurrent} reader={TaskReader} onClick={onTaskClick} flex={flex}>{view}</DataTable>}
                </div>                
                :
                <>
                {(role.id===2 && pending?.data?.content?.length>0)&&
                <div className="CurrentTasks">
                    <DataTable handlePagination={handleNextPending} reader={JobReader} onClick={handlePending} flex={flex}>{pending}</DataTable>
                </div>
                }
                <div className="CurrentTasks">
                    {current.data&&<DataTable handlePagination={handleNextCurrent} reader={TaskReader} onClick={onTaskClick} flex={flex}>{current}</DataTable>}
                </div>
                <div className="CurrentTasks">
                    {previous.data&&<DataTable handlePagination={handleNextPrevious} onClick={onTaskClick} reader={TaskReader} flex={flex}>{previous}</DataTable>}
                </div>                
                </>
            }
        </div>
    )
}
export default TasksView;