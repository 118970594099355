import css from "./CheckBox.module.css";

const CheckBox = ({ label, isChecked, name, onChange }) => {
  return (
    <label className={css.CheckBox}>
      <input
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={onChange}
      />
      <span
        style={{
          color: isChecked ? "var(--electricblue)" : "var(--blue)",
          fontWeight: isChecked ? "bold" : "normal",
        }}
      >
        {label}
      </span>
    </label>
  );
};

export default CheckBox;
