import "./TaskItem.css";
import Svg from "../Svg/Svg";
import SvgOptions from "../Svg/SvgOptions";
import ProgressBar from "../ProgressBar/ProgressBar";
import TaskCompany from "../TaskComany/TaskCompany";
import { useNavigate } from "react-router-dom";
import User from "../../Services/User";
const TaskItem=({children})=>{
    const navigate=useNavigate();
    const {createdAt, title, current, completionPercentage}=children    
    // const taskMilestones=taskEquipmentList.reduce((a,v)=>[...a, ...v.taskMilestones],[]);
    const dt=new Date(createdAt);
    const dateString=`${dt.getDate()}-${dt.getMonth()}-${dt.getFullYear()}`
    const Progress=completionPercentage*100;
    //const progressClass=Completed?"ElectricBlue":(Progress===100?"Green":"Red");
    const progressClass="ElectricBlue"
    const biodata=User.getBiodata();
    const handleClick=()=>{
        const base=biodata.appRoles[0].base;
        navigate(`/${base}/Tasks/${children.id}`)        
    }
    return(
        <div className="TaskItem">
            <h4 onClick={handleClick}>{title}</h4>
            <div className="TaskItemDetails">             
                <div className={"TaskEndDate " + progressClass}>
                    <div className="TaskDateIcon"><Svg>{SvgOptions.Clock}</Svg></div>
                    <div className="TaskDateText">{dateString}</div>
                </div>
            </div>
            <div className="TaskProgress">
                <ProgressBar className={progressClass} progress={Progress}>{(Progress===100 && current)&&"Waiting Approval"}</ProgressBar>
            </div>
        </div>
    )
}
export default TaskItem;