import "./Button.css";
import Svg from "../Svg/Svg";

const Button = ({ onClick, className, children, icon, type }) => {
  const btnClass = children ? className : "iconbtn " + className;

  return (
    <button onClick={onClick} className={btnClass} type={type}>
      <div className="btnContent">
        {icon && (
          <div className="btnIcon">
            <Svg className="btnsvg">{icon}</Svg>
          </div>
        )}
        <div>{children}</div>
      </div>
    </button>
  );
};

Button.defaultProps = {
  className: "Default-Button",
};

export default Button;
