import React, { useEffect, useState } from 'react'
import AddTemplate from '../AddTemplate/AddTemplate'
import { useNavigate, useParams } from 'react-router-dom'
import { Error, Get } from '../../../../Services/Server';
import Url from '../../../../Services/Url';

const EditTemplate = ({ModalAction}) => {
    const [init, setInit]=useState()
    const {id}=useParams();
    const nav=useNavigate()
    useEffect(()=>{
        if(!id) nav("../")
        Get(Url.contract.get(id)).then(resp=>{
            setInit(resp)
        }).catch(err=>{
            Error(err)
        })
    },[])
   return (
    <>
    {init&&
        <AddTemplate ModalAction={ModalAction}>{init}</AddTemplate>
    }
    </>
  )
}

export default EditTemplate
