import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import Modal from 'react-modal';
import closeImg from "../Assets/Images/Close.png";
import { ToastContainer } from 'react-toastify';
import User from '../Services/User';
import { Navigate } from 'react-router-dom';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Confirm from '../Modals/Confirm/Confirm';
let ModalAction={openModal:null,
  closeModal:null,
  confirm:null,confirmOk:null,openPopup:null};
const NavRouter=({children})=>{
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding:"0px",
        },
      };    
      const [mOpen, setmOpen]=React.useState(false);
      const [mOptions, setmOptions]=React.useState(null);
      const [spOptions, setspOptions]=React.useState({isOpen:false, element:null})
      const [cOpen, setcOpen]=React.useState(false);
      const [cOptions, setcOptions]=React.useState(null);
      const [pOptions, setpOptions]=React.useState({isOpen:false})
      const [eOpen, seteOpen]=React.useState(false);
      const [Extend, setExtend]=React.useState(null);

      Modal.setAppElement("#root")
      ModalAction={
        confirm:(option)=>{
          setcOpen(true);
          setcOptions(option);  
        },
        confirmOk:(data)=>{
          if(cOptions.onOk){
            cOptions.onOk(data);
          }
          setcOpen(false);
        },
        openPopup:(Options)=>{
          setpOptions({...Options, isOpen:true})
        },
        closePopup:()=>{
          setpOptions({isOpen:false})
        },
        openExtend:(extend)=>{
          setExtend(extend);
          seteOpen(true);
        },
        closeExtend:()=>{
          setExtend(null);
          seteOpen(false);
        },
        openModal:(Options)=>{
          setmOptions(Options)
            setmOpen(true);
          },
          closeModal:()=>{
            setmOpen(false);
          },
          openSidePanel:(element)=>{
            setspOptions({isOpen:true, element})
          },
          closeSidePanel:()=>{
            setspOptions({isOpen:false, Options:null})
          }

      }
      if(children.Protected){
        if(!User.getToken()){
          return(<Navigate to="../login" />)
        }
      }
      return(
        <>
        <children.element ModalAction={ModalAction}></children.element>
        <Modal isOpen={mOpen} style={customStyles}>
          <div style={{display:"flex"}}>
          <div>
            <div className='ModalHead'>
              <div><h3>{mOptions?mOptions.title&&mOptions.title:""}</h3></div>
              <div className='btnModalClose' onClick={ModalAction.closeModal}><img onClick={ModalAction.clossModal} src={closeImg} alt='Close Modal' /></div>
            </div>
              {mOptions?<mOptions.element>{{...mOptions, element:null}}</mOptions.element>:""}
          </div>
          {eOpen&&
          <div id="extModal">{Extend}</div>
          }
          </div>
        </Modal>
        <Modal isOpen={cOpen} style={customStyles}>
          <div>
          {cOptions&&<Confirm onOk={ModalAction.confirmOk} onExit={()=>setcOpen(false)}>{cOptions}</Confirm>}
          </div>
        </Modal>
        <Modal isOpen={pOptions.isOpen} style={customStyles}>
          <div>
                {pOptions.element&&
                  <pOptions.element>{pOptions}</pOptions.element>
                }
          </div>
        </Modal>
        <ToastContainer />
        <SlidingPane
        hideHeader={true}
        onRequestClose={()=>{}}
        isOpen={spOptions.isOpen}
        from="right"
        width="500px"
      >
        <div>
          {spOptions.element&&<spOptions.element></spOptions.element>}
        </div>
      </SlidingPane>
        </>
    )
}
export {ModalAction}
export default NavRouter;