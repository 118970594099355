import FormType from "../../../../Components/Form/FormType";
const FormOptions={
    Fields:[
        [
            {element:FormType.Input, value:"", required:true, label:"First Name", name:"firstname", placeholder:"Enter firstname"},
            {element:FormType.Input, value:"", required:true, label:"Last Name", name:"lastname", placeholder:"Enter lastname"},
        ], 
        [
            {element:FormType.Input, value:"", required:true, label:"Username", name:"username", placeholder:"Enter Username"},
            {element:FormType.Input, value:"", required:true, label:"Staffnumber", name:"staffNumber", placeholder:"Enter Staff Number"},
        ],     
        [
            {element:FormType.Input, value:"", required:true, label:"Phone Number", name:"phone", placeholder:"Enter Phone number"},
            {element:FormType.Input, value:"", required:true, label:"Email", name:"email", placeholder:"Enter Email"},
        ],   
        [
            {element:FormType.Input, required:true, label:"Set Password", name:"password", placeholder:"Password"},
            {element:FormType.Input, required:true, label:"Confirm Password", name:"confirmPassword", placeholder:"Confirm Password"},
        ],   
    ],
    Buttons:[
        {text:"Save", className:"Btn-Mid Active"}  
    ]
}

const SecureOptions={
    Fields:[
        [
            {element:FormType.Input, required:true, label:"Current Password", name:"password", placeholder:"Current Password"},
            {element:FormType.Input, required:true, label:"New Password", name:"npassword", placeholder:"New Password"},
        ],   
    ],
    Buttons:[
        {text:"Save", className:"Btn-Mid Active"}  
    ]
}

export {SecureOptions}
export default FormOptions