import css from "./Page.module.css"
import Svg from "../Svg/Svg";
import SvgOptions from "../Svg/SvgOptions";
import { NavLink } from "react-router-dom";
const Page=({left, right, children, LTitle})=>{
return(
    <div className={css.Page}>
        {
            children?
            <div className={css.Children}>
                {children}
            </div>:
            <div className={css.Sides}>
                <div className={css.Left}>
                    <div className={css.LHead}>
                        <div>
                            <NavLink to="../"><Svg>{SvgOptions.ArrowBack}</Svg></NavLink>
                        </div>
                        {
                            LTitle&&
                            <div className={css.LTitle}>{LTitle}</div>
                        }
                    </div>
                    {left}
                </div>
                <div className={css.Right}>
                {right}
                </div>
            </div>
        }

    </div>
)
}
export default Page;