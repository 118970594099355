import css from "./Wizard.module.css"
import ProgressBar from "../ProgressBar/ProgressBar"
import { useEffect, useState } from "react"
import { ModalAction } from "../../Routes/NavRouter"
const Wizard = ({children, width, height, onNext, onClose, props, init}) => {
  const [page, setPage]=useState(0)
  const predata={...init};
  const [data, setData]=useState(predata);
  const {closeModal}=ModalAction;
  const len=children.length;
  const handleClose=(data)=>{
    onClose?onClose(data):closeModal();
  }
  const onNav=(nav)=>{
    if(nav>=0 && nav<=len){
      setPage(nav)
      onNext&&onNext(data)
    }
    else{
      handleClose()
    }
}

  return (
    <div className={css.Wizard} style={{width:`${width}px`, height:`${height}px`}}>
      <ProgressBar progress={100 * (page+1) /len} borderRadius={0} />
      <div className={css.PagesCont}>
            <div className={css.Step}> Step {page+1}/{len}</div>
            <div className={css.Pages} style={{transform:`translateX(-${width * page}px)`, width:`${width * len}px`}}>
      {
            children.map((Page, index)=>
            <div  key={index} style={{width:`${width}px`, overflow:"hidden"}}>
              <Page {...props} current={page} onClose={handleClose} onSet={setData} onNext={()=>onNav(page+1)} onBack={()=>onNav(page-1)}>{data}</Page>
            </div>
            )
        }
    </div>
    </div>
    </div>
  )
}
Wizard.defaultProps={
  width:600,
  height:620,
}
export default Wizard