import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import Button from "../../../../../Components/Button/Button";
import RoundedStatus from "../../../../../Components/RoundedStatus/RoundedStatus";
import Svg from "../../../../../Components/Svg/Svg";
import SvgOptions from "../../../../../Components/Svg/SvgOptions";
import css from "./AddContract.module.css";
import BillingDetails from "./BillingDetails/BillingDetails";
import Review from "./Review/Review";
import Terms from "./Terms/Terms";
import Url from "../../../../../Services/Url";
import { Post, Error } from "../../../../../Services/Server";
import { toast } from "react-toastify";

const AddContract = ({ init, onSaved }) => {
	const [contractStep, setContractStep] = useState(1);
	const [sales, setSales] = useState(init);
	const contract = sales?.contract;
	const [submitted, setSubmitted] = useState([
		contract?.billingInformation?.id > 0,
		contract?.contractTerms?.id > 0,
		contract?.contractReview?.id > 0,
	]);
	const termRef = useRef(),
		reviewRef = useRef(),
		billRef = useRef();
	let contractStepComponent = null;
	if (contractStep === 2) {
		contractStepComponent = <Terms ref={termRef} init={sales} />;
	} else if (contractStep === 3) {
		contractStepComponent = <Review ref={reviewRef} init={sales} />;
	} else {
		contractStepComponent = <BillingDetails ref={billRef} init={sales} />;
	}

	const refs = [billRef, termRef, reviewRef];
	const urls = [
		Url.sales.saveEnterpriseContractBilling,
		Url.sales.saveEnterpriseContractTerms,
		Url.sales.saveEnterpriseContractReview,
	];

	const handleSave = () => {
		const index = contractStep - 1;
		const dt = refs[index].current.data();
		Post(urls[index], dt)
			.then((resp) => {
				toast("Saved");
				submitted[index] = true;
				setSubmitted([...submitted]);
			})
			.catch((err) => {
				Error(err);
			});
	};

	const sendContract = () => {
		const dt = reviewRef.current.data();
		Post(Url.sales.sendEnterpriseContract, dt)
			.then(() => {
				toast("Sent");
			})
			.catch((err) => {
				Error(err);
			});
	};
	const status = (index) => {
		return contractStep === index ? 2 : submitted[index - 1] ? 3 : 1;
	};
	return (
		<div className={css.AddContract}>
			<div className={css.Top}>
				<NavLink to={"../"} state={{ init }} style={{ textDecoration: "none" }}>
					<div className={css.BackBox}>
						<Svg>{SvgOptions.BackArrow}</Svg>
						<span>Back</span>
					</div>
				</NavLink>
				<div className={css.TopProgress}>
					<div onClick={() => setContractStep(1)}>
						<RoundedStatus
							status={status(1)}
							onClick={() => setContractStep(1)}
						/>
						<span>Billing Details</span>
					</div>
					<div onClick={() => setContractStep(2)}>
						<RoundedStatus
							status={status(2)}
							onClick={() => setContractStep(2)}
						/>
						<span>Terms</span>
					</div>
					<div onClick={() => setContractStep(3)}>
						<RoundedStatus
							status={status(3)}
							onClick={() => setContractStep(3)}
						/>
						<span>Review</span>
					</div>
				</div>
				<div className={css.TopButtons}>
					<Button onClick={handleSave} className="Btn-Mid Inverse">
						Save
					</Button>
					<Button
						className="Btn-Mid Active"
						onClick={
							contractStep !== 3
								? () => setContractStep(contractStep + 1)
								: sendContract
						}
					>
						{contractStep === 3 ? "Send" : "Next"}
					</Button>
				</div>
			</div>
			<hr />
			<div className={css.Body}>{contractStepComponent}</div>
		</div>
	);
};

export default AddContract;
