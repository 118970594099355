import Wizard from "../../Components/Wizard/Wizard"
import AddTaskModal from "./AddTaskModal"
import AddEquipmentModal from "./AddEquipmentModal"
import AddEngineersModal from "./AddEngineersModal"
import TaskTemplateModal from "./TaskTemplateModal"
import AddMailCopy from "./AddMailCopy"
const AddTaskWizard = ({children}) => {
  const {closeModal} =children;
    const pages=[AddTaskModal, AddEquipmentModal,TaskTemplateModal, AddMailCopy,AddEngineersModal]
  return (
    <Wizard width={500} onClose={closeModal}>
        {pages}
    </Wizard>
  )
}

export default AddTaskWizard