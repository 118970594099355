import css from './Modal.module.css'
import React from 'react'
import AccordianTask from '../../../../../Components/AccordianTask/AccordianTask';
import Button from '../../../../../Components/Button/Button';
const ClauseListModal = ({children}) => {
  const {clauseList, closeModal}=children;
  const onChange=(id, group, state)=>{
    clauseList.find(c=>c.id===id).selected=state
  }
  const addClauses=()=>{
    closeModal(
      clauseList
    )
  }
  return (
    <div className={css.Modal}>
      <div className={css.List}>
      {clauseList.map(item=>
          <AccordianTask key={item.id} selected={item.selected} onChange={onChange} id={item.id} title={item.name}>
          <p dangerouslySetInnerHTML={{__html:item.details}}></p>
        </AccordianTask>
      )}        
      </div>
      <div className={css.FootBtn}>
        <Button onClick={addClauses} className="Btn-Mid Active">Add Clauses</Button>
      </div>
    </div>
  )
}

export default ClauseListModal
