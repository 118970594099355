import { useEffect, useState } from "react";
import * as echarts from 'echarts';
import Charts from "./Charts";


const Bar = ({children, chartId, op}) => {
    const xdata=children.map((v)=>v.name)

    const [myChart, setChart]=useState();
    useEffect(()=>{      
        if(!myChart){
            setChart(echarts.init(document.getElementById(chartId)));
        }
    },[]);

    useEffect(
        ()=>{      
            if(myChart){
                    const op=Charts.getBar([...children], [...xdata]);
                    myChart.setOption(op);                
            }
        },[myChart, children])

    return (
    <div id={chartId}  style={{width:"100%", height:"500px"}}>
        Bar
    </div>
  )
}

export default Bar