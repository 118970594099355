import FormType from "../Form/FormType";
const SalesStarlinkEquipmentOptions = {
	Fields: [
		{
			element: FormType.Input,
			required: true,
			type: "text",
			name: "equipment",
			label: "Equipment",
			placeholder: "Enter Equipment Name",
			value: "Starlink",
		},
		{
			element: FormType.Select,
			required: true,
			name: "service",
			label: "Task Type",
			placeholder: "Select Type",
			value: null,
			options: [{ id: "2", name: "Installation" }],
		},
		{
			element: FormType.Select,
			required: true,
			name: "template",
			label: "Provision Template",
			placeholder: "Select Type",
			value: null,
			options: {},
		},
	],

	Buttons: [
		{ type: "button", text: "Close", className: "Btn-Mid", onClick: null },
		{ type: "submit", text: "Add", className: "Btn-Mid Active" },
	],
};
export default SalesStarlinkEquipmentOptions;
