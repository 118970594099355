import React from 'react'
import css from "./style.module.css"
const MilestoneG = ({children}) => {
    const {name, group}=children
    //console.log(children)
    const gStyle=()=>{
        const len=group.length;
        const done=group.filter(g=>g.status.id===3).length;
        if(done===len)return css.Approved;
        else if(done>0) return css.PartApproved;
        else return css.NotApproved;
    }

  return (
    <div>
        <div className={css.Milestone}>
            <div className={css.MSRow}>
                <div className={css.MSRow}>
                    <div className={gStyle()}></div>
                    <div>{name}</div>  
                </div>
                <div className={css.MSRowG}>
                    {group.map((g,i)=><div className={g.status.id===3?css.Completed:css.Waiting} key={i}>{g.equipment}</div>)}
                </div>
            </div>
            <div className={css.MSRow}>
                <div className={css.VerticalSpace}></div>
            </div>
        </div>
    </div>
  )
}

export default MilestoneG
