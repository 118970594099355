import { type } from "@testing-library/user-event/dist/type";
import FormType from "../../Components/Form/FormType";

const LocationFormOptions = (init) => {
	return {
		Fields: [
			{
				element: FormType.Input,
				required: true,
				name: "port",
				label: "Installation Port",
				value: init.portSchedule&&init.portSchedule.port,
			},
			[
				{
					element: FormType.Input,
					type: "date",
					required: true,
					label: "Vessel ETA",
					name: "eta",
					placeholder: "Select Vessel ETA",
					value: init.portSchedule&&init.portSchedule.eta,
				},
				{
					element: FormType.Input,
					type: "date",
					name: "etd",
					required: true,
					label: "Vessel ETD",
					placeholder: "Select Vessel ETD",
					value: init.portSchedule&&init.portSchedule.etd,
				},
			],
		],
	};
};

export default LocationFormOptions;
