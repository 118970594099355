import { BrowserRouter } from 'react-router-dom';
import './App.css';
import RouteView from './Routes/RouteView';
import NavOptions from './Routes/NavOptions';
function App() {
  return (
    <BrowserRouter>
        <RouteView>{NavOptions}</RouteView>
    </BrowserRouter>
  );
}
export default App;
