import React from 'react'

const ExpensesView = () => {
  return (
    <div>
      Expenses View
    </div>
  )
}

export default ExpensesView
