import Button from "../Button/Button";
import css from "./Form.module.css";
import FormRow from "./FormRow";

const Form = ({ children, onSubmit, height, onChange }) => {
	const { Fields, Buttons } = children;
	const handleSubmit = (e) => {
		onSubmit(children);
		e.preventDefault();
	};

	return (
		<div className={css.FormCont}>
			<form onSubmit={handleSubmit}>
				{Fields?.map((rec, index) => (
					<FormRow key={index} onChange={onChange}>
						{rec}
					</FormRow>
				))}
				<div className={css.Controls}>
					{Buttons &&
						Buttons.map((btn, index) => (
							<div key={index}>
								<Button
									type={btn.type}
									onClick={btn.onClick}
									className={btn.className}
								>
									{btn.text}
								</Button>
							</div>
						))}
				</div>
			</form>
		</div>
	);
};

Form.defaultProps = {
	height: 0,
};

export default Form;
