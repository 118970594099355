import FormType from "../Form/FormType"
import Url from "../../Services/Url"
import Role from "../../Data/Role";
const AddOperationOptions={
    Fields:[
        {element:FormType.Select,required:true, type:"text",name:"operation", label:"Operation", placeholder:"Select Operation", value:null,options:{url:Url.selectStaffByRole(Role.operation)}},
    ],
    
    Buttons:[
        {type:"button", text:"Close", className:"Btn-Mid", onClick:null},
        {type:"submit", text:"Add", className:"Btn-Mid Active"},
    ]
}
export default AddOperationOptions;