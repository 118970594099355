import FormType from "../../../../Components/Form/FormType";
import Url from "../../../../Services/Url";

const AddJobFormOptions = {
	Fields: [
		{
			element: FormType.Select,
			required: true,
			name: "company",
			label: "Company",
			value: null,
			placeholder: "Select Company",
			options: {
				url: Url.enterpriseCompany.allCompany,
				id: "id",
				name: "name",
			},
		},

		{
			element: FormType.Select,
			required: true,
			name: "contact",
			value: null,
			label: "Contact",
			placeholder: "Select Contact",
			options: null,
		},
		{
			element: FormType.Input,
			required: true,
			name: "email",
			label: "Email",
			placeholder: "Enter contact's email",
		},
	],
};

export default AddJobFormOptions;
