import FormType from "../../../../../../Components/Form/FormType";
import Url from "../../../../../../Services/Url";

const AddBillingFormOptions = (init) => {
  const contract=init.contract||{};
  const {billingInformation={}}=contract;
  return {
    Extra:{
      id:billingInformation.id||null
    },
    Fields: [
      [
        {
          element: FormType.Input,
          required: true,
          label: "Firstname",          
          placeholder: "Enter firstname",
          value:billingInformation.firstname,
          name: "firstname",
        },
        {
          element: FormType.Input,
          required: true,
          label: "Lastname",
          placeholder: "Enter lastname",
          value:billingInformation.lastname,
          name: "lastname",
        },
      ],
      [
        {
          element: FormType.Input,
          required: true,
          label: "Email",
          placeholder: "Enter email",
          value:billingInformation.email,
          name: "email",
        },
        {
          element: FormType.Input,
          required: true,
          label: "Phone",
          placeholder: "Enter phone",
          value:billingInformation.phone,
          name: "phone",
        },
      ],
      {
        element: FormType.Select,
        required: true,
        label: "Company",
        placeholder: "Select company",
        options: { url: Url.company.all, id: "id", name: "name" },
        value:billingInformation.company,
        name: "company",
      },
      [
        {
          element: FormType.Input,
          required: false,
          label: "State / Province / Territory",
          placeholder: "Enter state",
          value:billingInformation.state,
          name: "state",
        },
        {
          element: FormType.Input,
          required: false,
          label: "Country",
          placeholder: "Enetr country",
          value:billingInformation.country,
          name: "country",
        },
      ],
      [
        {
          element: FormType.Input,
          required: false,
          label: "City",
          placeholder: "Select city",
          value:billingInformation.city,
          name:"city"
        },
        {
          element: FormType.Input,
          required: false,
          label: "Postal / Zipcode",
          placeholder: "Enter postal code",
          value:billingInformation.postalCode,
          name:"postalCode"
        },
      ],
      {
        element: FormType.Input,
        required: true,
        label: "Address",
        placeholder: "Enter address",
        value:billingInformation.address,
        name:"address"
      },
    ],
  };
};

export default AddBillingFormOptions;
