import SvgOptions from "../../../Components/Svg/SvgOptions";
import CompanyList from "./CompanyList";
import Details from "./Details/Details";
import AddCompany from "./AddCompany/AddCompany";
const CompanyNavOption=[
    {
        element:Details, 
        path:"Details/*",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
    {
        element:Details, 
        path:":id/*",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },

    {
        element:AddCompany, 
        path:"Create",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    }
]
const defaultRoute={
    element:CompanyList, 
    path:"*",
    access:{user:true, admin:true, super:true}, 
    Protected:false,
    default:true,
    icon:SvgOptions.Dashboard
}
export {defaultRoute};
export default CompanyNavOption