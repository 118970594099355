const FormRow = ({ children, onChange }) => {
  if (Array.isArray(children)) {
    const cnt = children.length;
    const flex = cnt > 1 ? 100 / cnt - 5 : 100;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        {children.map((field, index) => (
          <div
            key={index}
            style={{ flexBasis: field.flex ? field.flex + "%" : flex + "%" }}
          >
            <field.element onChange={children.changeEvent && onChange}>
              {field}
            </field.element>
          </div>
        ))}
      </div>
    );
  }
  return (
    <div style={{ marginBottom: "15px" }}>
      <children.element onChange={children.changeEvent && onChange}>
        {children}
      </children.element>
    </div>
  );
};
export default FormRow;
