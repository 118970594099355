import Button from "../../Components/Button/Button";
import css from "./AddTaskModal.module.css";
import React from "react";
import SvgOptions from "../../Components/Svg/SvgOptions";
import Svg from "../../Components/Svg/Svg";
import Expandable from "../../Components/Expandable/Expandable";
import AddEquipment from "../../Components/AddEquipment/AddEquipment";
import { useState, useEffect } from "react";

import Utils from "../../Services/Utills";
import { toast } from "react-toastify";
const AddEquipmentModal = ({ onNext, onBack, children, onSet }) => {
	const [expand, setExpand] = useState(true);
	const [EditEquipment, setEditEquipment] = useState();

	const [equipmentList, setEquipment] = useState(children.taskEquipmentList);
	useEffect(() => {
		children.AddEquipmentModal = equipmentList;
		onSet(children);
	}, [equipmentList]);
	const onSubmit = (data) => {
		equipmentList.splice(data.index, 1, {
			index: data.index,
			...Utils.FormObject(data),
		});
		setEquipment([...equipmentList]);
		setExpand(false);
	};

	const onEdit = (item) => {
		setEditEquipment(
			<AddEquipment onSubmit={onSubmit} edit={true}>
				{item}
			</AddEquipment>
		);
		setExpand(true);
	};
	const handleNext = () => {
		let valid = true;
		equipmentList.forEach((element) => {
			if (!element.template) {
				toast("No template added for " + element.equipment);
				valid = false;
			}
		});
		if (valid) {
			onNext && onNext(equipmentList);
		}
	};
	const handleBack = () => {
		onBack && onBack();
	};
	return (
		<div className={css.Cont}>
			<div className={css.Head}>
				<h2>Set Milestone Template</h2>
			</div>
			<div className={css.Body}>
				{EditEquipment && (
					<div style={{ marginBottom: "10px" }}>
						<Expandable expand={expand} height={220}>
							{EditEquipment}
						</Expandable>
					</div>
				)}

				<div className={css.EquipmentList}>
					{equipmentList.map((equipment, index) => (
						<EquipmentView onEdit={onEdit} key={index}>
							{equipment}
						</EquipmentView>
					))}
				</div>
				<div className={css.Controls}>
					<div>
						<Button onClick={handleBack} className="Btn-Mid Inverse">
							Back
						</Button>
					</div>
					<div>
						<Button onClick={handleNext} className="Btn-Mid Active">
							Next
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

const EquipmentView = ({ children, onEdit }) => {
	const { equipment, template } = children;
	const handelEdit = () => {
		onEdit && onEdit(children);
	};
	return (
		<div className={css.EquipmentView}>
			<div>
				<div>{equipment}</div>
				<div onClick={handelEdit} className={css.DeleteIcon}>
					<Svg>{SvgOptions.Edit2}</Svg>
				</div>
			</div>
			<div>{template?.name}</div>
		</div>
	);
};

export default AddEquipmentModal;
