import SvgOptions from "../../../Components/Svg/SvgOptions";
const NotificationType={
    danger:{name:"danger", icon:SvgOptions.Danger, color:"var(--red)"},
    warning:{name:"warning", icon:SvgOptions.Warning, color:"var(--yellow)"},
    active:{name:"active",icon:null, color:"var(--electricblue)"},
    info:{name:"info",icon:null, color:"var(--electricblue)"}
}
export const getType=(index)=>{
    if(typeof(index) ==="object"){

    }else
    return NotificationType[Object.keys(NotificationType)[index-1]];
}
export default NotificationType;