import "./AddOpModel.css";
import FormOptions from "./FormOptions";
import Form from "../../Components/Form/Form";
import Utils from "../../Services/Utills";
import { Post } from "../../Services/Server";
import Url from "../../Services/Url";
import { toast } from "react-toastify";
const AddOpModel=({children})=>{
    const options =FormOptions
    console.log(children)
    const onSubmit=(e)=>{
        const dt=Utils.FormObject(e);
        dt.roleId=2;
        Post(Url.staffAddRole, dt).then(resp=>{
            toast(resp)
        }).catch(()=>{
            toast("Operation failed")
        }).finally(()=>{
            children.closeModal();
        })
    }

    return(
        <div className="AddEngineerModel">
            <Form onSubmit={onSubmit}>{options}</Form>
        </div>
    )
}
export default AddOpModel;