import css from "./MilestoneEdit.module.css"
import Svg from "../Svg/Svg"
import SvgOptions from "../Svg/SvgOptions"
import Button from "../Button/Button";
import { useState } from "react";
import { useEffect } from "react";
const MilestoneEdit=({children, handleAdd, select, Events})=>{
    const executorTypes=[
        {id: 1, name: 'Engineer'},
        {id: 2, name: 'Operation'}
    ]
    const onDelete=Events?Events.onDelete:()=>{};
    const onClick=Events?Events.onClick:()=>{};
    const onChange=Events?Events.onChange:()=>{};
    const {name, executor, count}=children;
    const exec=executor.id===1?{eng:"Active", ops:"Inverse"}:{eng:"Inverse", ops:"Active"}
    const [switchState, setState]=useState(exec)
    const [viewState, setView]=useState(children.clientView?1:0)
    const views=[SvgOptions.VisibleOff, SvgOptions.Visible]
    const handleView=()=>{
        const v=((viewState + 1) %2)
        setView(v);
        children.clientView=v?true:false;
        onChange&&onChange(children);
    }
    const handleSwitch=()=>{
        if(switchState.eng.localeCompare("Active")===0){
            children.executor=executorTypes[1];
           setState({ops:"Active", eng:"Inverse"}) 
        }else{
            children.executor=executorTypes[0];
            setState({eng:"Active", ops:"Inverse"})
        }
        onChange&&onChange(children);
    }
    const onSelect=(e)=>{
        console.log(e);
    }
    return(
        <div className={css.Cont} draggable>
            <div className={css.content}>
                <div>
                    <Svg>{SvgOptions.Moving}</Svg>
                </div>
                <div className={css.name} onClick={()=>onClick(children)}>                    
                    {name}
                    {count&&
                    <span className={css.count}> {count}</span>                    
                    }
                </div>
            </div>
            <div className={css.controls}>
                <div className={css.buttons}>
                    <div><Button onClick={()=>handleSwitch(1)} className={"Btn-Tny "+switchState.eng} icon={SvgOptions.Engineer}>Eng</Button></div>
                    <div><Button onClick={()=>handleSwitch(2)} className={"Btn-Tny "+switchState.ops} icon={SvgOptions.Ops}>Ops</Button></div>
                </div>
                <div className={css.Visible}>
                    <Svg className={css.svg} onClick={handleView}>{views[viewState]}</Svg>
                </div>
                <div className={css.Close} onClick={select?()=>{handleAdd(children)}:()=>onDelete(children)}>
                    <Svg className={css.svgclose} >{select?SvgOptions.Add:SvgOptions.Close}</Svg>
                </div>
            </div>
        </div>
    )
}
export default MilestoneEdit