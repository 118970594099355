import { type } from "@testing-library/user-event/dist/type";

const logTemplate={
    start:{label:"Starting Time", name:"start", type:"datetime", value:new Date()}, 
    finish:{label:"Finish Time", name:"finish", type:"date", value:new Date()}, 
    jobDoneList:[{name:"details", label:"Task Done", id:null}], 
    observation:{name:"Observations",label:"Observations", value:""}, 
    images:[],id:null}

const debriefTemplate={
    id:null,
    result:{name:"result",label:"Result", value:""},     
    hoursSpent:{name:"hoursSpent",label:"Hours Spent", value:""}, 
    parts:[{name:"name", label:"Part Replaced", id:null}], 
    notes:{name:"notes",label:"Notes", value:""}, 
}
export {debriefTemplate}
export default logTemplate;