import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Form from "../../../../../Components/Form/Form";
import Svg from "../../../../../Components/Svg/Svg";
import SvgOptions from "../../../../../Components/Svg/SvgOptions";
import { Post } from "../../../../../Services/Server";
import Url from "../../../../../Services/Url";
import Utils from "../../../../../Services/Utills";
import css from "../FormsContainer.module.css";
import AddRiskAssessmentFormOptions from "./AddRiskAssessmentFormOptions";

const AddRiskAssessment = ({ init, onSaved }) => {
	const formOptions = AddRiskAssessmentFormOptions(init);
	const [showForm, setShowForm] = useState(false);
	const [score, setScore] = useState({ total: 0, avg: 0 });
	const getTotal = (riskScore) => {
		setScore(
			riskScore.reduce(
				(a, v) => {
					return { total: a.total + 10, avg: a.avg + parseInt(v.score) };
				},
				{ total: 0, avg: 0 }
			)
		);
	};
	useEffect(() => {
		getTotal(init.risk.riskScore);
	}, []);

	const hanldeSubmit = (e) => {
		const dt = Utils.FormObjectParse(formOptions);
		dt.id = init.id;
		init.risk = dt;
		Post(Url.sales.saveEnterpriseRisk, dt)
			.then((resp) => {
				init.status = resp;
				onSaved && onSaved(init);
				setShowForm(false);
				toast("Risk Assessment Saved");
			})
			.catch((err) => {
				if (err.response) {
					const error = err.response.data;
					Object.keys(error).map((key) => {
						toast.error(error[key], { theme: "colored" });
					});
				}
			});
	};
	const onChange = () => {
		const dt = Utils.FormObjectParse(formOptions);
		init.risk = dt;
		getTotal(dt.riskScore);
	};
	return (
		<div className={css.FormsContainer}>
			<div className={css.BoxTitle} onClick={() => setShowForm(!showForm)}>
				<Svg className={css.svg}>
					{!showForm ? SvgOptions.ArrowSideFill : SvgOptions.ArrowDownFill}
				</Svg>
				Risk Assessment
			</div>
			{showForm && (
				<div className={css.InputsContainer}>
					<Form onChange={onChange} onSubmit={hanldeSubmit}>
						{formOptions}
					</Form>
					{score && (
						<div className={css.RiskScore}>
							Risk Score {score.avg}/{score.total}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default AddRiskAssessment;
