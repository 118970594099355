import Button from "../../Components/Button/Button"
import Input from "../../Components/Form/Input/Input"
import css from "./style.module.css"
import { Post } from "../../Services/Server"
import Url from "../../Services/Url"
import { toast } from "react-toastify"
const CloseTaskModal = ({children}) => {
    const {task}=children;
    const closedTask={label:""}
    const exit=()=>{
        children.closeModal();
    }
    const save=()=>{
      if(!closedTask.value){
        toast("Please give a reason for closing this task");
        return ;
      }
      task.closedTask={details: closedTask.value}
      Post(Url.saveTask, task).then(()=>{
        toast("Task closed");
        children.onClosed();
      }).catch(err=>{
        console.log(err)
      })
    }
  return (
    <div className={css.Modal}>
      <div><h4>You are about to close {children.task.title}</h4></div>
      <div>What is your reason?</div>
      <Input>{closedTask}</Input>
      <div className={css.Controls}>
        <div><Button onClick={save} className="Btn-Mid Active">Close Task</Button></div>
        <div><Button onClick={exit} className="Btn-Mid Inverse">Exit Operation</Button></div>
      </div>
    </div>
  )
}

export default CloseTaskModal
