import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState,useEffect } from "react";

const DragDrop=({children, onOrderChanged, Element, Events})=>{
  const setId=()=>{
    return children.reduce((acc, val,index)=>{
      acc.push({element:val, id:index+""})
      return acc
    },[])
  }
  const [items, setItems]=useState([]);

  useEffect(()=>{
    //console.log(children)
    //console.log(setId())
    setItems(children);
    //console.log(children)
  },[children])
  //console.log(setId())

  
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  
  const grid = 0;
  
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: 0,
    margin: `0 0 16px 0`,  
    background: isDragging ? "transparent" : "transparent",  
    ...draggableStyle
  });
  
  const getListStyle = isDraggingOver => ({
    padding: grid,
  });
  
    const onDragEnd=(result)=> {
        if (!result.destination) {
          return;
        }    
        const itemx = reorder(
          items,
          result.source.index,
          result.destination.index
        );
        setItems(itemx)
        onOrderChanged&&onOrderChanged(itemx)
      }    

      return(
            <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="1">
                {
                    (provided, snapshot)=>(
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
{items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id+""} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Element Events={Events}>{item}</Element>
                      {provided.placeholder}
                      </div>
                  )}
                </Draggable>
              ))}

                      </div>    
                    )
      
                }
            </Droppable>
            </DragDropContext>
    )
}
export default DragDrop