import AccordianTask from "../../../../../../Components/AccordianTask/AccordianTask";
import Form from "../../../../../../Components/Form/Form";
import TermDefinitionFormOptions from "./TermDefinitionFormOptions";
import css from "./Terms.module.css";
import { forwardRef, useImperativeHandle } from "react";
import Utils from "../../../../../../Services/Utills";

const Terms = forwardRef(({ init }, ref) => {
	const termDefinitionFormOptions = TermDefinitionFormOptions(init);
	const { equipment } = init;
	equipment.map((e) => {
		e.selectedProvisions = e.template.clauseList.reduce(
			(a, v) => [...a, v.id],
			[]
		);
	});
	const handleTermDefinitionForm = () => {};
	useImperativeHandle(ref, () => ({
		data() {
			return {
				...Utils.FormObject(termDefinitionFormOptions),
				equipment: equipment.reduce(
					(a, v) => [
						...a,
						{ id: v.id, selectedProvisions: v.selectedProvisions },
					],
					[]
				),
				id: init.id,
			};
		},
	}));

	const onAccordianChanged = (id, equipment_id, status) => {
		const eq = new Set(
			equipment.find((e) => (e.id = equipment_id)).selectedProvisions
		);
		if (status) {
			eq.add(id);
		} else {
			eq.delete(id);
		}
	};

	return (
		<div className={css.Terms}>
			<p className={css.Title}>Terms &amp; Condition</p>
			<ol>
				<li className={css.ListTitles}>Definitions:</li>
				<Form onSubmit={handleTermDefinitionForm}>
					{termDefinitionFormOptions}
				</Form>
				{equipment.map((eq, index) => (
					<div key={index}>
						<li className={css.ListTitles}>
							Provision Of {eq.equipment} {eq.service.name}:
						</li>
						<div className={css.AccordianLists}>
							{eq.template.clauseList.map((pr, prIndex) => (
								<AccordianTask
									onChange={onAccordianChanged}
									equipment_id={eq.id}
									id={pr.id}
									selected={eq.selectedProvisions.indexOf(pr.id) !== -1}
									key={prIndex}
									title={pr.name}
								>
									<p dangerouslySetInnerHTML={{ __html: pr.details }}></p>
								</AccordianTask>
							))}
						</div>
					</div>
				))}
			</ol>
		</div>
	);
});

export default Terms;
