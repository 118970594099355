import React from "react";
import SalesMilestone from "./SalesMilestone";

const SalesMilestones = ({ children }) => {
  return (
    <div>
      <SalesMilestone name="Initial Setup">{children.initial}</SalesMilestone>
      <SalesMilestone name="Requirements">
        {children.requirements}
      </SalesMilestone>
      <SalesMilestone name="Risk">{children.risk}</SalesMilestone>
      <SalesMilestone name="Contract">{children.contract}</SalesMilestone>
    </div>
  );
};

export default SalesMilestones;
