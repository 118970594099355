import SvgOptions from "../../../Components/Svg/SvgOptions";
import Details from "./Details/Details";
import OpsList from "./OpsList";
const OpsNavOptions=[
    {
        element:Details, 
        path:"Details/*",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
]
const defaultRoute={
    element:OpsList, 
    path:"*",
    access:{user:true, admin:true, super:true}, 
    Protected:false,
    default:true,
    icon:SvgOptions.Dashboard
}
export {defaultRoute};
export default OpsNavOptions;