import Input from "../../Components/Form/Input/Input";
import Svg from "../../Components/Svg/Svg";
import SvgOptions from "../../Components/Svg/SvgOptions";
import css from "./ReportModal.module.css"
import { useState, useEffect } from "react";
import AddButton from "../../Components/Form/AddButton/AddButton";
import TextArea from "../../Components/Form/TextArea/TextArea";
import Pictures from "../../Components/Form/Pictures/Pictures";
import DateTime from "../../Components/Form/DateTime/DateTime";
import { Delete } from "../../Services/Server";
import Url from "../../Services/Url";
const ReportLog=({children,index, onDelete, onChange})=>{
    const [log, setLog]=useState(children)
    useEffect(()=>{
        setLog(children)
    },[children])
    const [headIconDeg, setHIconDeg]=useState(0);
    const [height, setHeight]=useState("0px")
    const [vissible, setVissible]=useState(true);
    const toggle=()=>{
        const deg=(headIconDeg +180)%360;
        setHIconDeg(deg)        
        setHeight(deg?"300px":"0px")
    }
    const addTaskDone=()=>{
        children.jobDoneList.push({name:"details", label:""})
        setLog({...children})
    }
    const pictureAdded=(e)=>{
        children.images=e;
        onChange();
    }
    const handleDelete=(e)=>{
        if(e.id)Delete(Url.deleteLogImage(e.id)).then(resp=>{
            console.log(resp)
        })
    }
    return(
        <div className={css.Log}>
            <div className={css.LogHeadCont}>
                <div onClick={toggle} className={css.LogHead}>
                    <div style={{transform:`rotateX(${headIconDeg}deg)`,transition:"0.5s"}}>
                        <Svg className={css.LogHeadIcon}>{SvgOptions.ArrowUpFill}</Svg>
                    </div>
                    <div>
                        Log {index +1 }
                    </div>
                </div>
                <div className={css.LogHeadIcons}>
                    <div><Svg onClick={()=>setVissible(!vissible)} className={css.LogHeadIcon}>{vissible?SvgOptions.Visible:SvgOptions.VisibleOff}</Svg></div>
                    <div><Svg onClick={()=>onDelete(index)} className={css.LogHeadIcon}>{SvgOptions.Delete}</Svg></div>
                </div>
            </div>
            <div style={{height}} className={css.Wrapper}>
                <div className={css.LogCont}>
                    <div className={css.Form}>
                        <div className={css.FormRow}>
                            <DateTime onChange={onChange}>{log.start}</DateTime>
                            <DateTime onChange={onChange}>{log.finish}</DateTime>
                        </div>
                        <div>
                            {
                                log.jobDoneList.map((job, index)=>
                                    <Input key={index} onChange={onChange}>{job}</Input>
                                )
                            }
                        </div>
                        <div>
                            <AddButton onClick={addTaskDone}>Add Task</AddButton>
                        </div>
                        <div>
                            <TextArea onChange={onChange}>{children.observation}</TextArea>
                        </div>
                        <div className={css.Pictures}>
                            <Pictures onDelete={handleDelete} load={children.images} onChange={pictureAdded}></Pictures>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ReportLog;