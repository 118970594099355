import Button from "../../Components/Button/Button";
import css from "./ReportModal.module.css";
import Pictures from "../../Components/Form/Pictures/Pictures";
import { useRef, useState, useEffect } from "react";
import { Post, Delete } from "../../Services/Server";
import Url from "../../Services/Url";
import User from "../../Services/User";
const Report1 = ({ onNext, children, onBack }) => {
	const [btn, setBtn] = useState({ text: "Next", onClick: onNext });

	if (!children.initial) children.initial = {};
	children.createdBy = { id: User.getBiodata().id };
	children.initial.createdBy = children.createdBy;
	const handleNext = () => {
		if (btn.text === "Next") onNext();
		else save();
	};

	useEffect(() => {
		setBtn({ text: "Next", onClick: onNext });
	}, []);
	const txtRef = useRef();
	const handlePictureDelete = (e) => {
		if (e.id)
			Delete(Url.deleteInitialImage(e.id)).then((resp) => {
				console.log(resp);
			});
	};
	const pictureAdded = (e) => {
		if (children.initial.images) {
			if (children.initial.images.length !== e.length) {
				setBtn({ text: "Save", onClick: save });
			}
		}
		children.initial.images = e;
	};
	const save = () => {
		setBtn({ text: "Wait...", onClick: null });
		children.initial.details = txtRef.current.value;
		children.initial.createdAt = new Date().toISOString();
		Post(
			Url.saveInitialImages,
			children.initial.images.filter((img) => !img.id)
		)
			.then((resp) => {
				children.initial.images = children.initial.images
					.filter((img) => img.id)
					.concat(resp);
			})
			.finally(() => {
				Post(Url.saveReport, children)
					.then((resp) => {
						children.initial = resp.initial;
						children.initial.createdBy = children.createdBy;
						children.id = resp.id;
					})
					.finally(() => {
						setBtn({ text: "Next", onClick: onNext });
					});
			});
		/*
		 */
	};

	return (
		<div className={css.Report}>
			<div className={css.Head}>
				<h2>
					Initial State of{" "}
					{children.taskEquipment &&
						children.taskEquipment
							.reduce((a, v) => [...a, v.name], [])
							.join(" and ")}
				</h2>
			</div>
			<div className={css.ReportBody}>
				<div className={css.FormControl}>
					<textarea
						defaultValue={children.initial.details}
						onChange={() => setBtn({ text: "Save", onClick: save })}
						ref={txtRef}
					></textarea>
				</div>
				<div className={css.Pictures}>
					<Pictures
						onDelete={handlePictureDelete}
						load={children.initial.images}
						onChange={pictureAdded}
					></Pictures>
				</div>
			</div>
			<div className={css.Foot}>
				<div className={css.Buttons}>
					<Button className="Btn-Mid" onClick={onBack}>
						Back
					</Button>
					<Button className="Btn-Mid Active" onClick={handleNext}>
						{btn.text}
					</Button>
				</div>
			</div>
		</div>
	);
};
export default Report1;
