import Button from "../../Components/Button/Button"
import AddButton from "../../Components/Form/AddButton/AddButton";
import Input from "../../Components/Form/Input/Input";
import TextArea from "../../Components/Form/TextArea/TextArea";
import css from "./ReportModal.module.css"
import { debriefTemplate } from "./logTemplate";
import { useState, useEffect } from "react";
import { Post } from "../../Services/Server";
import Url from "../../Services/Url";
const Report3=({onClose, onBack, children})=>{
    const {debrief}=children;
    const clone=()=>{
        return JSON.parse(JSON.stringify(debriefTemplate));
     }
    
    const initDebrief=()=>{
        if(!children.debrief)return clone();
        if(!children.debrief.id)return clone();
            const cl= clone();
            cl.id=debrief.id;
            cl.result.value=debrief.result;
            cl.hoursSpent.value=debrief.hoursSpent;
            cl.notes.value=debrief.notes;
            cl.parts=debrief.parts.reduce((a,v)=>[...a,{label:"Part Replaced",name:"name",id:v.id, value:v.name}],[])
        return cl;
    }
    const [options, setOptions]=useState({...initDebrief()});
    useEffect(()=>{
        setOptions({...initDebrief()})
    },[debrief])
    const [btn, setBtn]=useState("Finish")
    const onChange=()=>{
        setBtn("Save");
    }
    const handleBtn=()=>{
        if(btn==="Save")save();
        else onClose&&onClose(children);
    }
    const save=()=>{
        const data={
            id:options.id,
            result:options.result.value,
            hoursSpent:options.hoursSpent.value,
            notes:options.notes.value,
            parts:options.parts.map(part=>{return{name:part.value, id:part.id}}),
        }
        children.debrief=data;
        Post(Url.saveReport, children).then(resp=>{
            debrief.id=resp.debrief.id;
            debrief.parts.forEach((part, index) => {
                part.id=resp.debrief.parts[index].id;
            })
            children.debrief=debrief;
            console.log(resp)
        }).finally(()=>{
            setBtn("Finish")
        })
    }

    const addPart=()=>{
        debriefTemplate.parts.push({name:"name", label:"Part Replaced", id:null})
        setOptions({...debriefTemplate})
    }
    return(
        <div className={css.Report}>
            <div className={css.Head}><h2>Debrief</h2></div>
            <div className={css.ReportBody}>
                <div className={css.Scrollable}>
                    <div>
                        <TextArea onChange={onChange}>{options.result}</TextArea>
                    </div>
                    <div>
                        <Input onChange={onChange}>{options.hoursSpent}</Input>
                    </div>
                    <div>
                        {options.parts.map((part, index)=>
                            <Input onChange={onChange} key={index}>{part}</Input>
                            )
                        }
                    <AddButton onChange={onChange} onClick={addPart}>Add Part</AddButton>
                    </div>
                    <div>
                        <TextArea onChange={onChange}>{options.notes}</TextArea>
                    </div>
                </div>
            </div>
            <div className={css.Foot}>
            <div className={css.Buttons}>
            <Button className="Btn-Mid" onClick={onBack}>Back</Button>
            <Button className="Btn-Mid Active" onClick={handleBtn}>{btn}</Button>                
            </div>
            </div>
        </div>
    )
}
export default Report3;