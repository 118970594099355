import FormType from "../../../../Components/Form/FormType";
import Url from "../../../../Services/Url";
const AccId={
    Fields:[
        [
            {element:FormType.Input, value:"", required:true, label:"Staff Number", name:"staffNumber", placeholder:"Staff Number"},
            {element:FormType.Select, required:true, name:"country", label:"Company", value:null, placeholder:"Select Countries", options:{url:Url.countries, id:"id",name:"name"}},
        ],   

    ]
}
const AccOptions={
    Fields:[
        [
            {element:FormType.Input, value:"", required:true, label:"First Name", name:"firstname", placeholder:"Enter firstname"},
            {element:FormType.Input, value:"", required:true, label:"Last Name", name:"lastname", placeholder:"Enter lastname"},
        ],   
        [
            {element:FormType.Input, value:"", required:true, label:"Phone Number", name:"phone", placeholder:"Enter Phone number"},
            {element:FormType.Input, value:"", required:true, label:"Email", name:"email", placeholder:"Enter Email"},
        ],   
    ],
    Buttons:[
       // {text:"Save", className:"Btn-Mid Active"}  
    ]
}

const AccJob={
    Fields:[
        [
            {element:FormType.Input, value:"", required:true, label:"Staff Number", name:"staff_number", placeholder:"Department"},
            {element:FormType.Select, required:true, name:"country", label:"Company", value:null, placeholder:"Select Countries", options:{url:Url.countries, id:"id",name:"name"}},
        ],   

    ]
}


const SecureOptions={
    Fields:[
        [
            {element:FormType.Input, required:true, label:"Set Password", name:"password", placeholder:"Set Password"},
            {element:FormType.Input, required:true, label:"Confirm Password", name:"cpassword", placeholder:"Confirm Password"},
        ],   
    ],
    Buttons:[
        {text:"Save", className:"Btn-Mid Active"}  
    ]
}

export {SecureOptions, AccId}
export default AccOptions