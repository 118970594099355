import Page from "../../../../../Components/Page/Page"
import Url from "../../../../../Services/Url"
import { Get, Post, Delete } from "../../../../../Services/Server"
import { useState, useEffect } from "react"
import MilestonesDragable from "../../../../../Components/Milestones/MilestonesDragable"
import Form from "../../../../../Components/Form/Form"
import FormOptions from "./FormOptions"
import css from "./Style.module.css"
import Utils from "../../../../../Services/Utills"
import { toast } from "react-toastify"
import { ModalAction } from "../../../../../Routes/NavRouter"
import ConfirmOption from "../../../../../Services/ConfirmOption"
import SvgOptions from "../../../../../Components/Svg/SvgOptions"
const Left=({children, onSave})=>{
  const [fmoptions, setOptions]=useState(FormOptions)
  const save=(e)=>{    
    const dt=Utils.FormObject(e);
    dt.clientView=dt.clientView.value;
    dt.approvalRequired=dt.approvalRequired.value;
    dt.merge=dt.merge.value;
    dt.id=fmoptions.id;
    dt.reportRequired=false;
    onSave(dt)
  }
  return(
    <div className={css.Form}>
      <Form onSubmit={save}>{fmoptions}</Form>
    </div>
  )
}

const Right=({children,onChange, onClick})=>{
  const {confirm}=ModalAction;
  const onDelete=(e)=>{
    const options={...ConfirmOption()};
    options.text="You are about to delete "+e.name;
    options.title="Confirm Delete Milestone"
    options.onOk=()=>{
      Delete(Url.deleteMilestone(e.id)).then(()=>{
        toast("Milestone Deleted")
        setList(list.filter(i=>i.id!==e.id))
      })
      console.log(e)
    }
    confirm(options)
  }
  const [list, setList]=useState()
  useEffect(()=>{
    setList(children)
  },[children])
  const save=()=>{}
  return(  
    <>
      {list&&
        <MilestonesDragable onChange={onChange} onClick={onClick} onDelete={onDelete}>{list}</MilestonesDragable>
      }
    </>  
  )
}

const ManageMilestones = () => {
  const [list, setList]=useState([]);
  useEffect(()=>{
    Get(Url.milestones).then(resp=>{
      setList(resp)
    }).catch(err=>{
      console.log(err)
    })
  
  },[])
  const onSave=(e)=>{
    if(list.find(m=>m.name.toLowerCase()===e.name.toLowerCase())){
      toast("Milestone already exist")
      return;
    }
    Post(Url.saveMilestone, e).then(resp=>{
      setList([...list, resp]);
      toast("Milestone Saved")      
     // console.log(resp)
    }).catch(err=>{
      console.log(err)
    })
  }
  const onChange=(e)=>{
    console.log(e)
  }
  const onClick=(e)=>{
    console.log(e)
  }
  return (
      <Page LTitle={"Manage Milestone"} left={<Left onSave={onSave}></Left>}
      right={list.length?<Right onClick={onClick} onChange={onChange}>{list}</Right>:<div></div>}
      ></Page>
  )
}

export default ManageMilestones