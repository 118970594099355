import SvgOptions from "../../../../Components/Svg/SvgOptions";
import Left from "./Left";
import Edit from "./Edit/Edit";
import Delete from "./Delete/Delete";
const NavOptions = [
	{
		element: Edit,
		path: "Edit/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		icon: SvgOptions.Dashboard,
	},
	{
		element: Delete,
		path: "Delete/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		icon: SvgOptions.Dashboard,
	},
];
const defaultRoute = {
	element: Left,
	path: "*",
	access: { user: true, admin: true, super: true },
	Protected: false,
	default: true,
	icon: SvgOptions.Dashboard,
};
export { defaultRoute };
export default NavOptions;
