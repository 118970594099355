import imageCompression from "browser-image-compression";
const parse=(fld)=>{
    if(fld.parse){
    const {object, set}=fld.parse
    object[set]=fld.value;
    return object;
    }
    return fld.value
}                

const Utils={
    FormObject:(FormOptions)=>{
        const obj={};
        if(Array.isArray(FormOptions)){
            FormOptions.map(fld=>{
                return obj[fld.name]=fld.value
            })
        }else{
		FormOptions.Fields.map(fld=>
            {
                if(Array.isArray(fld)){
                    fld.map(fl=>obj[fl.name]=fl.value)
                }else
                return obj[fld.name]=fld.value
            }
		)
        }
        return obj;
    },
    FormObjectExtra:(FormOptions)=>{
        const obj=FormOptions.Extra||{};        
        if(Array.isArray(FormOptions)){
            FormOptions.map(fld=>{
                return obj[fld.name]=fld.value
            })
        }else{
		FormOptions.Fields.map(fld=>
            {
                if(Array.isArray(fld)){
                    fld.map(fl=>obj[fl.name]=fl.value)
                }else
                return obj[fld.name]=fld.value
            }
		)
        }
        return obj;
    },

    FormObjectParse:(FormOptions)=>{
        const obj={};
        if(Array.isArray(FormOptions)){
            FormOptions.map(fld=>{
                if(obj[fld.name]!=null){
                   return obj[fld.name]=[...obj[fld.name], fld.value]
                }else
                  return obj[fld.name]=fld.value
            })
        }else{
		FormOptions.Fields.map(fld=>
            {
                if(Array.isArray(fld)){
                    fld.map(fl=>obj[fl.name]=fl.value)
                }else{
                    if(obj[fld.name]!=null){
                        return obj[fld.name]=Array.isArray(obj[fld.name])?
                        [...obj[fld.name], parse(fld)]:[obj[fld.name], parse(fld)]
                     }else
                       return obj[fld.name]=parse(fld);     
                }
            }
		)
        }
        return obj;
    },
    getBase64File:(file, cb)=>{
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };

    },
    getBase64: (file, cb) =>{
        //console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
        const options = {
            maxSizeMB: 0.100,
            maxWidthOrHeight: 720,
            useWebWorker: true
          }
        imageCompression(file, options).then(cmp=>{
            //console.log(`originalFile size ${cmp.size / 1024 / 1024} MB`);
            let reader = new FileReader();
            reader.readAsDataURL(cmp);
            reader.onload = function () {
                cb(reader.result)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
    
        })
    },
}
export default Utils;