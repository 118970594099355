import PageHead from "../../../../Components/PageHead/PageHead";
import style from "./MilestoneTemplates.module.css"
import Template from "./Template";
import SvgOptions from "../../../../Components/Svg/SvgOptions";
import { useNavigate } from "react-router-dom";
import { Get } from "../../../../Services/Server";
import Url from "../../../../Services/Url";
import User from "../../../../Services/User";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
const MilestoneTemplates=({navoption})=>{
    const navigate=useNavigate();
    const btns=[
        {id:2, text:"Create Template", icon:SvgOptions.Add, onClick:()=>{navigate("Create",{state:null})}},
        {id:3, text:"Milestones", icon:SvgOptions.Repair, onClick:()=>{navigate("Manage",{state:null})}}
    ]
    const [templates, setTemplates]=useState([])
    useEffect(()=>{
        Get(Url.templateByuser(User.getBiodata().id))
        .then(resp=>{
            setTemplates(resp)
            //console.log(resp)
        })
        .catch(()=>{
            toast("Error fetching record")
        })    
    },[])
    return(
        <div className={style.MilestoneTemplates}>
            <PageHead buttons={btns} navoption={navoption} title="Task Templates" hideIcon={true} backControl={true}></PageHead>
            <div className={style.Templates}>
                {
                    templates.length?
                    templates.map((template, index)=>
                        <div key={index}><Template>{template}</Template></div>
                    ):<span className={style.NoTaskTemplate}>No task template</span>
                }
            </div>
        </div>
    )
}
export default MilestoneTemplates;