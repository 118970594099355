import { useNavigate, useLocation } from "react-router-dom";
import Accordian from "../../../../Components/Accordian/Accordian";
import Pills from "../../../../Components/Pills/Pills";
import Svg from "../../../../Components/Svg/Svg";
import SvgOptions from "../../../../Components/Svg/SvgOptions";
import css from "./KBSingleItemPage.module.css";
import Url from "../../../../Services/Url";

const lists = [{ id: 1, title: "Solutions 1" }];

const KBSingleItemPage = ({ navoption }) => {
  const location = useLocation();
  const { report } = location.state;
  console.log(report);
  let navigate = useNavigate();
  const models = ["GM800", "GM309", "GM700"];

  return (
    <div className={css.KBSingleItemPage}>
      <div style={{ width: "70%" }}>
        <div className={css.PageHead}>
          <div>
            <Svg onClick={() => navigate(-1)}>{SvgOptions.ArrowBack}</Svg>
            <p>Solutions Equipment Name</p>
          </div>
          <div className={css.MeatsballMenu}>
            <Svg>{SvgOptions.MeatballsMenu}</Svg>
          </div>
        </div>
        <div className={css.Equipment}>
          <p className={css.SectionTitles}>Equipment</p>
          <div>
            {/* <p>
              <strong> Model:</strong>
              {models.map((item) => (
                <Pills key={item.id} text={item} />
              ))}
            </p> */}
            <p>
              <strong> Name:</strong>
              {report.taskName}
            </p>
            {/* <p>
              <strong>Software:</strong>
              {["13.320", "13.400", "13.580"].map((item) => (
                <Pills key={item.id} text={item} />
              ))}
            </p> */}
          </div>
        </div>
        <div>
          <p className={css.SectionTitles}>Manifestation</p>
          <p className={css.ManifestationDescription}>
            {report.initialDetails}
          </p>
          <div className={css.ManifestationImage}>
            {report.initialImageIds
                    ?.filter((imgId) => imgId !== null)
                    .map((imgId) => (
                      <img
                        key={imgId}
                        className={css.img}
                        src={Url.img.get(imgId)}
                        alt={`Image ${imgId}`}
                      />
                    ))}
          </div>
        </div>
        <div>
          <p className={css.SectionTitles}>Solutions</p>
          <div className={css.AccordiansList}>
            {report.reportLogs.map((log, index) => (
              <Accordian key={index} title={`Solutions ${index + 1}`}>
                <div className={css.Spacee}>
                  <p className={css.Space}>
                    <strong>Tasks</strong>
                  </p>
                  <ul>
                    {log.jobDoneDetails.map((detail, idx) => (
                      <li key={idx}>{detail}</li>
                    ))}
                  </ul>
                </div>
                <div className={css.Spacee}>
                  <p className={css.Space}>
                    <strong>Observations</strong>
                  </p>
                  <p>{log.observation}</p>
                </div>
                <div className={css.ManifestationImage}>
                  {log.logImageIds
                    ?.filter((imgId) => imgId !== null)
                    .map((imgId) => (
                      <img
                        key={imgId}
                        className={css.img}
                        src={Url.img.get(imgId)}
                        alt={`Image ${imgId}`}
                      />
                    ))}
                </div>
              </Accordian>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KBSingleItemPage;
