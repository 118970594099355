import Form from "../Form/Form";
import css from "./AddEquipment.module.css";
import SalesStarlinkEquipmentOptions from "./SalesStarlinkEquipmentOptions";
import Url from "../../Services/Url";
import { Get } from "../../Services/Server";
import { useState } from "react";

const AddSalesEquipment = ({ onSubmit }) => {
	const [options, setOptions] = useState(SalesStarlinkEquipmentOptions);
	const init = () => {
		const op = { ...options };
		op.Fields[0].value = "";
		op.Fields[1].value = "";
		setOptions(op);
	};
	const taskChange = (e) => {
		if (typeof e === "object" && e != null) {
			Get(Url.contract.getSelectByTaskType(e.id))
				.then((resp) => {
					const op = { ...options };
					op.Fields[2].options = resp;
					setOptions(op);
				})
				.catch((err) => {});
		}
	};
	options.Fields[1].onSelected = taskChange;

	const handleSubmit = () => {
		onSubmit && onSubmit(options);
		init();
	};

	return (
		<div className={css.AddEquipment}>
			{options && <Form onSubmit={handleSubmit}>{options}</Form>}
		</div>
	);
};

export default AddSalesEquipment;
