import FormType from "../../../../../../Components/Form/FormType";
import Url from "../../../../../../Services/Url";

const AddContactFormOptions = (init, index = 0) => {
	console.log(init);
	const contract = init.contract || {};
	const { contactsInformationList = [{}] } = contract;
	const data = contactsInformationList[index] || {};
	return {
		Extra: {
			id: data.id || null,
		},
		Fields: [
			{
				element: FormType.Input,
				required: true,
				label: "Authorized as",
				placeholder: "Authorized",
				value: data.authorizedAs,
				name: "authorizedAs",
			},
			[
				{
					element: FormType.Input,
					required: true,
					label: "Firstname",
					placeholder: "Enter firstname",
					value: data.firstname,
					name: "firstname",
				},
				{
					element: FormType.Input,
					required: true,
					label: "Lastname",
					placeholder: "Enter lastname",
					value: data.lastname,
					name: "lastname",
				},
			],
			[
				{
					element: FormType.Select,
					required: true,
					label: "Role",
					placeholder: "Select role",
					options: { url: Url.company.clientRoles, id: "id", name: "name" },
					value: data.role,
					name: "role",
				},
				{
					element: FormType.Input,
					required: true,
					label: "Job Title",
					placeholder: "Enter lastname",
					value: data.jobTitle,
					name: "jobTitle",
				},
			],
			{
				element: FormType.Select,
				required: true,
				label: "Company",
				placeholder: "Select company",
				options: {
					url: Url.enterpriseCompany.allCompany,
					id: "id",
					name: "name",
				},
				value: data.company,
				name: "company",
			},
			[
				{
					element: FormType.Input,
					required: true,
					label: "Email",
					placeholder: "Enter email",
					value: data.email,
					name: "email",
				},
				{
					element: FormType.Input,
					required: true,
					label: "Phone",
					placeholder: "Enter phone",
					value: data.phone,
					name: "phone",
				},
			],
			[
				{
					element: FormType.Input,
					required: false,
					label: "State / Province / Territory",
					placeholder: "select state",
					value: data.state,
					name: "state",
				},
				{
					element: FormType.Input,
					required: false,
					label: "Country",
					placeholder: "Select country",
					value: data.country,
					name: "country",
				},
			],
		],
	};
};

export default AddContactFormOptions;
