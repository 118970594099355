import css from "./Details.module.css";
import TaskItem from "../../../../Components/TaskItem/TaskItem";
const Right = ({ state }) => {
	const { currentTasks } = state;
	return (
		<div className={css.Right}>
			<h3>{currentTasks.totalElements.length} Ongoing Tasks</h3>
			{currentTasks &&
				currentTasks.content.map((ct, index) => (
					<TaskItem key={index}>{ct}</TaskItem>
				))}
		</div>
	);
};

export default Right;
