import back from "../../Assets/Images/Back.png"
import "./ClientMilestones.css"
import Button from "../../Components/Button/Button"
import MilestonesG from "../../Components/Milestones/MilestonesG"
import { PublicGet } from "../../Services/Server"
import Url from "../../Services/Url"
import FeedbackModal from "../../Modals/FeedbackModal/FeedbackModal"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import TaskService from '../../Services/TaskService';
const CMRight=({ModalAction, children})=>{
    const {taskEquipmentList, title, tracking, id, jobNumber}=children;
    const [taskMilestones, setMilestones]=useState([]);    
    const del=children.deliveryEstimate?children.deliveryEstimate:children.createdAt;
    const delivery=new Date(del);
    const navigate=useNavigate();
    const ts=new TaskService(children);
    const [msGroup, setGroup]=useState([])
    //const msGroup=ts.msGroup();
    useEffect(()=>{
        ts.setRank().then(()=>{
            PublicGet(Url.taskByTracking(tracking)).then(resp=>{
                const ms=resp.taskEquipmentList.reduce((a,v)=>{
                    v.taskMilestones.map(x=>x.equipment=v.name)
                    return[...a,...v.taskMilestones]},[])
                setMilestones(ms);
            })    
            console.log(ts.msGroup())
            setGroup(ts.msGroup())
    

        })

    },[])    
    const handleFeedback=()=>{
        const Options={
            tracking,
            closeModal:ModalAction.closeModal,
            element:FeedbackModal,
            title:"Feedback"
        }
        ModalAction.openModal(Options);
    }
    return (
        <div className="CMRight">
                <a href="../" className="CM-Back-Arrow">
                    <img src={back} alt="Back arrow"></img>
                    <span>Go Back</span>
                </a>
                <div className="Cont-Space">
                    <div><h5>Service:</h5><div>{title} </div></div>
                    <div><h5>Your order number:</h5><div>{jobNumber}</div></div>
                </div>
                <h6>Estimated delivery:</h6>
                <h3 className="electricblue">{delivery.toDateString()}</h3>
                <div className="CM-Buttons">
                    <div>
                        <Button className="Btn-Small Active">See the report</Button>
                    </div>
                    <div>
                        <Button onClick={handleFeedback} className="Btn-Small Inverse">Share feedback</Button>
                    </div>
                    <div>
                        <Button onClick={()=>navigate("notification", {state:children})} className="Btn-Small Inverse">Manage Notifications</Button>
                    </div>
                </div>
                <div className="CM-Milestones">
                    {msGroup.length&&
                        <MilestonesG>{msGroup}</MilestonesG>
                    }
                </div>
        </div>
    )
}
export default CMRight;