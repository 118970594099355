import RouteView from "../../../Routes/RouteView";
import TemplatesNavOptions, { defaultRoute } from "./TemplatesNavOptions";

const ContractTemplate = ({ navoption, ModalAction }) => {
  return (
    <RouteView
      defaultRoute={defaultRoute}
      navoption={navoption}
      ModalAction={ModalAction}
    >
      {TemplatesNavOptions}
    </RouteView>
  );
};

export default ContractTemplate;
