import SvgOptions from "../../../Components/Svg/SvgOptions";
import KBSingleItemPage from "./KBSingleItemPage/KBSingleItemPage";
import KnowledgeBase from "./KnowledgeBaseMain/KnowledgeBase";

const KnowledgeBaseNavOptions = [
  {
    element: KBSingleItemPage,
    path: ":id",
    access: { user: true, admin: true, super: true },
    Protected: false,
    default: true,
    icon: SvgOptions.Receipt,
  },
];

const defaultRoute = {
  element: KnowledgeBase,
  path: "*",
  access: { user: true, admin: true, super: true },
  Protected: false,
  default: true,
  icon: SvgOptions.Receipt,
};

export { defaultRoute };
export default KnowledgeBaseNavOptions;
