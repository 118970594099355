import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageHead from "../../../../Components/PageHead/PageHead";
import { Get, Post } from "../../../../Services/Server";
import Url from "../../../../Services/Url";
import AITroubleshooting from "./AITroubleshooting/AITroubleshooting";
import Filters from "./Filters/Filters";
import KBSingleList from "./KBSingleList/KBSingleList";
import css from "./KnowledgeBase.module.css";
import Pagination from "../../../../Components/Pagination/Pagination";

const KnowledgeBase = ({ navoption }) => {
	const fetchData = (page) => {
		const pagination = {
			taskName: "",
			page: page,
		};
		Post(Url.knowledgeBase, pagination).then((resp) => {
			console.log(resp);
			setAllEquipmentNames(resp.allTaskEquipmentNames.content);
			setList(resp.allTaskEquipmentNames.content);
			setFilterEquipmentNames(resp.distinctTaskEquipmentNames.content);
			setTotalPages(resp.allTaskEquipmentNames.totalPages);
			setPageable(resp.allTaskEquipmentNames.pageable);
		});
	};

	const handleFilterChange = (taskNames) => {
		const pagination = {
			taskNames: taskNames,
			page: 0,
		};
		Post(Url.knowledgeBase, pagination).then((resp) => {
			console.log("filter", resp);
			setFilteredEquipment(resp.tasksInitialDetails.content);
			setFilteredList(resp.tasksInitialDetails.content);
			setTotalPages(resp.tasksInitialDetails.totalPages);
			setPageable(resp.tasksInitialDetails.pageable);
			setCurrentPage(0);
		});
	};
	const handleEquipmentClick = (id) => {
		Get(`${Url.knowledgeBase}/${id}/report`).then((response) => {
			navigate(`${id}`, { state: { report: response } });
		});
	};
	const onSearch = (e) => {
		setAllEquipmentNames(
			list.filter((l) => l.name.toLowerCase().includes(e.toLowerCase()))
		);
		setFilteredEquipment(
			filteredlist.filter((l) => l.name.toLowerCase().includes(e.toLowerCase()))
		);
	};
	const handlePagination = (page) => {
		setCurrentPage(page);
	};
	let navigate = useNavigate();
	const [openAITroubleshooting, setOpenAITroubleshooting] = useState(false);
	const [openFilters, setOpenFilters] = useState(false);
	const [allEquipmentNames, setAllEquipmentNames] = useState([]);
	const [filterEquipmentNames, setFilterEquipmentNames] = useState([]);
	const [filteredEquipment, setFilteredEquipment] = useState([]);
	const [list, setList] = useState([]);
	const [filteredlist, setFilteredList] = useState([]);
	const [pageable, setPageable] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [totalPages, setTotalPages] = useState(1);

	useEffect(() => {
		fetchData(currentPage);
	}, [currentPage]);

	return (
		<div className={css.KnowledgeBase}>
			{openFilters && (
				<div className={css.FiltersSide}>
					<Filters
						filterEquipmentNames={filterEquipmentNames}
						onFilterChange={handleFilterChange}
					/>
				</div>
			)}
			<div className={css.KBMain}>
				<PageHead
					title="Knowledge Base"
					navoption={navoption}
					onSearch={onSearch}
					buttons={[
						{
							id: 1,
							text: "Open Filters",
							onClick: () => {
								setOpenFilters(!openFilters);
								openAITroubleshooting && setOpenAITroubleshooting(false);
							},
						},
						{
							id: 2,
							text: "AI",
							onClick: () => {
								setOpenAITroubleshooting(!openAITroubleshooting);
								openFilters && setOpenFilters(false);
							},
						},
					]}
				/>
				<div
					className={css.Lists}
					style={{
						gridTemplateColumns:
							openAITroubleshooting || openFilters
								? "1fr 1fr 1fr"
								: "1fr 1fr 1fr 1fr",
					}}
				>
					{(filteredEquipment.length > 0
						? filteredEquipment
						: allEquipmentNames
					).map((item) => (
						<KBSingleList
							key={item.id}
							KBList={item}
							onClick={() => handleEquipmentClick(item.id)}
						/>
					))}
				</div>
				{pageable && (
					<Pagination
						onClick={handlePagination}
						pageable={pageable}
						totalPages={totalPages}
					/>
				)}
			</div>

			{
				// AI Troubleshooting
				openAITroubleshooting && (
					<div className={css.AISide}>
						<AITroubleshooting />
					</div>
				)
			}
		</div>
	);
};

export default KnowledgeBase;
