import css from "./Style.module.css"
import Button from "../../../../Components/Button/Button"
import Select from "../../../../Components/Form/Select/Select"
import Input from "../../../../Components/Form/Input/Input"
import { Post } from "../../../../Services/Server"
import Url from "../../../../Services/Url"
import DataTable from "../../../../Components/DataTable/DataTable"
import { useState } from "react"
import Bar from "../../../../Components/Charts/Bar"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from "react-router-dom"
const TaskReader=(rec)=>{
    return [
        rec.vessel.name,rec.jobNumber,rec.vessel.company_name,rec.location,rec.createdAt]
}
const options={
    from:{label:"From", type:"date", value:new Date(), name:"from"},
    to:{label:"To", type:"date", value:new Date(), name:"to"}
}
const select={placeholder:"Filter by" ,options:[{name:"Current Jobs", value:false}, {name:"Completed Jobs", value:true}]}
const selectbill={placeholder:"Filter by" ,options:[{name:"Billable", value:false}, {name:"Non Billable", value:true}]}

const Report = () => {
    const [page, setPage]=useState(null);
    const [summary, setSummary]=useState(null);
    const navigate=useNavigate();

    const onTaskClick=(e)=>{
        navigate(`../${e.id}`);
    }

    const getData=(rec)=>{
        const dt=rec.reduce((a, v)=>{
            return [...a,
            {value:v.taskCount,
            name:v.staffBiodata.firstname,itemStyle:{color:"#14B8FF"}
            }]},[]) 
            return dt;
    }

    const save=(pageNumber)=>{
        const dt={            
            from:new Date(`${options.from.value}T00:00`),
            to: new Date(`${options.to.value}T23:59`),
            current:typeof select.value=="undefined"?null:select.value.value,
            chargeable:typeof selectbill.value=="undefined"?null:selectbill.value.value,
            pageNumber,
            pageSize:5
        }
        console.log(dt)
        Post(Url.taskRange,dt).then(resp=>{
            setPage(resp.taskPage)
            setSummary({
                engineer:getData(resp.engineerSummaryList),
                operation:getData(resp.operationSummaryList)
            })
        })
    }
    const handlePagination=(e)=>{
        save(e);
    }
    
    const flex=[20,20, 20,20,20];

  return (
    <div className={css.Report}>
        <div className={css.Head}>
            <h2>Report</h2>
        </div>
        <div className={css.TopSection}>
        <div className={css.Range}>            
            <div className={css.Control}>
                <div><Input>{options.from}</Input></div>
                <div><Input>{options.to}</Input></div>
            </div>
            <div className={css.Control}>
                <div>
                    <Select>{select}</Select>
                </div>
                <div>
                    <Select>{selectbill}</Select>
                </div>
            </div>            
            <div><Button onClick={()=>save(0)} className="Btn-Mid Inverse">Load</Button></div>
        </div>
        <div className={css.Summary}>
            <h3>Sumary</h3>
            <div>
                <h4>Total Number of Task: {page?page.totalElements:0}</h4>
                <h4>Task Records per Page: {page?page.size:0}</h4>
                <h4>Current Pages: {page?page.number+1:0}</h4>
                <h4>Number of Pages: {page?page.totalPages:0}</h4>
            </div>
        </div>
        </div>
        <div className={css.Analysis}>            
            <h3>Analysis</h3>
            <div>
                <Carousel>
                    <div>
                        {summary&&<Bar chartId="engBar">{summary.engineer}</Bar>}
                    </div>
                    <div>
                        {summary&&<Bar chartId="opBar">{summary.operation}</Bar>}
                    </div>
                </Carousel>
                <div>

                </div>
            </div>
        </div>
        <div >
            <div className="CurrentTasks">
                {page&&<DataTable onClick={onTaskClick} handlePagination={handlePagination} pageable={page.pageable} totalPages={page.totalPages} reader={TaskReader} flex={flex}>{{data:page.content}}</DataTable>}
            </div>
        </div>
    </div>
  )
}

export default Report