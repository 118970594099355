import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Logo from "../../Assets/svg/logo.svg";
import { Get } from "../../Services/Server";
import Url from "../../Services/Url";
import User from "../../Services/User";
import css from "./Contact.module.css";

const JobAuth = () => {
  const { token, accountStatus } = useParams();
  User.setToken(token);
  const nav = useNavigate();
  const Verify = (url, cb) => {
    Get(url)
      .then((resp) => {
        toast("Token verifed");
        User.setJobInit(resp);
        cb();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (accountStatus === "contract") {
      Verify(Url.client.verifyContract, () => {
        nav("../../contract");
      });
    } else {
      Verify(Url.client.verify, () => {
        const step = accountStatus === "new" ? 2 : 3;
        nav("../../contact", { state: { step } });
      });
    }
  });

  return (
    <div className={css.Contact}>
      <div className={css.ContactLeft}>
        <div>
          <img src={Logo} alt="Fieldbase Logo" />
        </div>
        <div className={css.ContactLeftContent}>
          <p className={css.title}>Contact Us</p>
          <p className={css.text}>
            If you have any concerns or issues with your order, please
            <br /> don't hesitate to get in touch with us.
          </p>
          <p className={css.text}>
            <span className={css.key}>Email:</span> support@fbase.co.uk
          </p>
          <p className={css.text}>
            <span className={css.key}>Phone:</span> +234 9030 001 104
          </p>
        </div>
      </div>
      <div className={css.CompletedRight}>
        <div className={css.VerifyingBox}>
          <p className={css.Title}>Verifying Link... </p>
        </div>
      </div>
    </div>
  );
};

export default JobAuth;
