import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Post } from "../../../../../Services/Server";
import Url from "../../../../../Services/Url";
import { toast } from "react-toastify";
const Delete = () => {
	const navigate = useNavigate();
	const state = useLocation().state;
	Post(Url.RemoveStaff, {
		email: state.staffBiodata.email,
		roleId: state.staffBiodata.appRoles[0]?.id,
	})
		.then((resp) => {
			toast(resp);
			navigate("../..");
		})
		.catch((err) => {
			console.log(err);
		});
	return (
		<div style={{ width: "100%" }}>
			<h2>Deleteing from role...</h2>
		</div>
	);
};

export default Delete;
