import { NavLink, useParams } from "react-router-dom";
import User from "../../Services/User";
import ImgThumb from "../ImgThumb/ImgThumb";
import Svg from "../Svg/Svg";

const NavItem = ({ children, base }) => {
	const { path, icon, navImg, title, subTitle, notify, confirm } = children;
	const params = useParams();
	const pathName = path.split("/")[0];
	const basePath = params["*"].split("/")[0];
	const NavClass = navImg ? "NavItem NavItemWithImg" : "NavItem";
	const pathNameDir = confirm ? null : pathName;
	const roles = User.getRoles().reduce((a, v) => [v.name, ...a], []);
	const con = children.access.filter((ac) => roles.includes(ac));
	if (con.length < 1) {
		return <></>;
	}

	const onClick = () => {
		if (!confirm) return null;
		const { ModalAction, option } = confirm;
		ModalAction.confirm(option);
	};
	return (
		<div className={NavClass}>
			<NavLink
				to={base ? base + pathNameDir : null}
				onClick={onClick}
				replace={true}
				className={
					basePath.localeCompare(pathName) === 0 ||
					(!basePath && !pathName.localeCompare("Dashboad"))
						? "active"
						: ""
				}
			>
				<div className="BackLine"></div>
				<div className="LinkCont">
					<div>
						{icon && (
							<div className="svg">
								<Svg>{icon}</Svg>
							</div>
						)}
						{navImg && (
							<ImgThumb className="img" size={48}>
								{navImg}
							</ImgThumb>
						)}
					</div>
					<div className="NavPath">
						<div className="title">{title ? title : pathName}</div>
						{subTitle && <div className="sub">{subTitle}</div>}
					</div>
					{notify && (
						<div className="NavNotice">
							<span>{notify}</span>
						</div>
					)}
				</div>
			</NavLink>
		</div>
	);
};
export default NavItem;
