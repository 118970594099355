import FormType from "../../../../../Components/Form/FormType";
import Url from "../../../../../Services/Url";

const AddTemplateDetailsFormOptions = (init) => {
  return {
    Fields: [
      {
        element: FormType.Input,
        required: true,
        label: "Template Name",
        placeholder: "Enter template name",
        name: "name",
        value:init.name
      },
      {
        element: FormType.Select,
        required: true,
        label: "Template Type",
        placeholder: "Select template type",
        options:{url:Url.taskType},
        name: "taskType",
        value:init.taskType
      },
      {
        element: FormType.TextArea,
        required: true,
        label: "Use Case",
        placeholder: "Enter use case",
        name: "useCase",
        value:init.useCase
      }
    ],
  };
};

export default AddTemplateDetailsFormOptions;
