import Button from "../../../../../Components/Button/Button";
import CheckBoxList from "../../../../../Components/Form/CheckBoxList/CheckBoxList";
import css from "./Filters.module.css";
import { useState } from "react";

const Filters = ({ filterEquipmentNames, onFilterChange }) => {
  const [activeFilters, setActiveFilters] = useState([]);

  const handleCheckboxChange = (name, isChecked) => {
    let updatedFilters;
    if (isChecked) {
      updatedFilters = [...activeFilters, name];
    } else {
      updatedFilters = activeFilters.filter((filter) => filter !== name);
    }
    setActiveFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  return (
    <div className={css.Filters}>
      <div className={css.FiltersContainer}>
        {/* <div className={css.FilterBoxes}>
          <p className={css.FilterTitle}>Category</p>
          <CheckBoxList
            checkboxes={[
              {
                name: "Satellite Commmunication Systems",
                label: "Satellite Commmunication Systems",
                isChecked: false,
              },
              {
                name: "GMDSS Systems",
                label: "GMDSS Systems",
                isChecked: false,
              },
              {
                name: "Navigation Systems",
                label: "Navigation Systems",
                isChecked: false,
              },
              {
                name: "Bridge Instruments",
                label: "Bridge Instruments",
                isChecked: false,
              },
              {
                name: "Power Systems",
                label: "Power Systems",
                isChecked: false,
              },
            ]}
            onCheckboxChange={handleCheckboxChange} // Pass the function
          />
        </div> */}
        <div className={css.FilterBoxes}>
          <p className={css.FilterTitle}>Equipment</p>
          <CheckBoxList
            checkboxes={filterEquipmentNames.map(item => ({
              name: item.name,
              label: item.name,
              isChecked: false,
            }))}
            onCheckboxChange={handleCheckboxChange} // Pass the function
          />
        </div>
        {/* <div className={css.FilterBoxes}>
          <p className={css.FilterTitle}>Model</p>
          <CheckBoxList
            checkboxes={[
              {
                name: "GM800",
                label: "GM800",
                isChecked: false,
              },
              {
                name: "Gm780",
                label: "Gm780",
                isChecked: false,
              },
              {
                name: "GM700",
                label: "GM700",
                isChecked: false,
              },
            ]}
            onCheckboxChange={handleCheckboxChange} // Pass the function
          />
        </div>
        <div className={css.FilterBoxes}>
          <p className={css.FilterTitle}>Software</p>
          <CheckBoxList
            checkboxes={[
              {
                name: "Version 1.0",
                label: "Version 1.0",
                isChecked: false,
              },
              {
                name: "Version 2.0",
                label: "Version 2.0",
                isChecked: false,
              },
              {
                name: "Version 3.0",
                label: "Version 3.0",
                isChecked: false,
              },
            ]}
            onCheckboxChange={handleCheckboxChange} // Pass the function
          />
        </div> */}
      </div>
      <div className={css.FilterButtonsBox}>
        <Button className="Btn-Mid inverse">Clear All</Button>
        {/* <Button className="Btn-Mid Active">Show 16</Button> */}
      </div>
    </div>
  );
};

export default Filters;
