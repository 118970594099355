import { PublicGet } from "./Server";
import Url from "./Url";
export default class TaskService{
    constructor(task, rank){
        this.task=task;
        this.rank=rank;
    }
    setRank(){
        return new Promise((resolve, reject)=>{
            PublicGet(Url.publicMilestones).then(resp=>{
                this.rank=resp;
                //console.log(resp);
                resolve(1);
            }).catch(err=>{
                reject(err);
            })
        })
    }

    msGroup(ls= this.list()){
        //const ls= this.list();
        let last=ls[0];
        let cntEq=0;
        const out=ls.reduce((a, v)=>{
            const ms={};
            ms.name=v.name;
            ms.group=[{...v}];
            const r=this.rank.find(r=>r.name===v.name);
            if(last.equipment !==v.equipment){
                cntEq++;
            }
            if(r){
                ms.rank=r.id
            }else{
                ms.rank=0
            }

            if(cntEq===0){
                a.push(ms)
            }
            else if(a.find(t=>t.name===v.name)){
                a.find(t=>t.name===v.name).group.push({...v});
            }
            else{
                const index=a.findIndex(t=>t.rank > ms.rank);
                if(index> -1)
                a.splice(index, 0, ms);
                else
                a.push(ms)

            } 
            last={...v};
            return [...a]},[]);
        return out
    }
    list(){
        return this.task.taskEquipmentList.reduce((a,v)=>{
            v.taskMilestones.map(x=>x.equipment=v.name)
            return[...a,...v.taskMilestones]},[])    
    }
    
}

