import { useState, useEffect } from "react";

import RoundedStatus from "../RoundedStatus/RoundedStatus";
import Svg from "../Svg/Svg";
import SvgOptions from "../Svg/SvgOptions";
import css from "./AccordianTask.module.css";

const AccordianTask = ({ children, selected = false, title, equipment_id, id, onChange=()=>{} }) => {
  const [openAccordian, setOpenAccordian] = useState(false);
  const [selectAccordian, setSelectAccordian] = useState(selected);
  useEffect(()=>{
    onChange(id, equipment_id, selectAccordian)
  }, [selectAccordian])
  return (
    <div
      className={selectAccordian ? css.AccordianClicked : css.Accordian}
      style={{ color: "var(--blue)" }}
    >
      <div
        className={selectAccordian ? css.AccordianTopClicked : css.AccordianTop}
      >
        <div
          className={
            selectAccordian ? css.AccordianTitleClicked : css.AccordianTitle
          }
        >
          <RoundedStatus
            onClick={() => setSelectAccordian(!selectAccordian)}
            status={selectAccordian ? 2 : 1}
          />
          <span
            onClick={() => setOpenAccordian(!openAccordian)}
            style={{ cursor: "pointer" }}
          >
            {title}
          </span>
        </div>
        <Svg onClick={() => setOpenAccordian(!openAccordian)}>
          {!openAccordian ? SvgOptions.ArrowDownFill : SvgOptions.ArrowUpFill}
        </Svg>
      </div>

      {openAccordian && (
        <div
          className={
            selectAccordian
              ? css.AccordianDropDownClicked
              : css.AccordianDropDown
          }
        >
          <div className={css.content}>{children}</div>
        </div>
      )}
    </div>
  );
};

export default AccordianTask;
