import DateTime from "../DateTime/DateTime";
import css from "../Form.module.css"
import { useRef, useState, useEffect } from "react";
const Input=({children, onChange})=>{
    const {label, name, placeholder, type, value, required, disabled}=children;
    const id="id"+Date.now() + (Math.random() * 1000);
    const ref=useRef();
    useEffect(()=>{
        ref.current.value=value?value:"";
    },[value])
    useEffect(()=>{
        ref.current.value=value?value:"";
    },[children])
    const setValue=()=>{
        children.value=ref.current.value;
        onChange&&onChange();
    }
    return(
        <div className={css.FormControl}>
            <div>
                <label htmlFor={id}>{label}</label>
            </div>
            <div>
                <input disabled={disabled} id={id} required={required?required:false} 
                defaultValue={value} onChange={setValue} ref={ref} type={type} name={name} placeholder={placeholder} autoComplete="off"/>
            </div>
        </div>
    )
}
export default Input;