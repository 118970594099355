import React from "react";
import Form from "../../../../../Components/Form/Form";
import AddTemplateDetailsFormOptions from "./TemplateDetailsFormOptions";
import { forwardRef, useImperativeHandle } from "react";
import Utils from "../../../../../Services/Utills";
const TemplateDetails = forwardRef(({init}, ref) => {
  const templateDetailsFormOptions = AddTemplateDetailsFormOptions(init);
  useImperativeHandle(ref, ()=>({
    data(){
      return Utils.FormObject(templateDetailsFormOptions)
    }
  }))
  const submitTemplateDetails = (e) => {
    //console.log(templateDetailsFormOptions)
  };
  return (
    <div>
      <p
        style={{
          fontSize: "28px",
          fontWeight: 600,
          color: "var(--blue)",
          marginBottom: "25px",
          marginTop: "10px",
        }}
      >
        Contract Details
      </p>
      <Form onSubmit={submitTemplateDetails}>{templateDetailsFormOptions}</Form>
    </div>
  );
});

export default TemplateDetails;
