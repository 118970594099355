import React from "react";
import css from "./Style.module.css";
import PageHead from "../../../../Components/PageHead/PageHead";
import SvgOptions from "../../../../Components/Svg/SvgOptions";
import { useNavigate } from "react-router-dom";
import ModalOption from "../../../../Services/ModalOption";
import AddTaskWizard from "../../../../Modals/AddEnterpriseTaskModal/AddTaskWizard";
import AddPendingTaskWizard from "../../../../Modals/AddPendingEnterpriseTask/AddTaskWizard";
import User from "../../../../Services/User";
import { useState, useEffect } from "react";
import { Error, Get } from "../../../../Services/Server";
import Url from "../../../../Services/Url";
import DataTable from "../../../../Components/DataTable/DataTable";

const Reader = (rec) => {
	return [
		rec.title,
		rec.jobNumber,
		rec.companyName,
		rec.location,
		rec.createdAt,
	];
};
const JobReader = (rec) => {
	return ["", rec.equipment, rec.company, rec.createdAt];
};
const OpEnHome = ({ navoption, ModalAction }) => {
	const role = User.getRole();
	const heading = ["Job Number", "Company", "Location", "Submitted on"];
	const jobHeading = ["Job", "Company", "Started on"];
	const [current, setCurrent] = useState({
		heading: ["Current Tasks", ...heading],
	});
	const [previous, setPrevious] = useState({
		heading: ["Previous Tasks", ...heading],
	});
	const [pending, setPending] = useState({
		heading: ["Pending Tasks", ...jobHeading],
	});
	const flex = [20, 20, 20, 20, 20];

	const nav = useNavigate();
	const option = ModalOption();
	option.element = AddTaskWizard;
	option.title = "Add Enterprise Task";
	option.closeModal = () => {
		ModalAction.closeModal();
	};
	const btns = [
		{
			id: 2,
			text: "Engineers",
			icon: SvgOptions.Engineer,
			className: "Inverse",
			onClick: () => {
				nav("Engineers");
			},
		},
		{
			id: 3,
			text: "Add Task",
			icon: SvgOptions.Add,
			onClick: () => {
				ModalAction.openModal(option);
			},
		},
	];
	const load = (state, page, cb) => {
		Get(Url.enterprise.taskList(state, page))
			.then((resp) => {
				cb(resp);
			})
			.catch((err) => {
				Error(err);
			});
	};
	const loadCurrent = (page) => {
		load(true, page, (resp) => {
			setCurrent({ ...current, data: resp });
		});
	};
	const loadPrevious = (page) => {
		load(false, page, (resp) => {
			setPrevious({ ...previous, data: resp });
		});
	};
	const loadPending = (page) => {
		Get(Url.sales.getAllEnterpriseInitDTO(true, page)).then((resp) => {
			setPending({ ...pending, data: resp });
		});
	};
	const handlePending = (e) => {
		const Options = {
			title: "Add Task",
			element: AddPendingTaskWizard,
			jobId: e.id,
			closeModal: (resp) => {
				loadCurrent(0);
				loadPrevious(0);
				loadPending(0);
				ModalAction.closeModal();
			},
		};
		ModalAction.openModal(Options, "Add Task");
		//        console.log(e)
	};
	useEffect(() => {
		loadCurrent(0);
		loadPrevious(0);
		loadPending(0);
	}, []);

	const handleNextCurrent = (e) => {
		loadCurrent(e);
	};
	const handleNextPrevious = (e) => {
		loadPrevious(e);
	};
	const handleClick = (e) => {
		nav("Task/" + e.id);
	};
	return (
		<div className={css.OpEnHome}>
			<PageHead navoption={navoption} buttons={btns}></PageHead>
			<div>
				{role.id === 2 && pending.data && (
					<DataTable
						handlePagination={handleNextCurrent}
						reader={JobReader}
						flex={flex}
						onClick={handlePending}
					>
						{pending}
					</DataTable>
				)}
			</div>
			<div>
				{current.data && (
					<DataTable
						handlePagination={handleNextCurrent}
						reader={Reader}
						onClick={handleClick}
						flex={flex}
					>
						{current}
					</DataTable>
				)}
			</div>
			<div>
				{previous.data && (
					<DataTable
						handlePagination={handleNextPrevious}
						reader={Reader}
						onClick={handleClick}
						flex={flex}
					>
						{previous}
					</DataTable>
				)}
			</div>
		</div>
	);
};

export default OpEnHome;
