import style from "./Milestones.module.css";
import ProgressBar from "../ProgressBar/ProgressBar";
import StatsGroup from "./StatusGroup";
import { useState, useEffect } from "react";
import TaskService from "../../Services/TaskService";
import Url from "../../Services/Url";
const Milestones = ({ children, activeUser, onComplete, updateUrl=Url.taskMilestone }) => {
	const ts = new TaskService();

	const getGroup = (children) => {
		return {
			future: ts.msGroup([...children.filter((ms) => ms.status.id === 1)]),
			waiting: ts.msGroup([...children.filter((ms) => ms.status.id === 2)]),
			approved: ts.msGroup([...children.filter((ms) => ms.status.id === 3)]),
		};
	};

    const cnt=children.length;
    const share=(100/cnt);
    const [data, setData]=useState()
    useEffect(()=>{
        ts.setRank().then(()=>{
            setData(getGroup(children))
        })    
    },[])

	const [percent, setPercent] = useState(0);
	const onChange = () => {
		ts.setRank().then(() => {
			setData(
				getGroup(
					[...data.approved, ...data.future, ...data.waiting].reduce(
						(a, v) => [...a, ...v.group],
						[]
					)
				)
			);
		});
	};

    useEffect(()=>{
        if(percent>99){
            onComplete&&onComplete()
        }
    },[percent])
    const dataLen=(dt)=>{
        return dt.reduce((a,v)=>[...a, ...v.group],[]).length;
    }
    useEffect(()=>{ 
        if(data){
            setPercent(dataLen(data.approved)*share + (dataLen(data.waiting)*share)/2)
        }
    },[data])
    return (
        <div className={style.Milestones}>
            <div className={style.Title}>
                <div>Milestones</div>
                <div className={style.ProgressValue}>{percent.toFixed(2)}%</div>
            </div>
            <div className={style.Progess}>
                <ProgressBar padding="0" progress={percent} className="ElectricBlue"></ProgressBar>
            </div>
            {data&&
            <>
            <StatsGroup ts={ts} onChange={onChange} updateUrl={updateUrl} title="Approved" activeUser={activeUser}>{data.approved}</StatsGroup>
            <StatsGroup ts={ts} onChange={onChange} updateUrl={updateUrl} title="Awaiting approval" activeUser={activeUser}>{data.waiting}</StatsGroup>
            <StatsGroup ts={ts} onChange={onChange} updateUrl={updateUrl} title="Future" activeUser={activeUser}>{data.future}</StatsGroup>
            </>
            }
        </div>
    )
}
export default Milestones;
