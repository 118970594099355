import style from "./style.module.css";
import RoundedStatus from "../RoundedStatus/RoundedStatus";

const SalesMilestone = ({ children, name }) => {
  const onClick = () => {};
  return (
    <div className={style.Milestone}>
      <div>
        <RoundedStatus onClick={onClick} status={children.id}></RoundedStatus>
      </div>
      <div className={style.Title}>{name}</div>
    </div>
  );
};

export default SalesMilestone;
