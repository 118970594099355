import logo from "../../Assets/Images/Logo.png"
import "./ClientMilestones.css"
import MilestoneData from "../../Data/MilestoneData"
import ServiceReport from "../../Components/ServiceReport/ServiceReport"
import { Route, Routes, useLocation } from "react-router-dom"
import CMRight from "./CMRight"
import ClientNotifications from "./ClientNotifications"
const ClientMilestones=({ModalAction})=>{
    const location=useLocation();
    return(
        <div className="CM-Page">
            <div className="CM-Left">
                <img src={logo} alt="logo" className="CM-Logo"></img>
                <div className="CM-Contact">
                    <h1>Contact us</h1>
                    <p>
                    If you have any concerns or issues with<br/>
                    your order, please don't hesitate to get in<br/>
                    touch with us.                    
                    </p>
                    <p><span>Email:</span> support@fbase.co.uk</p>
                    <p><span>Phone:</span> +234 9030 001 104</p>
                </div>
            </div>
            <div className="CM-Right">
                <Routes>
                    <Route path="report" element={<ServiceReport>{MilestoneData}</ServiceReport>}></Route>
                    <Route path="notification" element={<ClientNotifications></ClientNotifications>}></Route>
                    <Route path="*" element={location.state?<CMRight ModalAction={ModalAction}>{location.state}</CMRight>:<div><h1>Invalid state</h1></div>}></Route>
                </Routes>                    
            </div>
        </div>
    )
}
export default ClientMilestones;