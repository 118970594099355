import css from "./Details.module.css"
import AddButton from "../../../../Components/Form/AddButton/AddButton"
import { ModalAction } from "../../../../Routes/NavRouter"
import AddVesselModal from "../../../../Modals/AddVesselModal/AddVesselModal"
import { Post,Get } from "../../../../Services/Server"
import Url from "../../../../Services/Url"
import { toast } from "react-toastify"
import { useState } from "react"
import Svg from "../../../../Components/Svg/Svg"
import SvgOptions from "../../../../Components/Svg/SvgOptions"
const Right = ({state, onChange}) => {
    const [company, setCompany]=useState(state)
    const {openModal, closeModal}=ModalAction;
    const closeAddVessel=(e)=>{
        company.vessels.push(e);
        if(onChange){
            onChange(company)
            setCompany(company)
            closeModal();
            return;
        }
        Post(Url.saveCompany, company).then(resp=>{
            setCompany(resp)
            toast("Vessel Added")
        })
        closeModal();
    }
    const handleAddVessel=()=>{
        const option={
            element:AddVesselModal,
            title:"Add Vessel",
            closeModal:closeAddVessel,
            companyId:state.id
        }
        openModal(option)
    }
    const handleDelete=(e)=>{
        const vessels=company.vessels;
        company.vessels=vessels.filter(v=>v.id!=e.id);
        Post(Url.saveCompany, {...company}).then(resp=>{
            setCompany(resp)
            toast("Vessel Deleted")
        })
        //setCompany({...company});
    }
    const handleEdit=(e)=>{
        company.vessels.find(v=>v.id===e.id).name=e.name
        Post(Url.saveCompany, company).then(resp=>{
            setCompany(resp)
            toast("Vessel Saved")
        })
    }
  return (
    <div className={css.Right}>
        <div className={css.Title}>
            Vessels
            <hr></hr>
        </div>
        <div className={css.Vessels}>
            {company.vessels.map((vessel, index)=>
            <Vessel onDelete={handleDelete} onEdit={handleEdit} key={index}>{vessel}</Vessel>
            )}
            <AddButton onClick={handleAddVessel}>{"Add Vessel"}</AddButton>
        </div>
            
    </div>
)
}
const Vessel=({children, onDelete, onEdit})=>{
    const {openModal, closeModal}=ModalAction;
    const {name}=children;
    const handleEdit=()=>{
        const option={
            element:AddVesselModal,
            title:"Edit Vessel",
            closeModal:(v)=>{
                onEdit({...children, name:v.name});
                closeModal();
                },
            companyId:children.company_id,
            name:name
        }
        openModal(option)
    }
    const handleDelete=(e)=>{
        ModalAction.confirm({
            title:"Deleteing Item?",
            text:"You are about to delete the selected item",
            exitText:"Close",
            okText:"Delete",
            onOk:onOk
            })
    }
    const onOk=()=>{
        onDelete(children)
    }
    return(
        <div className={css.Vessel}>
            <div className={css.Name}>{name}</div>
            <div className={css.VesselControls}>
            <div className={css.Edit}><Svg onClick={()=>handleEdit(children)}  className="Icon">{SvgOptions.Edit}</Svg></div>
            <div className={css.Delete}><Svg onClick={()=>handleDelete(children)} className={css.Icon}>{SvgOptions.Delete}</Svg></div>
            </div>
        </div>
    )
}
export default Right