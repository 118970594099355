import FormType from "../../../../../Components/Form/FormType";

const AddRiskAssessmentFormOptions = (init) => {
	const form = {
		Fields: [
			{
				element: FormType.TextArea,
				required: true,
				label: "Health and Safety",
				value: init.risk.healthAndSafety,
				name: "healthAndSafety",
			},
			{
				element: FormType.TextArea,
				required: true,
				label: "Security Concerns",
				placeholder: "Enter security concerns",
				name: "securityConcerns",
				value: init.risk.securityConcerns,
			},
		],
		Buttons: [
			{ type: "button", text: "Close", className: "Btn-Mid", onClick: null },
			{ type: "submit", text: "Submit", className: "Btn-Mid Active" },
		],
	};
	// init.risk?.riskScore?.forEach(risk=>{
	//   form.Fields.push(
	//     {
	//       element: FormType.Slider,
	//       required: true,
	//       label: risk.riskItem.name,
	//       placeholder: risk.riskItem.details,
	//       parse:{object:risk, set:"score"},
	//       value:risk.score,
	//       changeEvent:true,
	//       min: 0,
	//       max: 10,
	//       name: "riskScore",
	//     }

	//   )
	// })
	return form;
};

export default AddRiskAssessmentFormOptions;
