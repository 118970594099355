const ModalOptions={
    confirm:{
        title:"Logging out?",
        text:"You are about to logout from fieldbase progress app",
        exitText:"Exit",
        okText:"Continue",
        onOk:()=>{}
        },

}
export default ModalOptions