import { useState } from "react";
import logoImg from "../../Assets/Images/Logo2.png";
import User from "../../Services/User";
import NavItem from "./NavItem";
import "./NavMenu.css";

const setNav = { notification: null };

const NavMenu = ({ children, accNav, logout }) => {
  const base = User.getBase();
  const [notifyOption, setNotify] = useState(accNav[0]);
  const accNavOptions = accNav.slice(1);
  setNav.notification = (num) => {
    setNotify({ ...notifyOption, notify: num + "" });
  };

  return (
    <div className="NavMenu">
      <div className="logoCont">
        <img src={logoImg} alt="Logo" />
      </div>
      <div>
        <div className="NavTitle">
          <h5>Menu</h5>
        </div>
        <div className="NavItems">
          {children.map((navoption, index) => (
            <NavItem base={base} key={index}>
              {navoption}
            </NavItem>
          ))}
        </div>
      </div>
      {accNav && (
        <div>
          <div className="NavTitle tm40">
            <h5>Account</h5>
          </div>
          <div className="NavItems">
            <NavItem base={base}>{notifyOption}</NavItem>
            {accNavOptions.map((navoption, index) => (
              <NavItem base={base} key={index}>
                {navoption}
              </NavItem>
            ))}
          </div>
        </div>
      )}
      <div className="Logout">
        {logout && (
          <div className="NavItems">
            <NavItem>{logout}</NavItem>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavMenu;
export { setNav };
