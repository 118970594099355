import css from "./FeedbackModal.module.css"
import ProgressBar from '../../Components/ProgressBar/ProgressBar';
import Feedback from "./Feedback";
import FeedbackOptions from "./FeedbackOptions";
import { useState } from 'react';
import { Zoom } from "react-reveal";
import { PublicPost } from "../../Services/Server";
import Url from "../../Services/Url";
import { toast } from "react-toastify";
const FeedbackModal=({children})=>{
  const {tracking, closeModal}=children;
  const [page, setPage]=useState(0);
  const onContinue=(data)=>{
    PublicPost(Url.saveFbByTracking(tracking), data)
    .then(()=>{
      toast("Saved")
    })
    .catch(err=>{
      console.log(err)
    })
    if(FeedbackOptions.length-1 > page){
      setPage(page +1)
    }else{
      closeModal();
    }
  }
    return(
      <div>
        <ProgressBar progress={(page+1)*100/FeedbackOptions.length} borderRadius="0"></ProgressBar>
        <div className={css.Step}>Step {page+1}/{FeedbackOptions.length}</div>
          <div className={css.FeedbackModel}>
            {
              FeedbackOptions.map((option, index)=>
                <div style={{display:page===index?"block":"none"}} key={index}><Zoom key={index}>
                  <div key={index} className={css.Page}>
                  <Feedback key={index} onContinue={onContinue}>{option}</Feedback>
                  </div></Zoom></div>
              )
            }
          </div>
      </div>
    )
}
export default FeedbackModal;