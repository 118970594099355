import React from 'react'
import { useState, useEffect } from 'react'
import MilestoneEdit from '../Milestone2/MilestoneEdit'
import Svg from '../Svg/Svg'
import SvgOptions from '../Svg/SvgOptions'
import css from "./Style.module.css"
import DragDrop from '../DragDrop/DragDrop'
const MilestonesDragable = ({children, onDelete, onChange, onClick, btn}) => {
    const [list, setList]=useState([])
    useEffect(()=>{
      setList(children)
    },[children])
    const save=()=>{}
    return(
      <div>
          <div>
              <div className={css.RHead}><h2> {list.length} Milestones</h2></div>
              <div className={css.RMSList}>
                  <div>Milestone name</div>
                  <div>Assignee</div>
                  <div>Client</div>
              </div>
              {list&&
                  <DragDrop Events={{onDelete, onChange, onClick}} Element={MilestoneEdit} onOrderChanged={setList}>{list}</DragDrop>            
              }
              {
                btn&&
                <div onClick={save} className={css.addMilestoneBtn}><div><Svg className={css.svg}>{SvgOptions.Add}</Svg></div><div>Save Changes</div></div>
            }
          </div>
      </div>
    )
  }

export default MilestonesDragable