import css from "./Style.module.css"
import AddButton from "../../Components/Form/AddButton/AddButton"
import ExpensesOptions from "./ExpenseOptions"
import Input from "../../Components/Form/Input/Input"
import Utils from "../../Services/Utills"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import Button from "../../Components/Button/Button"
import { Post, Get, UploadFile, Error } from "../../Services/Server"
import Url from "../../Services/Url"
import User from "../../Services/User"
import Svg from "../../Components/Svg/Svg"
import SvgOptions from "../../Components/Svg/SvgOptions"
import { Document, Page } from "react-pdf"
const ExpenseModal = ({children}) => {
const {task, closeModal}=children
const [changed, setChanged]=useState(false);
const expenses=task.expenses?(task.expenses.expenseApprovals?{taskId:task.id, submitted:false, expenseDetailsList:task.expenses.expenseDetailsList.reduce((a,v)=>{
    delete v.id;
    return [...a, v]},[])}:task.expenses):{taskId:task.id, submitted:false,expenseDetailsList:[]};

    const biodata=User.getBiodata();
const serial=(obj)=>{
    return JSON.parse(JSON.stringify(obj))
}
const op=serial(ExpensesOptions);
const initData=()=>{
    if(expenses.expenseDetailsList){
        return expenses.expenseDetailsList.map(v=>{
            const op={...serial(ExpensesOptions)};
            op.id=v.id;
            op.receiptId.value=v.receiptId;
            op.receiptType.value=v.receiptType;
            op.name.value=v.name;
            op.quantity.value=v.quantity;
            op.price.value=v.price;
            op.receipt.value=v.receiptId&&Url.img.thumb(v.receiptId);
            return {...op}});
    }
 
    return [ExpensesOptions];
}
const [options, setOptions]=useState(initData())
const addItem=()=>{
    setChanged(true);
    setOptions([...options, serial(op)])
}
const isValidFile=(filename)=>{
    const exts=["png", "gif", "jpg" , "jpeg" , "jfif" , "pjpeg", "pjp", "pdf"]
    if(!filename)return false;
    const ext=(filename.split(".").pop())?.toLowerCase();
    return exts.find(ex=>ex.localeCompare(ext)===0);       
}
const onUpload=(e, index)=>{
    const filename=e.target.value
    if(isValidFile(filename)){
        const fileNameArr=filename.split('\\');
        const name=fileNameArr[fileNameArr.length-1]
        UploadFile(e.target.files[0], name).then(resp=>{
            options[index].receiptId.value=resp.id;
            options[index].receiptType.value=resp.fileType;
            setChanged(true);
        }).catch(err=>{
            Error(err)
            console.log(err)
        })
        
        Utils.getBase64File(e.target.files[0], (result)=>{
            const fileType=result.split(";")[0].substring(5);            
            options[index].receipt={value:result, type:fileType}
            setOptions([...options])
        })
    }else toast("Selected file is not an image")
}

const save=()=>{
    return new Promise((resolve)=>{
        const dt=options.reduce((a,v)=>[...a, 
            {id:v.id, name:v.name.value,price:v.price.value, quantity:v.quantity.value,receiptId:v.receiptId.value, receiptType:v.receiptType.value, currency:1}],[])
        expenses.expenseDetailsList=dt;
        Post(Url.expenses.save, expenses).then((resp)=>{
            toast("Saved")
            resolve(resp)
            setChanged(false);
        }).catch(err=>{
            Error(err)
            console.log(err)
        }).finally(()=>{
            closeModal();
        })    
    })
}
const setSubmit=()=>{
    Get(Url.expenses.submit(expenses.id, biodata.id)).then(()=>{
        toast("Submitted")
    }).catch(err=>{
        Error(err)
        console.log(err);
    }).finally(()=>{
        closeModal();
    })
}
const submit=()=>{
    if(changed){
        save().then(()=>{
            setSubmit();
        })
    }else{
        setSubmit();
    }
}
const handleDelete=(index)=>{
    options.splice(index, 1);
    setOptions([...options]);
}
return (
    <div className={css.Modal}>
        <div className={css.Items}>
            {options.map((op, index)=>
            <div key={index} className={css.Item}>
                <div className={css.Img}>
                    <input id={"file-" + index} onChange={(e)=>onUpload(e, index)} className={css.FileInput} type="file" />
                    <label htmlFor={"file-" + index}>
                        <RecieptView>{op}</RecieptView>          
                    </label>
                </div>
                <div className={css.FormCont}>
                    <div><Input onChange={()=>setChanged(true)}>{op.name}</Input></div>
                    <div className={css.Details}>
                        <div className={css.Value}><Input onChange={()=>setChanged(true)}>{op.price}</Input><div className={css.Currency}>NGN</div></div>
                        <div className={css.Value}><Input onChange={()=>setChanged(true)}>{op.quantity}</Input><div className={css.Currency}>Qty</div></div>
                    </div>
                </div>
                <div className={css.Delete}><Svg onClick={()=>handleDelete(index)}>{SvgOptions.Delete}</Svg></div>
            </div>        
            )}
        </div>
        <div className={css.Submit}>
            <AddButton onClick={addItem}>Add Item</AddButton>
            {expenses.id&&
                <Button onClick={submit} className="Active Btn-Mid">{changed?"Save & Submit for appproval":"Submit for appproval"}</Button>
            }
        </div>
        <div className={css.Foot}>
            <div>
                <Button onClick={closeModal} className="Inverse Btn-Mid">Close</Button>
            </div>
            <div>
                <Button onClick={save} className="Active Btn-Mid">Save</Button>
            </div>
        </div>
    </div>
  )
}

const RecieptView=({children})=>{
    if(children.receipt.value){
    const {value, type}=children.receipt;
    if(type?.includes("pdf")){
        return (
        <div className={css.pdf}>
            <object data={value} width={200} >
            </object>
        </div>
        )
    }
    return(
        <div className={css.img}>
            <img src={value} width={200} ></img>
        </div>
    )
              
    }
    return(
        <div>
            <div>Reciept</div>
            <div className={css.UploadTypeName}>PDF Or Image</div>
        </div>
    )
}

export default ExpenseModal