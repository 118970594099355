import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import css from "./AddJob.module.css";
import Button from "../../../../Components/Button/Button";
import Svg from "../../../../Components/Svg/Svg";
import SvgOptions from "../../../../Components/Svg/SvgOptions";
import Form from "../../../../Components/Form/Form";
import AddJobFormOptions from "./AddJobFormOptions";
import { Get, Post } from "../../../../Services/Server";
import Url from "../../../../Services/Url";
import Utils from "../../../../Services/Utills";
import { toast } from "react-toastify";
import Expandable from "../../../../Components/Expandable/Expandable";
import AddSalesEquipment from "../../../../Components/AddEquipment/AddSalesEquipment";
const AddJob = () => {
	const [options, setOptions] = useState(AddJobFormOptions);
	const [expand, setExpand] = useState(true);
	const [equipment, setEquipment] = useState([]);

	const nav = useNavigate();
	const onSubmit = () => {
		const dt = Utils.FormObject(options);
		if (equipment.length === 0) {
			toast("Kindly add one or more equipment and service");
			return null;
		}
		dt.equipment = equipment;
		Post(Url.sales.enterpriseInit, dt)
			.then(() => {
				toast("Job Initiated");
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				nav("../");
			});
	};

	const companyChange = (e) => {
		if (typeof e === "object" && e != null) {
			const op = { ...options };
			Get(Url.enterpriseCompany.contacts(e.id)).then((resp) => {
				op.Fields.find((fld) => fld.name === "contact").options = resp;
				setOptions(op);
			});
		}
	};
	const contactChange = (e) => {
		const op = { ...options };
		if (typeof e === "object" && e != null) {
			op.Fields.find((fld) => fld.name === "email").value = e.email;
		} else {
			op.Fields.find((fld) => fld.name === "email").value = null;
		}
		setOptions(op);
	};
	options.Fields.find((fld) => fld.name === "contact").onSelected =
		contactChange;
	options.Fields.find((fld) => fld.name === "company").onSelected =
		companyChange;

	const handleAddService = (e) => {
		setEquipment([...equipment, { ...Utils.FormObject(e) }]);
		setExpand(false);
	};
	const onDelete = (index) => {
		equipment.splice(index, 1);
		setEquipment([...equipment]);
	};
	return (
		<div className={css.AddJob}>
			<div className={css.Top}>
				<NavLink to=".." style={{ textDecoration: "none" }}>
					<div className={css.BackBox}>
						<Svg>{SvgOptions.BackArrow}</Svg>
						<span>Back</span>
					</div>
				</NavLink>
				<div>
					<Button onClick={onSubmit} className="Btn-Mid Active">
						Save
					</Button>
				</div>
			</div>
			<hr />
			<div className={css.Body}>
				<div className={css.row}>
					<h1 className={css.Title}>Initiate Job</h1>
					<div className={css.Head}>
						<Button
							onClick={() => setExpand(!expand)}
							className="Btn-Mid Active"
							icon={SvgOptions.Add}
						>
							Add Equipment
						</Button>
					</div>
				</div>
				<div className={css.modalCont}>
					<div className={`${css.modal} ${expand ? css.visible : css.hidden}`}>
						<Expandable expand={expand} height={320}>
							<AddSalesEquipment
								onSubmit={handleAddService}
							></AddSalesEquipment>
						</Expandable>
					</div>
				</div>
				<Form onSubmit={onSubmit}>{options}</Form>
				<div className={css.EquipmentList}>
					{equipment.map((eq, index) => (
						<EquipmentView key={index} index={index} onDelete={onDelete}>
							{eq}
						</EquipmentView>
					))}
				</div>
			</div>
		</div>
	);
};

const EquipmentView = ({ children, onDelete, index }) => {
	const { equipment, service, template } = children;
	const handelDelete = () => {
		onDelete && onDelete(index);
	};
	return (
		<div className={css.EquipmentView}>
			<div>
				<div>{equipment}</div>
				<div onClick={handelDelete} className={css.DeleteIcon}>
					<Svg>{SvgOptions.Delete}</Svg>
				</div>
			</div>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<div>{service.name}</div> <div>{template.name}</div>
			</div>
		</div>
	);
};

export default AddJob;
