import FormType from "../../../../../Components/Form/FormType";
import Url from "../../../../../Services/Url";
const EditTemplateOptions = {
	Fields: [
		[
			{
				element: FormType.Input,
				type: "text",
				name: "name",
				label: "Name",
				placeholder: "Repair ICOMS",
				value: null,
			},
			{
				element: FormType.Select,
				name: "type",
				label: "Task Type",
				placeholder: "Select Type",
				value: null,
				options: { url: Url.taskType },
			},
		],
		[
			{
				element: FormType.TextArea,
				name: "use_case",
				label: "Use case",
				value: null,
			},
		],
	],
};
export default EditTemplateOptions;
