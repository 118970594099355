import css from "./style.module.css"
import React from 'react'
import SvgOptions from "../../Components/Svg/SvgOptions"
import Svg from "../../Components/Svg/Svg"
import { useState } from "react"
import Button from "../../Components/Button/Button"
import { Post } from "../../Services/Server"
import Url from "../../Services/Url"
import Utils from "../../Services/Utills"
import { toast } from "react-toastify"
const TaskOptionModal = ({children}) => {
    const {task, closeModal}=children
    const taskOptions=task.taskOptions
    const options=[
        {name:"id", value:taskOptions&&taskOptions.id},
        {name:"chargeable", value:taskOptions&&taskOptions.chargeable, label:"Chargeable"},
        {name:"firstTimeFix", value:taskOptions&&taskOptions.firstTimeFix,label:"First Time Fix"},
    ]
    const handleSave=()=>{
        task.taskOptions=Utils.FormObject(options);
        Post(Url.saveTask, task).then((resp)=>{
            toast("Task Saved");
            closeModal(resp);
        })
    }
  return (
    <div className={css.Modal}>
        <div className={css.body}>
            <div>Kindly check correct options. Error in the options may affect your rating and that of the field engineer.</div>
            {options.filter(opf=>opf.label).map((op, index)=>
            <div key={index}>
                <CheckBox>{op}</CheckBox>
                <div>
                    {op.label}
                </div>
            </div>        
            )}
        </div>
        <div className={css.foot}>
            <div>
                <Button onClick={handleSave} className="Btn-Mid Active">Save</Button>
            </div>
            <div>
                <Button className="Btn-Mid">Cancel</Button>
            </div>
        </div>
    </div>
  )
}

const CheckBox=({children})=>{
    const [state, setState]=useState(children.value);
    const handleClick=()=>{
        children.value=!children.value;
        setState(children.value)
    }
    return (
        <div className={css.CheckBox} onClick={handleClick}>
            <div style={{visibility:state?"visible":"hidden"}}>
                <Svg className="Check">{SvgOptions.Check}</Svg>
            </div>
        </div>
    )
}
export default TaskOptionModal