import RouteView from "../../../Routes/RouteView";
import KnowledgeBaseNavOptions, {
  defaultRoute,
} from "./KnowledgeBaseNavOptions";

const KnowledgeBase = ({ navoption, ModalAction }) => {
  return (
    <RouteView
      navoption={navoption}
      ModalAction={ModalAction}
      defaultRoute={defaultRoute}
    >
      {KnowledgeBaseNavOptions}
    </RouteView>
  );
};

export default KnowledgeBase;
