import React from 'react'
import css from "./Style.module.css"
import FormOptions from './FormOptions'
import userImg from "../../../../Assets/Images/userImage2.png"
import ImgThumb from "../../../../Components/ImgThumb/ImgThumb";
import Form from '../../../../Components/Form/Form'
import Button from '../../../../Components/Button/Button';
import Utils from '../../../../Services/Utills';
import Url from '../../../../Services/Url';
import { Post, Error } from '../../../../Services/Server';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const AddEngineer = () => {
    const nav=useNavigate();
    const handleSubmit=()=>{
        Post(Url.enterprise.saveEngineer, Utils.FormObject(FormOptions)).then(resp=>{
            toast("Saved")
            nav("../")
        }).catch(err=>{
            Error(err);
        })
    }
  return (
    <div className={css.AddEngineer}>
            <div className={css.Head}>
                <div>
                    <ImgThumb size={120}>{userImg}</ImgThumb>
                </div>
                <div>
                    <h2>User Profile</h2>
                    <div>
                    You can upload images up to 256x256. Your avatar shows up in your public profile and your team notifications.
                    </div>
                    <div className={css.Btn}>
                        <Button className="Btn-Mid Active">Upload</Button>
                    </div>
                </div>
            </div>

            <div>
                <h2 className={css.Title}>Enterprise Engineer's Details</h2>
                <div>
                    <Form onSubmit={handleSubmit}>{FormOptions}</Form>
                </div>
            </div>

    </div>
  )
}

export default AddEngineer
