import React from "react";
import Details from "./Details";
import AddContract from "./AddContract/AddContract";
import SvgOptions from "../../../../Components/Svg/SvgOptions";
import RouteView from "../../../../Routes/RouteView";
import { useParams, useLocation } from "react-router-dom";
import { Get } from "../../../../Services/Server";
import Url from "../../../../Services/Url";
import { useEffect, useState } from "react";
const DetailsNavOption = [
	{
		element: AddContract,
		path: "AddContract",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		icon: SvgOptions.Task,
	},
];

const defaultRoute = {
	element: Details,
	path: "*",
	access: { user: true, admin: true, super: true },
	Protected: false,
	default: true,
	icon: SvgOptions.Task,
};

const DetailsView = ({ ModalAction, navoption }) => {
	const { id } = useParams();
	const [init, setInit] = useState(null);
	useEffect(() => {
		Get(Url.sales.getEnterpriseInitial(id))
			.then((resp) => {
				setInit(resp);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	const state = useLocation().state;
	useEffect(() => {
		if (state) {
			state.init && setInit(state.init);
		}
	}, [state]);

	return (
		<>
			{init && (
				<RouteView
					navoption={navoption}
					ModalAction={ModalAction}
					defaultRoute={defaultRoute}
					props={{ init }}
				>
					{DetailsNavOption}
				</RouteView>
			)}
		</>
	);
};

export default DetailsView;
