import { useState } from "react";
import Svg from "../Svg/Svg";
import SvgOptions from "../Svg/SvgOptions";
import css from "./Accordian.module.css";

const Accordian = ({ title, children }) => {
  const [openAccordian, setOpenAccordian] = useState(false);

  return (
    <div className={css.Accordian}>
      <div className={css.AccordianHeading}>
        <div
          className={css.AccordianHeadingLeft}
          onClick={() => setOpenAccordian(!openAccordian)}
        >
          <Svg>
            {!openAccordian ? SvgOptions.ArrowUpFill : SvgOptions.ArrowDownFill}
          </Svg>
          <p>{title}</p>
        </div>
        <Svg>{SvgOptions.MeatballsMenu}</Svg>
      </div>
      {openAccordian && (
        <>
          <hr />
          <div className={css.Content}>{children}</div>
        </>
      )}
    </div>
  );
};

export default Accordian;
