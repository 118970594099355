import React, { useRef, useState } from "react";

import css from "../Form.module.css";

const Slider = ({ children, onChange }) => {
  const { label, max, min, placeholder } = children;
  const [value, setValue] = useState(children.value);

  const handleChange = (event) => {
    setValue(event.target.value);
    children.value=event.target.value;
    onChange&&onChange(children.value)
  };
  

  return (
    <div className={css.FormControl}>
      <div>
        <label>{label}</label>
        <div className={css.SliderDetails}>
          <p className={css.SliderDetailsPlaceholder}>{placeholder}</p>
          <p className={css.SliderDetailsValue}> {value}</p>
        </div>
      </div>
      <div>
        <input
          className={css.Range}
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Slider;
