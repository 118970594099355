import css from "./style.module.css"
const ExpensesItem = ({children}) => {
    const {expenseApprovals, expenseDetailsList} =children;
  return (
    <div className={css.Expenses}>
      <div className={css.Amount}>Total Amount: ₦{children.total}</div>
      <div className={css.List}>
        {expenseDetailsList.map(item=>
            <div key={item.id} className={css.Item}>
                <div>{item.name}</div>
                <div>₦{item.price}</div>
                <div>{item.quantity}</div>
                <div>₦{item.price * item.quantity}</div>
            </div>
            )}
      </div>
        <div>
          {
            expenseApprovals&&
            <div>
              {expenseApprovals.status?
                 <div>
                    <div className={css.Approve}>
                      <div className={css.Title}>Expenses Approved</div>
                      <div className={css.Remark}>
                        <div>Remark</div>
                        <div>{expenseApprovals.remark}</div>
                      </div>
                    </div>
                    {expenseApprovals.payment&&
                    <>
                      {expenseApprovals.payment.status?
                      <div className={css.Approve}>
                        <div className={css.Title}>Payment Approved</div>
                        <div className={css.Remark}>
                          <div>Remark</div>
                          <div>{expenseApprovals.payment.remark}</div>
                        </div>
                      </div>                    
                      :
                      <div className={css.Decline}>
                        <div className={css.Title}>Expenses Disapproved</div>
                        <div className={css.Remark}>
                          <div>Remark</div>
                          <div>{expenseApprovals.payment.remark}</div>
                        </div>
                      </div>                    

                      }
                    </>
                    }
                 </div>
                :
                 <div>
                    <div className={css.Decline}>
                      <div className={css.Title}>Expenses Disapproved</div>
                      <div className={css.Remark}>
                        <div>Remark</div>
                        <div>{expenseApprovals.remark}</div>
                      </div>
                    </div>
                  </div>
              }
            </div>
          }
        </div>
    </div>
  )
}

export default ExpensesItem
