import css from "./AddTaskModal.module.css";
import Form from "../../Components/Form/Form";
import AddTaskOPtions from "./AddTaskOptions";
import { useState } from "react";
import Utils from "../../Services/Utills";
import { ModalAction } from "../../Routes/NavRouter";
const AddTaskModal = ({ children, onNext }) => {
	const { closeModal } = ModalAction;
	const [options, setOptions] = useState(AddTaskOPtions);
	const [saving, setSaving] = useState(false);

	const onSubmit = (data) => {
		const taskDetails = Utils.FormObject(data);
		children.AddTaskModal = taskDetails;
		onNext && onNext(taskDetails);
	};
	return (
		<div className={css.Cont}>
			<div className={css.Head}>
				<h2>Task Details</h2>
			</div>
			<div className={css.Body} style={{ display: saving ? "none" : "flex" }}>
				<Form onSubmit={onSubmit}>{options}</Form>
			</div>
		</div>
	);
};
export default AddTaskModal;
