import AppUser from "../Pages/AppUser/AppUser";
import ClientLogin from "../Pages/ClientLogin/ClientLogin";
import ClientMilestones from "../Pages/ClientMilestones/ClientMilestones";
import Contact from "../Pages/Contact/Contact";
import EnterpriseContact from "../Pages/Contact/EnterpriseContact";
import Login from "../Pages/Login/Login";
import ClientContract from "../Pages/ClientContract/ClientContract";
import EnterpriseClientContract from "../Pages/ClientContract/EnterpriseClientContract";
import Completed from "../Pages/Contact/Completed";
import JobAuth from "../Pages/Contact/JobAuth";
import EnterpriseJobAuth from "../Pages/Contact/EnterpriseJobAuth";
import Tracking from "../Pages/Tracking/Tracking";
import Enterprise from "../Pages/Enterprise/Enterprise";

const NavOptions = [
	{
		element: ClientLogin,
		path: "",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: Login,
		path: "Login",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: AppUser,
		path: "Engineer/*",
		access: { user: true, admin: true, super: true },
		Protected: true,
		default: true,
		nav: { name: "Dashboard" },
	},
	{
		element: AppUser,
		path: "Operation/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		nav: { name: "Dashboard" },
	},
	{
		element: AppUser,
		path: "Account/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		nav: { name: "Dashboard" },
	},
	{
		element: AppUser,
		path: "Admin/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		nav: { name: "Dashboard" },
	},
	{
		element: AppUser,
		path: "Bdm/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		nav: { name: "Jobs" },
	},
	{
		element: AppUser,
		path: "head-engineer/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		nav: { name: "Dashboard" },
	},
	{
		element: ClientMilestones,
		path: "ClientMilestones",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: ClientMilestones,
		path: "ClientMilestones/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: Tracking,
		path: "tracking/:id",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: false,
	},
	{
		element: Contact,
		path: "contact",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: EnterpriseContact,
		path: "enterprise-contact",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: Completed,
		path: "completed",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: JobAuth,
		path: "job-auth/:accountStatus/:token",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: EnterpriseJobAuth,
		path: "enterprise-job-auth/:accountStatus/:token",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: ClientContract,
		path: "Contract",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: EnterpriseClientContract,
		path: "enterprise-contract",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: Enterprise,
		path: "Enterprise/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
	},
	{
		element: Enterprise,
		path: "Enterprise-bdm/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		// nav: { name: "EnterpriseJobs" },
	},
];

export default NavOptions;
