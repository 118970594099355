import SvgOptions from "../../../Components/Svg/SvgOptions";
import AddEngineer from "./AddEngineer/AddEngineer";
import EngineerList from "./EngineerList/EngineerList";
import OpEnHome from "./OpEnHome/OpEnHome";
import TaskDetails from "./TaskDetails/TaskDetails";
const OpEnNavOption=[
    {
        element:OpEnHome, 
        path:"Home/*",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
    {
        element:AddEngineer, 
        path:"Engineer/*",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
    {
        element:EngineerList, 
        path:"Engineers/*",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Engineer
    },
    {
        element:TaskDetails, 
        path:"Task/:id",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
]
const defaultRoute={
    element:OpEnHome, 
    path:"*",
    access:{user:true, admin:true, super:true}, 
    Protected:false,
    default:true,
    icon:SvgOptions.Dashboard
}
export {defaultRoute};
export default OpEnNavOption;