import FormType from "../../../../../Components/Form/FormType"
const FormOptions={
    id:null,
    Fields:[
        {element:FormType.Input, name:"name", label:"Milestone Name"},   
        {element:FormType.Select, name:"executor", label:"Assign To",
            options:[{name:"Engineer", id:1},{name:"Operation", id:2}]
        },   
        {element:FormType.Select, name:"clientView", label:"Client View",
            options:[{name:"True", value:true},{name:"False",value:false}]    
        },
        {element:FormType.Select, name:"approvalRequired", label:"Approve Engineer Update",
            options:[{name:"True", value:true},{name:"False",value:false}]    
        },   
        {element:FormType.Select, name:"merge", label:"Merge",
            options:[{name:"True", value:true},{name:"False",value:false}]    
        },   
    ],
    Buttons:[
        {text:"Save", className:"Btn-Mid Active"}
    ]
}

export default FormOptions;