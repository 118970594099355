import FormType from "../../Components/Form/FormType";

const ContactFormOptions = (init) => {
	return {
		Fields: [
			{
				element: FormType.Input,
				required: true,
				name: "companyName",
				label: "Company Name",
				value: init.companyName,
			},
			[
				{
					element: FormType.Input,
					required: true,
					name: "firstname",
					label: "First Name",
					value: init.firstname,
				},
				{
					element: FormType.Input,
					required: true,
					name: "lastname",
					label: "Last Name",
					value: init.lastname,
				},
			],
			{
				element: FormType.Input,
				required: true,
				name: "phone",
				label: "Phone",
				value: init.phone,
			},
			{
				element: FormType.Input,
				required: true,
				label: "Email",
				name: "email",
				value: init.email,
			},
		],
	};
};

export default ContactFormOptions;
