import "./Tracking.css"
import { useParams, useNavigate } from "react-router-dom"
import { PublicGet } from "../../Services/Server";
import Url from "../../Services/Url";
import { toast } from "react-toastify";
const Tracking=()=>{
    const param=useParams();
    const navigate=useNavigate();
    PublicGet(Url.taskByTracking(param.id)).then(resp=>{
        navigate("../ClientMilestones", {state:resp})
        toast("Verification Successful")
    }).catch(()=>{
        toast("Verification failed")
    })
    return(
        <div className="Tracking">
                <div>
                    <center>
                    <h2>Verifying tracking number</h2>
                    <h3>{param.id}</h3>
                    </center>
                </div>
        </div>
    )
}
export default Tracking