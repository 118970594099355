import {StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        padding: 25,
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
    },
    logo:{
      width:100,
    },
    list:{
      display:"flex",
      flexDirection:"row",
      marginTop:"5px",      
    },
    ul:{
      backgroundColor:"#444444",
      width:"5px",
      height:"5px",
      marginTop:"2.5px",
      borderRadius:"5px",
    },
    topSection:{
      flexDirection: 'row',
      height:30,
      marginBottom:10,
    },
    centerH:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
    },
    centerW:{
      display:"flex",
      flexDirection:"row",
      justifyContent:"center",
    },
    section: {
      padding: 0,
      flexGrow: 1,
      fontSize:12,
      fontWeight:"bolder",
      display:"flex",
      flexDirection:"row",
      justifyContent:"center",
      border:'1px solid #0E3465',
    },
    section2: {
      padding: 0,
      flexGrow: 1,
      fontSize:12,
      fontWeight:"bolder",
      display:"flex",
      flexDirection:"row",
      justifyContent:"center",
      border:'1px solid #0E3465',
      borderLeft:"none",
    },

    colGroup:{
        flexDirection: 'row',
        height:30,  
    },    
    col: {
        flex:1,
        border:'1px solid #0E3465',
        fontSize:10,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        },
      col2: {
        flex:1,
        border:'1px solid #0E3465',
        borderBottom:"none",
        borderRight:"none",
        fontWeight:100,
        fontSize:10,  
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        },
      col3: {
        flex:1,
        border:'1px solid #0E3465',
        borderBottom:"none",
        fontWeight:100,
        fontSize:10,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        },
      col4: {
        flex:1,
        border:'1px solid #0E3465',
        borderRight:"none",
        fontWeight:100,
        fontSize:10,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        },

      ImgGroup:{
        flexDirection: 'row',
        flexWrap:"wrap",
    },    
    Img:{
      width:250,
    },
      colImage:{
        padding: "5px 10px 0px 10px",
        flex:1,
        width:200,
        minHeight:150,  
      },
      strong:{
          fontSize:10,
          fontWeight:"normal",
      },
      colLineGroup2:{
        flexDirection: 'row',
        height:10,  
      },    
      colLineGroup:{
        flexDirection: 'row',
        height:20,  
      },    
      colLine: {
          paddingLeft: 20,
          flex:1,
          flexDirection:'row',
          border:'1px solid #0E3465',
          fontWeight:100,
          fontSize:12
      },
      colLine1: {
        paddingLeft: 20,
        flex:1,
        border:'1px solid #0E3465',
        borderTop:"none",
        borderRight:"none",
        fontWeight:100,
        fontSize:10,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
    },
      colLine2: {
        paddingLeft: 20,
        flex:1,
        border:'1px solid #0E3465',
        borderTop:"none",
        fontWeight:100,
        fontSize:10,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
    },

      text12:{
        fontSize:10,
      },
      content:{
        padding: "10px 20px",
        border:'1px solid #0E3465',
        borderTop:"none",
        minHeight:20,
        fontSize:10,
      },
      content2:{
        padding: "10px 20px 10px 20px",
        border:'1px solid #0E3465',
        borderTop:"none",
        minHeight:20,
        fontSize:10,
      },

      h10:{
        height:10,
      },
});
export default styles;