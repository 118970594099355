import React from 'react'
import css from "./Pictures.module.css"
import Url from '../../../Services/Url';
const Picture=({children})=>{
  if(children){
  let {value, uploadType, uploadId}=children;
  if(uploadId && !value){
    value=Url.img.thumb(uploadId);
    uploadType="image/jpeg"
  }
  if(uploadType?.includes("pdf")){
      return (
      <div className={css.pdf}>
          <object data={value} width={200} >
          </object>
      </div>
      )
  }
  return(
      <div className={css.img}>
          <img src={value} width={200} ></img>
      </div>
  )            
  }
  return(
      <div>
          <div>Document</div>
          <div className={css.UploadTypeName}>PDF Or Image</div>
      </div>
  )
}


export default Picture