import css from "./MilestoneSelect.module.css"
import MilestoneEdit from "../../Components/Milestone2/MilestoneEdit"
import Button from "../../Components/Button/Button"
import { useState } from "react"
const MilestoneSelect=({children})=>{
    const {milestones, handleAdd, handleContinue}=children
    const [unassigned, setUnassigned]=useState(milestones);
    const remove=(ms)=>{
        setUnassigned(unassigned.filter((v)=>v.id!==ms.id))
        handleAdd(ms)
    }
    return(
        <div className={css.MilestoneSelect}>
            <div className={css.Head}>
                <h2>Select Milestones</h2>
            </div>

            <div className={css.RMSList}>
                <div>Milestone name</div>
                <div>Assignee</div>
                <div>Client</div>
            </div>
            <div className={css.Milestones}>
                {
                    unassigned.map((ms, index)=>
                    <div key={index}><MilestoneEdit handleAdd={remove} select={true}>{ms}</MilestoneEdit></div>
                    )
                }
            </div>
           <div className={css.Controls}>
                <div><Button className="Btn-Mid">Exit</Button></div>
                <div><Button onClick={handleContinue} className="Btn-Mid Active">Continue</Button></div>
           </div> 
        </div>
    )
}
export default MilestoneSelect