import "./PageHead.css";
import Svg from "../Svg/Svg";
import SvgOptions from "../Svg/SvgOptions";
import SearchBox from "../SearchBox/SearchBox";
import Button from "../Button/Button";
import { useNavigate, NavLink } from "react-router-dom";
import Filter from "../Filter/Filter";

const PageHead=({navoption, dropOptions, addBtn, buttons, hideIcon, title, backControl, onSearch})=>{
    const {path, icon}=navoption;
    const pathName=path.split("/")[0];
    const navigate=useNavigate();
    return(
        <div className="PageHead">
            <div className="PHTitle">
                {backControl&&
                    <div style={{cursor:"pointer"}} className="PHIcon" 
                    onClick={()=>{navigate("../../")}}>
                        <Svg>{SvgOptions.ArrowBack}</Svg>
                    </div>                
                } 
                {
                    !hideIcon&&
                    <div className="PHIcon"><Svg>{icon}</Svg></div>
                }   
                <h2>{
                 title?title:pathName
                }</h2>
            </div>
        <div className="TopSearch">
            {dropOptions&&
                <Filter onClick={dropOptions.onClick}>{dropOptions.list}</Filter>            
            }
            <SearchBox onChange={onSearch}></SearchBox>
            {
                addBtn&&
                <div className="btnAdd">
                    <Button onClick={addBtn.onClick} icon={SvgOptions.Add} className="Btn-Mid Active">Add {addBtn.text}</Button>
                </div>
            }
            {(buttons && Array.isArray(buttons))&&buttons.map((btn, index)=>
                <div className="btnAdd" key={index}>
                    <Button onClick={btn.onClick} icon={btn.icon} className={"Btn-Mid "+(btn.className?btn.className:"Active")}>{btn.text}</Button>
                </div>)}
        </div>
    </div>
)
}
export default PageHead;