import css from "./Companies.module.css";
import PageHead from "../../../Components/PageHead/PageHead";
import { useState, useEffect } from "react";
import Url from "../../../Services/Url";
import { Get } from "../../../Services/Server";
import DataTable from "../../../Components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
const CompanyList = ({navoption}) => {
    const [list, setList]=useState([]);
    const [page, setPage]=useState({pageable:null,totalPage:null});
    const [data, setData]=useState([]);
    const navigate=useNavigate();
    const reader=(e)=>{
        return [e.name,e.email, e.taskCount + " Task"+(e.taskCount>1?"s":""), "View"];
    }
    const loadPage=(pg)=>{
        Get(Url.company.get.page(pg)).then(resp=>{
            console.log(resp);
            setList(resp.content)
            setPage({pageable:resp.pageable, totalPage:resp.totalPages});
            setData(resp.content);
        })
    }
    const handlePagination=(pg)=>{
        loadPage(pg)
    }
    useEffect(()=>{
        loadPage(0)
    },[])
    const handleAddComp=()=>{
        navigate("Create");
    }
    const handleClick=(e)=>{
        navigate(`${e.id}`);
    }
    const onSearch=(e)=>{
        setData(list.filter(l=>l.name.toLowerCase().includes(e.toLowerCase())));
    }
    const heading=["Company", "Email", "Tasks", "Actions"]
  return (
    <div className={css.Companies}>
        <PageHead onSearch={onSearch} navoption={navoption}  addBtn={{text:"Company", onClick:handleAddComp}}></PageHead>
        <div className={css.Record}>
            <DataTable handlePagination={handlePagination} pageable={page.pageable} totalPages={page.totalPage} onClick={handleClick} flex={[30, 30,30,10]} reader={reader}>{{data, heading}}</DataTable>
        </div>
    </div>
  )
}

export default CompanyList