import React, { useState } from "react";
import { toast } from "react-toastify";

import Form from "../../../../../Components/Form/Form";
import Pictures from "../../../../../Components/Form/Pictures/Pictures";
import Svg from "../../../../../Components/Svg/Svg";
import SvgOptions from "../../../../../Components/Svg/SvgOptions";
import { Post } from "../../../../../Services/Server";
import Url from "../../../../../Services/Url";
import Utils from "../../../../../Services/Utills";
import css from "../FormsContainer.module.css";
import AddRequirementsFormOptions from "./AddRequrementsFormOptions";

const AddRequirements = ({ init, onSaved }) => {
	const formOptions = AddRequirementsFormOptions(init);
	const [showForm, setShowForm] = useState(false);

	const handleSubmit = (e) => {
		const dt = Utils.FormObject(formOptions);
		dt.id = init.id;
		init.requirements = dt;

		Post(Url.sales.saveEnterpriseRequirements, dt)
			.then((resp) => {
				init.status = resp;
				onSaved && onSaved(init);
				setShowForm(false);
				toast("Requirement Saved");
			})
			.catch((err) => {
				if (err.response) {
					const error = err.response.data;
					Object.keys(error).map((key) => {
						toast.error(error[key], { theme: "colored" });
					});
				}
			});
	};

	const onDeletePicture = (e) => {};
	const onAddPicture = (e) => {};

	return (
		<div className={css.FormsContainer}>
			<div className={css.BoxTitle} onClick={() => setShowForm(!showForm)}>
				<Svg className={css.svg}>
					{!showForm ? SvgOptions.ArrowSideFill : SvgOptions.ArrowDownFill}
				</Svg>
				Requirements
			</div>
			{showForm && (
				<div className={css.InputsContainer}>
					<Form onSubmit={handleSubmit}>{formOptions}</Form>
					<Pictures
						onDelete={onDeletePicture}
						onChange={onAddPicture}
					></Pictures>
				</div>
			)}
		</div>
	);
};

export default AddRequirements;
