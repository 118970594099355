import FormType from "../Form/FormType"
import Url from "../../Services/Url"
const AddEquipmentOptions={
    Fields:[
        {element:FormType.Input,required:true, type:"text",name:"equipment", label:"Equipment", placeholder:"Enter Equipment Name", value:null},
        [
            {element:FormType.Select,required:true, name:"type", label:"Task Type", placeholder:"Select Type", value:null, options:{url:Url.taskType, id:"id",name:"name"}}, 
            {element:FormType.Select,required:true, type:"text",name:"template", label:"Template", placeholder:"Select Template", value:null, options:null},
        ],    
    ],
    
    Buttons:[
        {type:"button", text:"Close", className:"Btn-Mid", onClick:null},
        {type:"submit", text:"Add", className:"Btn-Mid Active"},
    ]
}
export default AddEquipmentOptions