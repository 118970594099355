import css from "./Style.module.css"
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Get, Error} from '../../../../Services/Server'
import Url from '../../../../Services/Url'
import Button from '../../../../Components/Button/Button'
import Milestones from '../../../../Components/Milestones2/Milestones'
import User from '../../../../Services/User'
import TaskCompany from '../../../../Components/TaskComany/TaskCompany'
import Executor from '../../../../Components/Executor/Executor'
import Head from '../../../../Components/Head/Head'
import SvgOptions from '../../../../Components/Svg/SvgOptions'
import ReportModal from '../../../../Modals/ReportModalEnterprise/ReportModal'
import ViewReportWizard from '../../../../Modals/ViewReportModalEnterprise/ViewReportWizard'
import ExpenseModal from '../../../../Modals/ExpenseModal/ExpenseModal'
import EquipmentModal from '../../../../Modals/EquipmentModal/EquipmentModal'
import JoinOPsModal from '../../../../Modals/JoinOpsModal/JoinOPsModal'
import JoinEngModal from '../../../../Modals/JoinOpsModal/JoinEngModal'
import CloseTaskModal from '../../../../Modals/CloseTaskModal/CloseTaskModal'
import TaskOptionModal from '../../../../Modals/TaskOptionModal/TaskOptionModal'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
const TaskDetails = ({ModalAction}) => {
    const [task, setTask]=useState()
    const {id}=useParams()
    const uid=User.getBiodata().id;
    const role=User.getRole();
    const navigate=useNavigate();
    const [taskMilestones, setMilestones]=useState([]);

    const init=()=>{
        Get(Url.enterprise.getTask(id)).then(resp=>{
            setTask(resp)
            const ms=resp.taskEquipmentList.reduce((a,v)=>{
                v.taskMilestones.map(x=>x.equipment=v.name)
                return[...a,...v.taskMilestones]},[])
            setMilestones(ms);
            }).catch(err=>{
            Error(err)
        })
    }
    useEffect(()=>{
        init();
    },[])

    const title=(task)=>{
        return task.closedTask?task.title+"[Closed]":task.title
    }
    const isOwner=()=>{
        if(role.base==="admin"){return true}
        if(task.ownerOperation){
            return uid===task.ownerOperation.id;
        }else return false;
    }
    const MSAccess=()=>{
        if(role.base==="enterprise" && task.leadEngineer){
            return uid===task.leadEngineer.id;
        }
        return isOwner();
    }
    const onComplete=()=>{
        task.current=false;
        task.completedAt=new Date();
        Get(Url.enterprise.taskCompleted(4)).then(()=>{
            toast("Task completed")
        }).catch(err=>{
            Error(err)
        })
    }
    const closeModal=()=>{
        init();
        ModalAction.closeModal();
    }
    const openReport=()=>{
        const options={
            element:ReportModal,
            title:"Job Report",
            id,
            task,
            closeModal    
        }
        ModalAction.openModal(options);
    }

    const openExpense=()=>{
        const options={
            element:ExpenseModal,
            title:"Expenses",
            id,
            task,
            closeModal
        }
        ModalAction.openModal(options);
    }
    const openEquipment=()=>{
        const options={
            element:EquipmentModal,
            title:"Equipment Info",
            id,
            task,
            closeModal
        }
        ModalAction.openModal(options);
    }

    const openReportView=()=>{
        const options={
            element:ViewReportWizard,
            title:"Job Report",
            id,
            task,
            closeModal
        }
        ModalAction.openModal(options);
    }
    const ManageOptions=()=>{
        const options={
            element:TaskOptionModal,
            title:"Manage Task Options",
            id,
            task,
            closeModal:()=>{
                    ModalAction.closeModal();
            }
        }
        ModalAction.openModal(options);
    }

    const ManageOperations=()=>{
        const options={
            element:JoinOPsModal,
            title:"Manage Operations",
            id,
            task,
            closeModal:()=>{
                Get(Url.task(id)).then(resp=>{
                    console.log(resp)
                    setTask(resp)
                    ModalAction.closeModal();
                })
            }
        }
        ModalAction.openModal(options);
    }
    const ManageEngineers=()=>{
        const options={
            element:JoinEngModal,
            title:"Manage Engineers",
            id,
            task,
            closeModal:()=>{
                Get(Url.task(id)).then(resp=>{
                    console.log(resp)
                    setTask(resp)
                    ModalAction.closeModal();
                })
            }
        }
        ModalAction.openModal(options);
    }
    const ManageClose=()=>{
        const options={
            element:CloseTaskModal,
            title:"Close Task",
            id,
            task,
            closeModal:()=>{
                Get(Url.task(id)).then(resp=>{
                    console.log(resp)
                    setTask(resp)
                    ModalAction.closeModal();
                })
            },
            onClosed:()=>{
                ModalAction.closeModal();
                navigate("../")
            }
        }
        ModalAction.openModal(options);
    }


    const ddOptions=[
        {text:"Engineers", onClick:ManageEngineers}, 
        {text:"Operations", onClick:ManageOperations},
        {text:"Options", onClick:ManageOptions},
        {text:"Close Task", onClick:ManageClose},
        ]

    return (
        <>
        {task&&
        <div className={css.TaskDetails}>
            <div className={css.TDLeft}>
                <Head title={title(task)} 
                dropdown={isOwner()?ddOptions:[]}></Head>
                <div className={css.Main}>
                    <div className={css.GeneralTaskInfo}>
                        <div>
                            <div>Job Number</div>
                            <div>{task.jobNumber}</div>
                        </div>
                        <div>
                            <div>Tracking Number</div>
                            <div>{task.tracking}</div>
                        </div>
                        <div>
                            <div>Company</div>
                            <div><TaskCompany>{task?.company?.name}</TaskCompany></div>
                        </div>
                        <div>
                            <div>Ops</div>
                            <div>
                                {task.taskOperations.map(op=>
                                    <Executor size={28} key={op.id}>{op.operation}</Executor>
                                    )}
                            </div>
                        </div>
                        <div>
                            <div>Engineer</div>
                            <div>
                                {task.taskEngineers.map(eng=>
                                    <Executor size={28} key={eng.id}>{eng.engineer}</Executor>
                                )}
                            </div>
                        </div>
                        <div>
                            <div>Location</div>
                            <div>{task.location}</div>
                        </div>
                        <div>
                            <div>Started on</div>
                            <div>{task.createdAt}</div>
                        </div>                        
                        <div>
                            <div>Chargeable</div>
                            <div>{task.taskOptions?(task.taskOptions.chargeable?"Yes":"No"):"Undefined"}</div>
                        </div>                        
                        <div>
                            <div>First Time Fix</div>
                            <div>{task.taskOptions?(task.taskOptions.firstTimeFix?"Yes":"No"):"Undefined"}</div>
                        </div>                        
                        {task.closedTask&&
                        <>
                        <div>
                            <div>Reason for closure</div>
                            <div>{task.closedTask.details}</div>
                        </div>                        
                        <div>
                            <div>Closed At</div>
                            <div>{(new Date(task.closedTask.closedAt)).toLocaleString()}</div>
                        </div>                        
                        </>
                        }
                    </div>
                    <div className="TaskDetailsBtns">
                        <div>
                            <Button onClick={openEquipment} icon={SvgOptions.Receipt} className="Btn-Mid Inverse">Equipment Info</Button>
                        </div>
                        {MSAccess()&&
                        <div>
                            <Button onClick={openReport} icon={SvgOptions.Repair} className="Btn-Mid Inverse">Create Report</Button>
                        </div>
                        }
                        {MSAccess()&&
                        <div>
                            {/* <Button onClick={openExpense} icon={SvgOptions.Receipt} className="Btn-Mid Inverse">Expense Report</Button> */}
                        </div>
                        }
                        <div>
                            <Button onClick={openReportView} icon={SvgOptions.Receipt} className="Btn-Mid Inverse">Report</Button>
                        </div>

                    </div>
                </div>
            </div>
            <div className="TDRight">
                {
                    !MSAccess()&&
                    <div className="Readonly"><h1>Read only</h1></div>
                }
                <Milestones onComplete={onComplete} activeUser="engineer" updateUrl={Url.enterprise.saveMilestone}>{taskMilestones}</Milestones>                
            </div>
        </div>
        }
        </>  )
}

export default TaskDetails
