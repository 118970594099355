import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import css from "./SignContract.module.css";
import SignatureCanvas from "react-signature-canvas";
import { useState } from "react";
const SignContract = ({ children }) => {
  const navigate = useNavigate();
  const initials="--"+children.firstname?.charAt(0)+"."+children.lastname?.charAt(0)
  const approveContractxx = () => {
    children.closeModal();
    navigate("signed");
  };
  let sigPad = {}
  const clearCanvas=()=>{
    sigPad.clear()
  }
  const useInitial=()=>{
    clearCanvas();
    const ctx=sigPad.getCanvas().getContext("2d");
    ctx.font = "50px cursive";
    ctx.fillText(initials, 10, 50);
    //console.log(canvas)
  }
  const navigator=window.navigator;
  const approveContract = () => {
    children.closeModal({
      userAgent:navigator.userAgent,
      time:Date.now(),
      data:sigPad.getTrimmedCanvas().toDataURL('image/png')
    })
  }
  return (
    <div className={css.SignContract}>
      <div>
        <SignatureCanvas ref={(ref) => { sigPad = ref }} penColor="red"
        canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}  />
      </div>
      <div className={css.Details}>
        <p style={{ fontWeight: "bold" }}>
          Draw your signature on the space above:
        </p>
        {/* <ol type="a">
          <li>Read and understood the contract.</li>
          <li>Agreed to the terms and conditions.</li>
          <li>Agreed to the payment terms.</li>
        </ol> */}
      </div>
      <hr />
      <div className={css.ButtonBox}>
        <div>
        <Button className="Btn-Mid Active" onClick={approveContract}>
          Append Signature
        </Button>
        <Button className="Btn-Mid Active" onClick={useInitial}>
          Use my Initial
        </Button>
        </div>
        <div>
        <Button className="Btn-Mid Inverse" onClick={clearCanvas}>
          Clear
        </Button>
        </div>
      </div>
    </div>
  );
};

export default SignContract;
