import ExpensesList from "./ExpensesList";
import ExpensesView from "./ExpensesView";
import SvgOptions from "../../../../Components/Svg/SvgOptions";
const ExpensesNavOptions=[
    {
        element:ExpensesView, 
        path:":id",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    }

]
const defaultRoute={
    element:ExpensesList, 
    path:"*",
    access:{user:true, admin:true, super:true}, 
    Protected:false,
    default:true,
    icon:SvgOptions.Dashboard
}
export {defaultRoute};
export default ExpensesNavOptions;