import SvgOptions from "../../../Components/Svg/SvgOptions";
import StaffList from "./StaffList/StaffList";
import AddStaff from "./AddStaff/AddStaff";
import Details from "./Details/Details";
const StaffNavOptions = [
	{
		element: AddStaff,
		path: "Add",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		icon: SvgOptions.Dashboard,
	},
	{
		element: Details,
		path: "Details/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		icon: SvgOptions.Dashboard,
	},
];
const defaultRoute = {
	element: StaffList,
	path: "*",
	access: { user: true, admin: true, super: true },
	Protected: false,
	default: true,
	icon: SvgOptions.Dashboard,
};
export { defaultRoute };
export default StaffNavOptions;
