import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import css from "./ReportModal.module.css"
import Report1 from "./Report1";
import Report2 from "./Report2";
import Report3 from "./Report3";
import { useState } from "react";
import Wizard from "../../Components/Wizard/Wizard";
import ReportOptions from "./ReportOptions";

const ReportModal=({children})=>{
    const pages=[
        ReportOptions,Report1, Report2, Report3
    ]
    const task=children.task
    const props={task}
    const data={initial:{}};

    return(
        <Wizard props={props} init={data}>{pages}</Wizard>
    )
}
export default ReportModal;