import MilestoneTemplates from "./MilestoneTemplates"
import EditTemplate from "./EditTemplate/EditTemplate"
import SvgOptions from "../../../../Components/Svg/SvgOptions"
import DeleteTemplate from "./DeleteTemplate/DeleteTemplate"
import ManageMilestones from "./ManageMilestones/ManageMilestones"
const mtNavOption=[
    {
        element:EditTemplate, 
        path:"Edit",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
    {
        element:DeleteTemplate, 
        path:"Delete",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
    {
        element:EditTemplate, 
        path:"Create",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },
    {
        element:ManageMilestones, 
        path:"Manage",
        access:{user:true, admin:true, super:true}, 
        Protected:false,
        default:true,
        icon:SvgOptions.Dashboard
    },

]
const defaultRoute={
    element:MilestoneTemplates, 
    path:"*",
    access:{user:true, admin:true, super:true}, 
    Protected:false,
    default:true,
    icon:SvgOptions.Dashboard
}

export {defaultRoute}
export default mtNavOption
