import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Form/Input/Input";
import LoginOptions from "./LoginOptions";
import Utils from "../../Services/Utills";
import Server from "../../Services/Server";
import { toast } from "react-toastify";
import User from "../../Services/User";
import logo from "../../Assets/svg/logo.svg";

const Login = () => {
	const navigate = useNavigate();
	const onSubmit = () => {
		Server.login(Utils.FormObject(LoginOptions))
			.then((resp) => {
				toast.success(resp, {
					theme: "colored",
				});
				const role = User.getRole();
				navigate("../" + role.base);
			})
			.catch((err) => {
				toast.error(err, {
					theme: "colored",
				});
			});
	};

	return (
		<div className="LoginPage">
			<div className="LoginLeft">
				<div className="LoginTitle">
					<div>
						<img src={logo} alt="logo" />
					</div>
					PROJECT TRACKER REPORT SYSTEM
				</div>

				<div className="LoginForm">
					<h1>Login</h1>
					{LoginOptions.Fields.map((option, index) => (
						<div key={index}>
							<Input>{option}</Input>
						</div>
					))}
					<div className="ForgetPWLink">
						<div className="ActiveLink">
							Forget your <Link>password</Link>
						</div>
					</div>
					<div>
						<Button onClick={onSubmit} className="Btn-Mid Active">
							Login
						</Button>
					</div>
				</div>
			</div>
			<div className="LoginRight"></div>
		</div>
	);
};
export default Login;
