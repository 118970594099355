import React from 'react'

const MilestoneRoute = () => {
  return (
    <div>
      MilestoneRoute
    </div>
  )
}

export default MilestoneRoute
