import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Post } from '../../../../../Services/Server';
import Url from '../../../../../Services/Url';
import { toast } from 'react-toastify';
const Delete = () => {
    const navigate=useNavigate()
    const state=useLocation().state;
    Post(Url.staffRemoveRole,{email:state.staffBiodata.email, roleId:2}).then(resp=>{
        toast(resp)
        navigate("../..")
    }).catch(err=>{
        console.log(err)
    })
    console.log(state)
    return (
        <div style={{width:"100%"}}><h2>Deleteing from role...</h2></div>
    )
}

export default Delete