import css from "../Form.module.css"
import { Get } from "../../../Services/Server"
import { useState, useEffect, useRef } from "react"
const Select=({children})=>{
    const {label, options, name, onSelected, placeholder, required, disabled}=children
    let [value, setVal]=useState(children.value)
    const ref=useRef();
    const [optionList, setOptions]=useState([])
    useEffect(()=>{
        setVal(children.value)
    },[children.value])
    useEffect(()=>{
    if(options){
            if(typeof(options)==="object" && !Array.isArray(options)){
                Get(options.url).then(resp=>{
                    setOptions(resp)
                }).catch(err=>{console.log(err)})
            }else if(Array.isArray(options)){
                setOptions(options)
            }
            setVal(children.value);
        }    
    },[options])
    useEffect(()=>{
        onSelected&&onSelected(children.value)
    },[value])
    const setValue=()=>{
        const val=optionList.find((rec)=>rec["name"].localeCompare(ref.current.value)===0)
        children.value=val;
        setVal(val);
    }
     return(
        <div className={css.FormControl}>
            <div>
                <label>{label}</label>
            </div>
            <div>
                <select disabled={disabled} required={required?required:false} value={value?value["name"]:""}  name={name} ref={ref} onChange={setValue}>
                    <option value="">{placeholder}</option>
                    {optionList.map((op, index)=>
                    <option value={op["name"]} key={index}>{op["name"]}</option>
                    )}
                </select>
            </div>
        </div>

    )
}
export default Select;