import StarRatings from 'react-star-ratings';
import Button from '../../Components/Button/Button';
import css from "./FeedbackModal.module.css"
import { useState } from 'react';

const Feedback=({children, onContinue})=>{
    const {question, stars, option}=children;
    const [options, setOptions]=useState([{text:"Yes", active:"Inverse"},{text:"No", active:"Inverse"},{text:"Others", active:"Inverse"}])
    const handleSelect=(index)=>{
        children.option={id:index+1, name:options[index].text}
        setOptions(options.map((op, i)=>{op.active=i==index?"Active":"Inverse"; return op}))
    }
    const textChanged=(e)=>{
        children.additionalText=e.target.value;
    }
    const onClick=()=>{
        onContinue(children);
    }
    const [rate, setRate]=useState(0);
    const changeRating=( newRating, name )=> {
        children.stars=newRating;
        setRate(newRating)
      }

    return(
    <div>
            <h3>{question}</h3>
            {option !==null &&
            <div className={css.BtnOptions}>
                {options.map((op, index)=>
                    <Button onClick={()=>{handleSelect(index)}} className={"Btn-Small "+op.active}>{op.text}</Button>                
                )}
            </div>
            }

            {stars!==null &&
            <StarRatings
            rating={rate}
            starRatedColor="#14B8FF"
            starHoverColor='#14B8FF'
            changeRating={changeRating}
            numberOfStars={10}
            starDimension="35px"
            name='rating'
            />                
            }
        <div>
        <div className={css.FormControl}>
        <div><strong>Additional comments </strong>(optional)</div>
        <textarea onChange={textChanged}>
        </textarea>
        </div>
        </div>
        <div className={css.ButtonControls}>
        <div>
            <Button className="Btn-Mid">Cancel</Button>
            </div>
            <div>
            <Button onClick={onClick} className="Btn-Mid Active">Continue</Button>
            </div>
        </div>
    </div>
)
}
export default Feedback;