import RouteView from "../../../Routes/RouteView";
import JobsNavOption, { defaultRoute } from "./JobsNavOption";

const Jobs = ({ navoption, ModalAction }) => {
	return (
		<RouteView
			navoption={navoption}
			ModalAction={ModalAction}
			defaultRoute={defaultRoute}
		>
			{JobsNavOption}
		</RouteView>
	);
};

export default Jobs;
