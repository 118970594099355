const PieOption={
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      show:false,
    },
    series: [
      {
        name: 'Jobs Taken',
        type: 'pie',
        radius: ['80%', '90%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 25, name: 'Installation',itemStyle:{color:"#F64740"} },
          { value: 50, name: 'Repairs',itemStyle:{color:"#14B8FF"} },
          { value: 25, name: 'Maintenance',itemStyle:{color:"#FAC815"} },
        ]
      }
    ]
  }        
const BarOption={
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: ["Samuel", "Isaac", "Tosin", "Mohammed", "Joab"],
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Jobs Taken',
        type: 'bar',
        barWidth: '60%',
        data: [
            { value: 25, name: 'Installation',itemStyle:{color:"#14B8FF"} },
            { value: 50, name: 'Repairs',itemStyle:{color:"#14B8FF"} },
            { value: 43, name: 'Installation',itemStyle:{color:"#14B8FF"} },
            { value: 37, name: 'Repairs',itemStyle:{color:"#14B8FF"} },
            { value: 30, name: 'Maintenance',itemStyle:{color:"#14B8FF"} },
          
        ]
      }
    ]
  };  
const Charts={
    getPie:(data)=>{
        PieOption.series[0].data=data;
        return {...PieOption}    
    },
    getBar:(data, xdata)=>{
      BarOption.series[0].data=data;
      BarOption.xAxis[0].data=xdata;
      return {...BarOption};
    },
    Pie:{
        option : PieOption  
    }
}
    
export default Charts;