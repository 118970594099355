import SvgOptions from "../../Components/Svg/SvgOptions";
import Dashboard from "./Dashboard/Dashboard";
import Tasks from "./Tasks/Tasks";
import Jobs from "./Jobs/Jobs";
const EpNavOption = [
	{
		element: Dashboard,
		path: "Dashboard",
		access: ["enterprise", "admin", "account", "operation"],
		Protected: false,
		default: true,
		icon: SvgOptions.Dashboard,
	},
	{
		element: Tasks,
		path: "Tasks/*",
		access: ["enterprise", "admin", "operation"],
		Protected: false,
		default: false,
		icon: SvgOptions.Task,
	},
	{
		element: Jobs,
		path: "Jobs/*",
		access: ["enterprise-bdm"],
		Protected: false,
		default: false,
		icon: SvgOptions.Task,
	},
	{
		element: Dashboard,
		path: "Expenses/*",
		access: ["enterprise"],
		Protected: false,
		default: true,
		icon: SvgOptions.Receipt,
	},
];

const accNavOptions = [
	{
		element: Dashboard,
		path: "Notifications/*",
		access: [
			"enterprise",
			"admin",
			"account",
			"operation",
			"bdm",
			"enterprise-bdm",
		],
		Protected: false,
		default: true,
		notify: 3,
		icon: SvgOptions.Bell,
	},
	{
		element: Dashboard,
		path: "Settings",
		access: [
			"enterprise",
			"admin",
			"account",
			"operation",
			"bdm",
			"enterprise-bdm",
		],
		Protected: false,
		default: true,
		icon: SvgOptions.Settings,
	},
];
const logoutNav = {
	element: Dashboard,
	path: "Logout",
	access: [
		"enterprise",
		"admin",
		"account",
		"operation",
		"bdm",
		"enterprise-bdm",
	],
	Protected: false,
	default: true,
	title: "",
	subTitle: "Log out",
	navImg: "",
	confirm: {},
};
export { accNavOptions, logoutNav };
export default EpNavOption;
