import React from "react";

import Button from "../../../Components/Button/Button";
import Svg from "../../../Components/Svg/Svg";
import SvgOptions from "../../../Components/Svg/SvgOptions";
import SignContract from "../../../Modals/ClienContract/SignContract";
import css from "./ViewContract.module.css";
import { useState } from "react";
import { Error, Post } from "../../../Services/Server";
import Url from "../../../Services/Url";
import { toast } from "react-toastify";

const ViewEnterpriseContract = ({ ModalAction, job }) => {
	const { initial } = job;
	const {
		billingInformation,
		subscriberInformation,
		clauses,
		contactsInformationList,
	} = initial.contract;
	const contact = contactsInformationList[0];
	const [sign, setSign] = useState(initial.contract.contractSign);
	const handleSignContract = () => {
		const Options = {
			title: "Approve Contract",
			element: SignContract,
			closeModal: handleCloseModal,
			firstname: job.firstname,
			lastname: job.lastname,
		};
		ModalAction.openModal(Options, "Approve Contract");
	};
	const date = new Date(sign?.time);
	const handleCloseModal = (data) => {
		ModalAction.closeModal();
		setSign(data);
	};

	const handleSubmit = () => {
		Post(Url.client.signEnterpriseContract, { ...sign, initialId: initial.id })
			.then((resp) => {
				toast("Contract Signed");
				setSign({ ...resp });
			})
			.catch((err) => {
				Error(err);
			});
	};
	const ucFirst = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};
	return (
		<div className={css.ContractRightContainer}>
			{ModalAction ? (
				<div className={css.ContractRightTop}>
					<p className={css.Title}>Contract</p>
					<div>
						<Button className="Btn-Mid" icon={SvgOptions.Download}>
							<span style={{ marginLeft: "5px" }}>Download</span>
						</Button>
					</div>
				</div>
			) : (
				<div style={{ marginTop: "-25px" }}></div>
			)}
			<div>
				<div>
					<p className={css.SubTitle}>Service Provider Information</p>
					<div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Company: </p>
								<p>Fieldbase Services Limited</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Email: </p>
								<p>sales@fbase.co.uk</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Company Number: </p>
								<p>F10637893</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Phone: </p>
								<p>07425-155-321</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Vat Number: </p>
								<p>313 7483 09</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>County: </p>
								<p>United Kingdom</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Contact First Name: </p>
								<p>Chinedu</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Province: </p>
								<p>Greater London</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Contact Last Name: </p>
								<p>Abanobi</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>City: </p>
								<p>London</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Role: </p>
								<p>Managing Director</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Postal Code: </p>
								<p>EC3V 3NG</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Also Designated as: </p>
								<p>-</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Address: </p>
								<p>International House, 36-38 Cornhill</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<p className={css.SubTitle}>Subscriber Information</p>
					<div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Accounty Type: </p>
								<p>-</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Email: </p>
								<p>{job.email}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Company: </p>
								<p>{initial.company?.name}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Phone: </p>
								<p>{job.phone}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Country: </p>
								<p>{subscriberInformation?.country}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Contact First Name: </p>
								<p>{contact.firstname}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>State: </p>
								<p>{contact?.state}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Contact Last Name: </p>
								<p>{contact?.lastname}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Contact Country: </p>
								<p>{contact.country}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Role: </p>
								<p>{contact?.role?.name}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Postal Code: </p>
								<p>-</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Also Designated as: </p>
								<p>{contact?.jobTitle}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Email: </p>
								<p>{contact.email}</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<p className={css.SubTitle}>Billing Information</p>
					<div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Account Type: </p>
								<p>-</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Email: </p>
								<p>{billingInformation.email}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Company: </p>
								<p>{billingInformation?.company?.name}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Phone: </p>
								<p>{billingInformation?.phone}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Postal: </p>
								<p>{billingInformation?.postalCode}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>County: </p>
								<p>{billingInformation?.country}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>First Name: </p>
								<p>{billingInformation?.firstname}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>State: </p>
								<p>{billingInformation?.state}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Last Name: </p>
								<p>{billingInformation?.lastname}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Address: </p>
								<p>{billingInformation?.address}</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<p className={css.SubTitle}>Terms &amp; Condition</p>
					<div>
						<ol>
							{clauses.map((pr) => (
								<li key={pr.id}>
									<div className={css.ListTitles}>{pr.name}</div>
									<p
										className={css.ClauseDetails}
										dangerouslySetInnerHTML={{ __html: pr.details }}
									></p>
								</li>
							))}
						</ol>
					</div>
				</div>
				<div className={css.SignFoot}>
					<p className={css.SubTitle}>Signature</p>
					<div className={css.InfoRow}>
						<div>
							<p className={css.SignatureNames}>Chinedu Abanobi</p>
							<p>Managing Director at Fieldbase</p>
						</div>
						<div>
							<p className={css.SignatureNames}>
								{ucFirst(job.firstname) + " " + ucFirst(job.lastname)}
							</p>
							<p>{subscriberInformation?.designation?.name}</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div>
							<div className={css.Signature}>
								<img src="/signature.png" alt="signature" />
							</div>
							<p className={css.Date}>Date: 12 Jan 2024</p>
						</div>
						{ModalAction && (
							<div style={{ width: "100%" }}>
								{sign && (
									<>
										<div className={css.Signature}>
											<img src={sign.data} alt="client signature" />
										</div>
										<div>
											{date.toDateString() + " " + date.toLocaleTimeString()}
										</div>
									</>
								)}
							</div>
						)}
					</div>
					{!sign?.id && (
						<div className={css.InfoRow}>
							<div></div>
							<div className={css.FootButtons}>
								{sign && (
									<Button
										className="Btn-Mid Active"
										onClick={handleSubmit}
										icon={SvgOptions.Check}
									>
										Submit Contract
									</Button>
								)}
								<Button
									className="Btn-Mid Active"
									onClick={handleSignContract}
									icon={SvgOptions.Edit2}
								>
									Sign Contract
								</Button>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ViewEnterpriseContract;
