import FormType from "../../../../../Components/Form/FormType";
import Url from "../../../../../Services/Url";

const AddRequirementsFormOptions = (init) => {
	return {
		Fields: [
			{
				element: FormType.TextArea,
				required: true,
				label: "Project Scope:",
				placeholder: "Enter Details of Project Scope",
				value: init.requirements ? init.requirements.projectScope : null,
				name: "projectScope",
			},
		],
		Buttons: [
			{ type: "button", text: "Close", className: "Btn-Mid", onClick: null },
			{ type: "submit", text: "Submit", className: "Btn-Mid Active" },
		],
	};
};

export default AddRequirementsFormOptions;
