import axios from "axios"
import Url from "./Url"
import Roles from "../Data/Roles";
import User from "./User";
import { toast } from "react-toastify";
const Error=(err)=>{
    if(err.response){
        const errs=err.response.data
        Object.keys(errs).map(key=>{
          toast.error(errs[key],{theme:"colored"});
          return null;
        })
      }
}
const UploadFile=(file, name)=>{
    const url=Url.upload.save;
    const fd=new FormData();
    fd.append("file", file);
    fd.append("name",name);
    return new Promise((resolve, reject)=>{
        axios.post(url, fd, { headers: {"Authorization" : `Bearer ${User.getToken()}`} })
        .then(resp=>{ resp.status===200?resolve(resp.data):reject(resp)})
        .catch(err=>{reject(err)});
    })    
}

const DownloadFile=(id)=>{
    const url=Url.upload.get(id);
    return new Promise((resolve, reject)=>{
        axios.get(url, { headers: {"Authorization" : `Bearer ${User.getToken()}`} })
        .then(resp=>{ resp.status===200?resolve(resp.data):reject(resp)})
        .catch(err=>{reject(err)});
    })    
}


const Post=(url, formObject)=>{
    return new Promise((resolve, reject)=>{
        axios.post(url, formObject, { headers: {"Authorization" : `Bearer ${User.getToken()}`} })
        .then(resp=>{ resp.status===200?resolve(resp.data):reject(resp)})
        .catch(err=>{reject(err)});
    })
}
const Get=(url)=>{
    return new Promise((resolve, reject)=>{
        axios.get(url, { headers: {"Authorization" : `Bearer ${User.getToken()}`} })
        .then(resp=>{ resolve(resp.data)})
        .catch(err=>{reject(err)});
    })
}
const PublicGet=(url)=>{
    return new Promise((resolve, reject)=>{
        axios.get(url)
        .then(resp=>{ resolve(resp.data)})
        .catch(err=>{reject(err)});
    })
}
const PublicPost=(url, formObject)=>{
    return new Promise((resolve, reject)=>{
        axios.post(url, formObject)
        .then(resp=>{ resolve(resp.data)})
        .catch(err=>{reject(err)});
    })
}

const Delete=(url)=>{
    return new Promise((resolve, reject)=>{
        axios.delete(url, { headers: {"Authorization" : `Bearer ${User.getToken()}`} })
        .then(resp=>{ resolve(resp.data)})
        .catch(err=>{reject(err)});
    })
}

const Server={
    login:(data)=>{
        return new Promise((resolve, reject)=>{
            axios.post(Url.login, data).then(resp=>{
                    if(resp.status===200){
                        if(resp.data.token){
                            const roles=resp.data.staffBiodata.appRoles;
                            if(Roles.has(roles)){
                                User.setBiodata(resp.data.staffBiodata);
                                User.setToken(resp.data.token);
                                User.setRole(Roles.get(roles));
                                User.setRoles(roles);
                                resolve("Login Successful");    
                            }else{
                                reject("You have no assiged role on this platform")
                            }
                        }
                    }
                    reject("Login Failed");
    
            }).catch(err=>{
                reject("Login failed");
            })    
        });
    },
    getAllStaff:async ()=>(await axios.get(Url.allStaff, { headers: {"Authorization" : `Bearer ${User.getToken()}`} })).data,    
    getStaffByRole:async(rolename)=>await Get(Url.staffByrole(rolename)),
    getTaskType:async()=>await Get(Url.taskType),
    //getStaffByRole:async (rolename)=>(await axios.get(Url.staffByrole(rolename), { headers: {"Authorization" : `Bearer ${User.getToken()}`} })).data,
}
export {Get, Post, Delete, PublicGet, PublicPost, Error, UploadFile, DownloadFile}
export default Server;