const LoginOptions = {
	Fields: [
		{
			label: "Your email",
			name: "email",
			placeholder: "name@fbase.co.uk",
			type: "email",
			value: "",
		},
		{
			label: "Password",
			name: "password",
			placeholder: "Enter your password",
			type: "password",
			value: "",
		},
	],
};
export default LoginOptions;
