import FormType from "../../Components/Form/FormType";
import Role from "../../Data/Role";
import Url from "../../Services/Url";
const AddTaskOPtions={
    Fields:[
            {element:FormType.Select, required:true, name:"company", label:"Company", value:null, placeholder:"Select Company", options:{url:Url.company.all, id:"id",name:"name"}},
            {element:FormType.Input,required:true, type:"text",name:"location", label:"Location", placeholder:"Port & State", value:null},
            [
                {element:FormType.Select, required:false, name:"taskOptions", label:"Bill Category", value:null, placeholder:"Select Category", options:[
                    {id:null, name:"Chargeable", chargeable:true},
                    {id:null, name:"Non Chargeable", chargeable:false},
                ]},
                {element:FormType.Input,required:true, type:"date",name:"deliveryEstimate", label:"Delivery Estimate", placeholder:"Port & State", value:null},    
            ],
            {element:FormType.Input,required:false, type:"text",name:"serviceOrder", label:"Service Order/Case Number", placeholder:"Optional Service order", value:null},
    ],
    Buttons:[
        {type:"button", text:"Cancel", className:"Btn-Mid", onClick:null},
        {type:"submit", text:"Next", className:"Btn-Mid Active"},
    ]
}
export default AddTaskOPtions;