import css from "./Head.module.css"
import DropDown from "../DropDown/DropDown"
import { NavLink } from "react-router-dom"
import Svg from "../Svg/Svg"
import SvgOptions from "../Svg/SvgOptions"
const Head = ({title, state, dropdown}) => {
  return (
    <div className={css.Head}>
    <div className={css.Title}><div><NavLink state={state} to=".."><Svg>{SvgOptions.ArrowBack}</Svg></NavLink></div> <div>{title}</div></div>
        <div>
            <DropDown state={state}>{dropdown}</DropDown>
        </div>
    </div>
  )
}

export default Head