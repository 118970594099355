const ConfirmOption=(options)=> {
    return {
    title:"Title",
    text:"",
    options,
    exitText:"Exit",
    okText:"Continue",
    onOk:()=>{}
    }
}

export default ConfirmOption;
