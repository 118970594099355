import Svg from "../Svg/Svg";
import SvgOptions from "../Svg/SvgOptions";
import css from "./Filter.module.css"
import { useState, useRef, useEffect} from "react";
const Filter=({children, onClick, title})=>{
    const getdata=()=>{
        if(typeof(children)!=="object")return[]
        if(Array.isArray(children)){
            return children.reduce((a, v)=>{
                let ob={text:v, selected:0};
                a.push(ob);
                return a;
            },[])
        }else{
            return children.data;
        }
    }
    const ref=useRef();
    const data=getdata()
    const [option, setOption]=useState({data:data.slice(0), title:title?title:"Show Following"});
    const handleSelect=(op)=>{
        const index=data.findIndex((dt)=>dt.text===op.text);
        const dataHolder=data.slice(0);
        dataHolder[index].selected=(option.data[index].selected+1)%2;
        if(dataHolder[index].selected===1){
            setOption({data:[...dataHolder], title:op.text});    
        }else{
            setOption({data:[...dataHolder], title:title?title:"Show Following"});    
        }
        onClick&&onClick(data)
    }
    return(
        <div className={css.Filter}>
            <div className={css.Active}>
                <div className={css.ActiveText}>{option.title}</div>
                <div ref={ref} className={css.Arrow}><Svg>{SvgOptions.ArrowDown}</Svg></div>
            </div>
            <div className={css.content}>
                <ul>
                    {
                        option.data.map((op, index)=>
                        <li key={index} className={css[op.selected&&"Selected"]}><span onClick={()=>handleSelect(op)}>{op.text}</span></li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
export default Filter;