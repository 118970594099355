import css from "./Style.module.css"
import FormOptions from "./FormOptions"
import Form from "../../Components/Form/Form"
import Utils from "../../Services/Utills";
function AddVesselModal({children}) {
    const {closeModal, name}=children;
    const options=FormOptions;
    options.Fields.find(f=>f.name==="name").value=name;
    const onSubmit=(e)=>{
        const dt=Utils.FormObject(e)
        closeModal(dt)
    }
  return (
    <div className={css.AddVesselModal}>
        <Form onSubmit={onSubmit}>{options}</Form>
    </div>
  )
}

export default AddVesselModal