import FormType from "../../../../../../Components/Form/FormType";
import Url from "../../../../../../Services/Url";

const AddSubscriberInformationFormOptions = (init) => {
  const contract=init.contract||{};
  const {subscriberInformation}=contract;
  return {
    Fields: [
      {
        element: FormType.Select,
        required: true,
        label: "Company",
        placeholder: "Select company",
        options: { url: Url.company.all, id: "id", name: "name" },
        value:init?.company,
        disabled:true,
        name: "company",
      },
      {
        element: FormType.Select,
        required: true,
        label: "Vessel",
        placeholder: "Select Vessel",
        options: init?.company?.vessels,
        value:init.vessel,
        disabled:true,
        name: "vessel",
      },
      {
        element: FormType.Select,
        required: true,
        label: "Contact",
        placeholder: "Select contact",
        options: [init.contact],
        value:init?.contact,
        disabled:true,
        name: "contact",
      },
      {
        element: FormType.Input,
        required: true,
        label: "Email",
        placeholder: "Enter contact email",
        value:init?.contact?.email,
        disabled:true,
        name: "email",
      },
      [
        {
          element: FormType.Select,
          required: false,
          label: "Role (if applicable)",
          placeholder: "Select role",
          name:"role",
          options: { url: Url.company.clientRoles, id: "id", name: "name" },
          value:subscriberInformation?.role,
        },
        {
          element: FormType.Select,
          required: false,
          label: "Also Designated as",
          placeholder: "Select Designation",
          name:"designation",
          options: { url: Url.company.clientDesignations, id: "id", name: "name" },
          value:subscriberInformation?.designation,
        },
      ],
      [
        {
          element: FormType.Input,
          required: false,
          label: "State / Province / Territory",
          placeholder: "select state",          
          name: "state",
          value:subscriberInformation?.state,
        },
        {
          element: FormType.Input,
          required: false,
          label: "Country",
          placeholder: "Select country",
          name: "country",
          value:subscriberInformation?.country,
        },
      ],
      [
        {
          element: FormType.Input,
          required: false,
          label: "City",
          placeholder: "Select city",
          name:"city",
          value:subscriberInformation?.city,
        },
        {
          element: FormType.Input,
          required: false,
          label: "Postal / Zipcode",
          placeholder: "Enter postal code",
          name: "postalCode",
          value:subscriberInformation?.postalCode,
        },
      ],
      {
        element: FormType.Input,
        required: true,
        label: "Address",
        placeholder: "Enter address",
        value:subscriberInformation?.address,
        name:"address",
      },
    ],
  };
};

export default AddSubscriberInformationFormOptions;
