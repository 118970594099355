import Svg from "../../Svg/Svg"
import SvgOptions from "../../Svg/SvgOptions"
import css from "./AddButton.module.css"
const AddButton=({children, onClick})=>{
    return(
        <div onClick={onClick} className={css.AddLog}>
            <div><Svg className={css.AddLogIcon}>{SvgOptions.Add}</Svg></div>
            <div>{children}</div>
        </div>
    )
}
export default AddButton