import css from "./Expandable.module.css"
const Expandable = ({children, expand, height, overlay}) => {
  return (
    <div style={{height:expand &&!overlay?`${height}px`:"0px"}} className={css.Cont}>
    <div style={{minHeight:expand?`${height}px`:"0px"}} className={css.Expandable}>{children}</div>
    </div>
  )
}
Expandable.defaultProps={
  height:100,
  overlay:0
}

export default Expandable