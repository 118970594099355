import "./DropDown.css"
import { ModalAction } from "../../Routes/NavRouter"
import { NavLink , useNavigate} from "react-router-dom"
const DropDown=({children, state})=>{
    const navigate=useNavigate();
    const onOk=(op)=>{
        navigate(op.to, {state})
    }
    const handleDelete=(op)=>{
        ModalAction.confirm({
            title:"Deleteing Item?",
            text:"You are about to delete the selected item",
            exitText:"Close",
            okText:"Delete",
            onOk:()=>onOk(op)
            })
    }
    return(
        <span className="DropDown">
            <div className="DropBtn"><NavLink to={null}>...</NavLink></div>
            <div className="DropDown-Cont">
                <div className="DropDown-menu">
                    <ul>
                        {
                            children.map((op,index)=>
                            <li onClick={op.onClick?()=>op.onClick(op):null} key={index}><NavLink to={op.delete?null:op.to} state={state} onClick={()=>{if(op.delete)handleDelete(op)}}>{op.text}</NavLink></li>                           
                            )
                        }
                    </ul>
                </div>
            </div>
        </span>
    )
}
DropDown.defaultProps={
    children:[
        {text:"Edit", to:"./Edit" },
        {text:"Delete", to:"./Delete", delete:true}
    ]
}
export default DropDown;