import React, { useState, useEffect } from "react";

import css from "./Contact.module.css";
import Button from "../../Components/Button/Button";
import Form from "../../Components/Form/Form";
import Logo from "../../Assets/svg/logo.svg";

import ContactFormOptions from "./ContactFormOptions";
import LocationFormOptions from "./LocationFormOptions";
import VesselFormOptions from "./VesselFormOptions";
import User from "../../Services/User";
import { Post } from "../../Services/Server";
import Url from "../../Services/Url";
import Utils from "../../Services/Utills";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const Contact = ({}) => {
	const { step } = useLocation().state;
	const [contactStepNumber, setContactStepNumber] = useState(step ? step : 2);
	let title = "Contact";
	const nav = useNavigate();
	let formOptions = ContactFormOptions;
	const init = User.getJobInit();

	const onSubmitForm = (e) => {
		e.preventDefault();
		console.log("Form Submitted");
	};
	console.log(init);

	if (contactStepNumber === 3) {
		formOptions = VesselFormOptions(init);
		title = "Vessel";
	} else if (contactStepNumber === 4) {
		title = "Location";
		formOptions = LocationFormOptions(init);
	} else {
		title = "Contact";
		formOptions = ContactFormOptions(init);
	}

	const onClickGoBack = () => {
		setContactStepNumber(contactStepNumber - 1);
	};

	const onClickContinue = () => {
		let endPoint = null;
		const dt = Utils.FormObject(formOptions);
		if (contactStepNumber === 2) {
			endPoint = Url.client.contact;
		} else if (contactStepNumber === 3) {
			endPoint = Url.client.vessel;
		} else if (contactStepNumber === 4) {
			endPoint = Url.client.port;
			dt.eta = new Date(dt.eta);
			dt.etd = new Date(dt.etd);
		}
		Post(endPoint, dt)
			.then((resp) => {
				if (contactStepNumber < 4) setContactStepNumber(contactStepNumber + 1);
				else nav("../Completed");
			})
			.catch((err) => {
				if (err.status == 206) {
					toast("Incomplete data provided");
				} else if (err.status == 204) {
					toast("Invalid data provided");
				} else {
					toast("server returns with error code " + err.status);
				}
				console.log(err);
			});
	};

	return (
		<div className={css.Contact}>
			<div className={css.ContactLeft}>
				<div>
					<img src={Logo} alt="Fieldbase Logo" />
				</div>
				<div className={css.ContactLeftContent}>
					<p className={css.title}>Contact Us</p>
					<p className={css.text}>
						If you have any concerns or issues with your order, please
						<br /> don't hesitate to get in touch with us.
					</p>
					<p className={css.text}>
						<span className={css.key}>Email:</span> support@fbase.co.uk
					</p>
					<p className={css.text}>
						<span className={css.key}>Phone:</span> +234 9030 001 104
					</p>
				</div>
			</div>
			<div className={css.ContactRight}>
				<p className={css.StepsCount}>Step {contactStepNumber}/4</p>
				<div className={css.Form}>
					<p className={css.FormTitle}>{title}</p>
					<Form onSubmit={onSubmitForm}>{formOptions}</Form>
				</div>
				<div className={css.Buttons}>
					<div>
						{contactStepNumber !== step && (
							<Button className="Btn-Mid" onClick={onClickGoBack}>
								Back
							</Button>
						)}
					</div>
					<div>
						<Button
							className="Btn-Mid Active"
							onClick={onClickContinue}
							disabled={contactStepNumber === 4}
						>
							{contactStepNumber === 4 ? "Submit" : "Continue"}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
